import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import DataTable from "../../../../components/DataTable";
import { Link } from "react-router-dom";
import { DataNotFound } from "../../../../components/DataNotFound";
import api from "../../../../services/api";

import { VincularOpcoesContext } from '../../../../contexts/vincularOpcoes/vincularOpcoesContext';
import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';

import { IListItemOpcao, ICheckedOpcoes } from "../../../../interfaces/vincularOpcao.interface";


const ModalVincularOpcoes: React.FC = () => {

    //Context
    const toastContext = useContext(ToastContext);
    const vincularOpcoesContext = useContext(VincularOpcoesContext);

    //States
    const [opcoes, setOpcoes] = useState<IListItemOpcao[]>([]);
    const [opcoesLoading, setOpcoesLoading] = useState(false);
    const [checkedOpcoes, setCheckedOpcoes] = useState<ICheckedOpcoes[]>([])

    //Effects
    useEffect(() => {
        if (vincularOpcoesContext.show) {
            (async () => {
                try {
                    setOpcoesLoading(true);
                    const { data } = await api.get(`/Propostas/ObterListaOpcoesMontagem?propostaItemId=${vincularOpcoesContext.tipologiaId}`);
                    setOpcoes(data)
                    setOpcoesLoading(false);

                } catch (err) {
                    const message = getSingleErrorMessage(err);
                    if (message) {
                        toastContext.notification(message, "error");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                }
            })()
        }
    }, [vincularOpcoesContext.show])

    //Handles - Opções
    const handleCloseOpcoes = () => {
        setOpcoes([]);
        setCheckedOpcoes([]);
        vincularOpcoesContext.setShow(false);
    }

    const handleSaveOpcoes = async () => {
        try {
            const postData = { propostaItemId: vincularOpcoesContext.tipologiaId, opcoes: checkedOpcoes };
            await api.put("/Propostas/AtualizarListaOpcoesMontagemItem", postData)
            toastContext.notification("Alterações de vínculo de opções de montagem realizadas.", "success");
            handleCloseOpcoes();

        } catch (error) {
            const message = getSingleErrorMessage(error);
            if (message) {
                toastContext.notification(message, "warn");
            } else {
                toastContext.notification(
                    "Ocorreu um erro ao realizar essa operação.",
                    "error"
                );
            }
        }
    }

    const handleTemporaryCheckedOpcoes = (e: any) => {
        if (checkedOpcoes.filter(ev => ev.opcaoMontagemId === e.target.value).length > 0) {
            const index = checkedOpcoes.findIndex(element => element.opcaoMontagemId == e.target.value);
            let newArr = checkedOpcoes;
            newArr[index] = { ...newArr[index], selecionada: e.target.checked }
            setCheckedOpcoes(newArr);
        } else {
            setCheckedOpcoes(prevArray => [...prevArray, { opcaoMontagemId: parseInt(e.target.value), selecionada: e.target.checked }])
        }
    }

    const checkTemporaryCheckedOpcoes = (id: number, selecionadaNoBanco: boolean) => {
        const opt = checkedOpcoes.filter(ev => ev.opcaoMontagemId === id);
        if (selecionadaNoBanco && opt.length == 0) {
            return selecionadaNoBanco;
        }
        else if (opt.length != 0) {
            return opt[0].selecionada;
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "Selecionar",
                accessor: "selecionado",
                label: "Selecionar",
                display: "column-table-2b align-middle text-left text-md-center",
                Cell: ({ row }: any) => (
                    <label className="checkinput">
                        <input
                            type="checkbox"
                            className="checkinput-input"
                            defaultChecked={checkTemporaryCheckedOpcoes(row.original.opcaoMontagemId, row.original.selecionada)}
                            value={row.original.opcaoMontagemId}
                            onChange={(e) => handleTemporaryCheckedOpcoes(e)}
                        />
                        <span className="checkinput-color"></span>
                    </label>
                ),
            },
            {
                Header: "Categoria de Opção de Montagem",
                accessor: "descricao",
                label: "Categoria de Opção de Montagem",
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Modal show={vincularOpcoesContext.show} onHide={handleCloseOpcoes} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Vincular Opções</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm md="auto" className="mb-4 mb-md-0">
                        <Link className="btn btn-primary w-100" to="/opcoes" target="_blank">
                            <i className="fe fe-package"></i><span className="ml-2">Gerenciar Opções</span>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <div className={'dimmer' + (opcoesLoading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={'dimmer-content' + (opcoesLoading ? ' small-box-loader' : '')}>
                        {opcoes && (
                            <DataTable
                                className="mb-5"
                                columns={columns}
                                data={opcoes}
                                bordered={false}
                                paginate={false}
                            />
                        )}
                    </div>
                </div>
                <DataNotFound visible={opcoesLoading === false ? opcoes.length === 0 : false} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseOpcoes}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleSaveOpcoes}>
                    <i className="fa fa-save mr-2"></i>
                    Salvar Vínculo
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalVincularOpcoes;
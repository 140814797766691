
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import EsqueciSenha from '../pages/SignIn/esqueciSenha';
import AtivarConta from '../pages/SignIn/ativarConta';
import NovaSenha from '../pages/SignIn/novaSenha';
import Contact from '../pages/Contact';
import SignInClientes from '../pages/SignInClientes';

import Dashboard from '../pages/Dashboard';
import Empresa from '../pages/Empresa';
import Propostas from '../pages/Propostas';
import PropostaDetalhes from '../pages/PropostaDetalhes';

import Perfil from "../pages/Admin/Perfil";
import Usuarios from "../pages/Admin/Usuarios";
import Personalizacao from "../pages/Admin/Personalizacao";
import Opções from "../pages/Admin/Opções";
import Imagens from "../pages/Admin/Imagens";
import Videos from "../pages/Admin/Videos";

const Routes: React.FC = () => (
    <Switch>
        {/* Rotas Publicas */}
        <Route path="/" component={SignIn} exact isClienteFinal />
        <Route path="/ativar-conta" component={AtivarConta} exact isClienteFinal />  
        <Route path="/esqueci-senha" component={EsqueciSenha} exact isClienteFinal />        
        <Route path="/nova-senha/:pCodigo" component={NovaSenha} exact isClienteFinal />        
        <Route path="/contact" component={Contact} isClienteFinal/>
        <Route path="/valida-cliente/:pCodigo" component={SignInClientes} exact isClienteFinal/>

        {/* Rotas Privadas */}
        <Route path="/dashboard" component={Dashboard} isPrivate isAdm/>
        <Route path="/empresa" component={Empresa} isPrivate isClienteFinal />
        <Route path="/propostas" component={Propostas} isPrivate exact isClienteFinal />
        <Route path="/propostas/:pId" component={PropostaDetalhes} isPrivate exact isClienteFinal/>        
        
        <Route path="/perfil" component={Perfil} isPrivate exact isClienteFinal />
        <Route path="/usuarios" component={Usuarios} isPrivate exact />
        <Route path="/personalizacao" component={Personalizacao} isPrivate exact />
        <Route path="/opcoes" component={Opções} isPrivate exact />
        <Route path="/imagens" component={Imagens} isPrivate exact />
        <Route path="/videos" component={Videos} isPrivate exact />
    </Switch>
)

export default Routes;
import React, { useEffect, useRef, InputHTMLAttributes, useState } from "react";
import { useField } from "@unform/core";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: any;
    id?: any;
    title?: string;
    src: string;
}

const CheckboxImage: React.FC<Props> = ({ name, id, title, src, defaultChecked, style, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, registerField } = useField(name);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref: HTMLInputElement) => {
                const dados = {imagemId: ref.value, selecionada: ref.checked}
                return dados;
            },
            clearValue: (ref: HTMLInputElement) => {
                ref.checked = false;
            },
            setValue: (ref: HTMLInputElement, value: any) => {
                ref.checked = value;
            }
        });       
    }, [fieldName, registerField]);

    return (
        <>            
            <label className="card mb-4">
                <input id={id} ref={inputRef} type="checkbox" className="imagecheck-input" defaultChecked={defaultChecked} {...rest}/>
                <figure className="imagecheck-figure">
                    <img src={src} alt={title} className={"imagecheck-image img-cover" + (loading ? " skeleton-image" : "")} onLoad={() => setLoading(false)} />
                    <div className="p-2">{title}</div>
                </figure>
            </label>
        </>
    );
};

export default CheckboxImage;
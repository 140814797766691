import React, { useContext, useEffect, useState } from 'react'
import imgPlaceholder from '../../assets/img/img-placeholder-ret.jpg';
import Carousel from '../../components/Carousel'
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import api from '../../services/api'

import { ToastContext } from '../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';

import './styles.css'
import { useAuth } from '../../hooks/auth';
import { NivelAcesso } from '../../Enums/Enums';
import ReactPlayer from 'react-player';

interface IDados {
    urlImagem: string,
    titulo: string,
    texto: string,
    endereco: string,
    emails: {
        email: string,
    }[],
    telefones: {
        telefone: string,
        whatsapp: boolean
    }[],
    projetos: {
        descricao: string,
        urlImagem: string,
    }[]
    fornecedores: {
        fornecedor: string,
        urlLogo: string,
        urlSite: string,
    }[],
    produtos: {
        produto: string,
        urlLogo: string,
    }[]
}

interface IShowImage {
    showing: boolean,
    src?: string,
}
interface IPropostaDetalhes{
    id: number;
    propostaAberta: boolean;
}

const Empresa: React.FC = () => {

    let history = useHistory();

    //Context
    const toastContext = useContext(ToastContext);
    
    const { fabricante, nivelAcesso, user } = useAuth();
    
    //States
    const [loading, setLoading] = useState(false);
    const [showSaibaMais, setShowSaibaMais] = useState(false);
    const [dados, setDados] = useState({} as IDados);
    const [showImage, setShowImage] = useState<IShowImage>({ showing: false });
    const [propostas, setPropostas] = useState<IPropostaDetalhes[]>([]);
    
    const numero = propostas.filter((item) => { return item.propostaAberta === false});

    useEffect(() =>{
        if(nivelAcesso === NivelAcesso.Cliente){
            try {
                const fetchData = async () => await api.get(`/propostas?Desc=true`);
                fetchData().then((response:any) => {
                    setPropostas(response.data.items);
                })
                .catch(error => {
                    ShowErrors(error);
                })
            } 
            catch (error) {
                ShowErrors(error);
            }
        }
    }, []);
    
    const ShowErrors = (error:any) => {
        const message = getSingleErrorMessage(error);
        if (message) {
            toastContext.notification(message, "warn");
        } else {
            toastContext.notification(
                "Ocorreu um erro ao realizar essa operação.",
                "error"
            );
        }
    }

    const handleClick = () => {
        var propostasNaoAbertas = propostas.filter((p:any) => !p.aberturaProposta);
        
        if(propostasNaoAbertas.length === 1){
            history.push(`/propostas/${propostasNaoAbertas[0].id}`);
        }else{
            history.push(`/`);
        }
    }

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get(`/Fabricantes/Minisite`);
                setDados(data)
                setLoading(false);

            } catch (err) {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        })()
    }, []);

    
    //Handles
    const handleShowImage = (url: string) => {
        setShowImage({
            showing: true,
            src: url,
        })
    }
    
    return (
        <>
            <div className={'dimmer' + (loading ? ' active' : '')}>
                <div className="loader"></div>
                <div className={'dimmer-content' + (loading ? ' medium-box-loader' : '')}>
                    {!loading && (
                        <>
                            <div className="container">
                                <div className="row">
                                    <div id='img' className={`mh-100 col-12 col-md-12 col-lg-7 img-fluid bg-responsive ${!dados.urlImagem ? "border" : ""}`} style={{ background: `url(${dados.urlImagem ? dados.urlImagem : imgPlaceholder}) no-repeat` }}>
                                    </div>
                                    <div className="col-12 col-lg-5 d-flex">
                                    {fabricante?(
                                        <div id='bv'>
                                            {nivelAcesso === "Administrador" &&
                                            <div className='ml-5'>
                                                <h2 className="mt-lg-0">{dados.titulo ? dados.titulo : "Local para o título do texto"}</h2>
                                                {dados.texto ? (
                                                <p className='texto text-justify' style={{ whiteSpace: "pre-wrap" }}>{dados.texto}</p>
                                                ) : (
                                                    <>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </>
                                                )}
                                            </div>}
                                        </div> 
                                        ):(
                                        <div id='bv' className='ml-5 align-items-end'>
                                            <h2 className='col-12 col-md-12 mt-3'>{dados.titulo}</h2>
                                            <div className='col-12 col-md-12'>
                                                <p className='mt-2'>Olá Sr. <strong>{user?.nome}</strong></p>
                                                <p className='mt-2'>Seja muito bem vindo a nossa área do cliente.</p>
                                                <div id='button' className='mt-8'>
                                                    <Button variant="primary" title="Acesse seus pedidos!" onClick={handleClick}>
                                                        <i className="fe fe-bell"> </i> Acesse sua proposta</Button>
                                                </div>
                                                <p style={{paddingTop:10}} className='my-4 numPO'>Você tem <span className="badge badge-dark rounded-circle px-2 mx-1" style={{background:'rgb(69, 129, 207)'}}> {numero.length}  </span> atualização de pedido</p>
                                            </div>
                                
                                       </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            {dados.fornecedores?.length > 0?(

                                <div id="fornecedores" className="container-fluid bg-white">
                                    <div className="container py-7">
                                        <div className='d-block d-sm-flex'>
                                            <h2 className="col text-center text-sm-right">Nossos <span style={{color: "rgb(69, 129, 207)"}}>Fornecedores</span></h2>
                                            <p className="col text-leght mt-4">Temos parceria com os principais fornecedores do mercado.</p>
                                        </div>
                                        <div className="row">
                                            <Carousel show={4}>
                                                    {dados.fornecedores?.map(forn => (
                                                        <div key={forn.urlLogo} style={{ height: "100%" }}>
                                                            <div style={{ padding: 8, height: "100%" }}>
                                                                <a href={forn.urlSite} target="__blank" className="d-block h-100" >
                                                                    <img src={forn.urlLogo} alt={forn.fornecedor} className="carousel-cover-fornecedor" style={{borderRadius:10}}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            ):(<></>)}
                            {dados.produtos?.length>0?(
                                <div id="produtos" className="container py-7">
                                    <div className='d-block d-sm-flex'>
                                        <h2 className="col text-center text-sm-right">Nossos <span style={{color: "rgb(69, 129, 207)"}}>Produtos</span></h2>
                                        <p className="col text-leght mt-4">Esses são os produtos que oferecemos aos nossos clientes.</p>
                                    </div>
                                    <div className="row">
                                        <Carousel show={4}>
                                                {dados.produtos?.map(prod => (
                                                    <div key={prod.urlLogo} style={{ height: "100%" }}>
                                                        <div style={{ padding: 8, height: "100%" }}>
                                                            <img src={prod.urlLogo} alt={prod.produto} className="carousel-cover" style={{borderRadius:10}}/>
                                                        </div>
                                                    </div>
                                                ))}
                                        </Carousel>
                                    </div>
                                </div>
                            ):(<></>)}
                            {dados.projetos?.length >0?(
                                <div id="projetos" className="container-fluid bg-white">
                                    <div className="container py-7">
                                        <div className='d-block d-sm-flex'>
                                            <h2 className="col text-center text-sm-right">Nossos <span style={{color: "rgb(69, 129, 207)"}}>Projetos</span></h2>
                                            <p className="col mt-4" style={{textAlign:"justify"}}>Confira alguns de nossos últimos projetos em destaque.</p>
                                        </div>
                                        <div className="row">
                                            <Carousel show={4}>
                                                    {dados.projetos?.map(projeto => (
                                                        <div key={projeto.urlImagem} style={{ height: "100%"}}>
                                                            <div style={{ padding: 8, height: "100%"}}>
                                                                <Link to="#" onClick={() => handleShowImage(projeto.urlImagem)} className="d-block h-100" >
                                                                    <img src={projeto.urlImagem} alt={projeto.descricao} className="carousel-cover" style={{borderRadius:10}}/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            ):(<></>)}
                            <div id="contato" className="container py-7">
                                <div className='d-block d-sm-flex'>
                                    <h2 className="col text-center text-sm-right" style={{color: "rgb(69, 129, 207)"}}>Contato</h2>
                                    <p className="col text-justify mt-4">Em nosso endereço, por e-mail ou telefone, estamos prontos para te atender.</p>
                                </div>
                                <div className="row row-cards row-deck">
                                    <div className="col-md-4">
                                        <div className="card info-card" style={{background:'RGB(245,247, 251)', border:'none'}}>
                                            <div className="card-body my-4 text-center" style={{backgroundColor:'none'}}>
                                                <h4><i className="fe fe-compass"></i>Endereço</h4>
                                                <p style={{ whiteSpace: "pre-wrap" }}>
                                                    {dados.endereco}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card info-card"  style={{background:'RGB(245,247, 251)',border:'none'}}>
                                            <div className="card-body my-4 text-center">
                                               <h4><i className="fe fe-at-sign"></i>E-mail</h4>
                                                {dados.emails?.map((val, idx) => (
                                                    <p key={idx}>{val.email}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" >
                                        <div className="card info-card" style={{background:'RGB(245,247, 251)', border:'none'}}>
                                            <div className="card-body my-4 text-center">
                                                <h4><i className="fe fe-phone"></i>Telefone</h4>
                                                {dados.telefones?.map((val, idx) => (
                                                    <p key={idx}>{val.telefone} {val.whatsapp ? "- WhatsApp" : ""}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </div>
                {!fabricante && (<>
                <div id='divSaibaMais'>
                    <Button 
                        title="Como funciona a Proposta online" 
                        variant='success'
                        className='saibaMais' 
                        onClick={() => setShowSaibaMais(true)}
                        >Como usar?
                    </Button>
                </div>
                </>)}
            </div>

            {/* Modal Lightbox */}
            <Modal show={showImage.showing} onHide={() => setShowImage({ showing: false })} centered size="lg">
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <img src={showImage.src} alt="Imagem" />
                </Modal.Body>
            </Modal>
            <Modal show={showSaibaMais} onHide={() => setShowSaibaMais(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Proposta Online - Passo a passo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="player-wrapper">
                        <ReactPlayer
                            // light
                            controls
                            playsinline
                            className='react-player'
                            width='100%'
                            height='100%'
                            url='https://www.youtube.com/watch?v=0pAuh12nLgA'
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Empresa;
import React, { useContext, useRef, useState } from "react";
import { Row, Col, Button, Carousel, CarouselItem, Modal, ModalBody } from "react-bootstrap";
import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";

import { faArrowCircleLeft, faArrowCircleRight, faPen, faPlay } from "@fortawesome/free-solid-svg-icons";
import logo from '../../../assets/img/proposta-online-logo.svg'

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../helpers/getValidateErrors';

import { getSingleErrorMessage } from '../../../helpers/getSingleErrorMessage';

import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { ToastContext } from '../../../contexts/toast/toastContext';
import { VincularImagensContext } from '../../../contexts/vincularImagens/vincularImagensContext';
import { VincularVideosContext } from '../../../contexts/vincularVideos/vincularVideosContext';
import { MenuLateralContext } from '../../../contexts/menuLateral/menuLateralContext';
import { ExibirOpcoesClienteContext } from '../../../contexts/exibirOpcoesCliente/exibirOpcoesClienteContext';

import { ITipologia } from "../../../interfaces/proposta.interface";
import { IOptionsString } from "../../../interfaces/options.interface";

import './styles.css'

 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModelViewer } from "../../../components/ModelViewer";
import ReactPlayer from 'react-player';
import DadosOriginais from "../Tipologia/DadosItemOriginais";
import FormularioEdicao from "../Tipologia/FormDadosItem";

interface IPropsTipologia {
    tipologia: ITipologia,
    versao: number,
    setAreaAtiva: React.Dispatch<React.SetStateAction<string>>,
    status: number,
    editavel:boolean,
    coresPerfis:IOptionsString[],
    vidros:IOptionsString[]
}

interface FormData {
    propostaItemId: string
    largura: number,
    altura: number,
    quantidade: number,
    cor: { label: string, value: string },
    vidros: { 
        vidro: {label: any, value: any }
    }[]
}

interface ILocalizacao{
    index:number;
    descricao:any;
    vidro:any;
};

const Tipologia: React.FC<IPropsTipologia> = ({ tipologia, versao, setAreaAtiva, status, editavel, coresPerfis, vidros }) => {
    //Auth
    const { fabricante } = useAuth();
   
    const lista: ILocalizacao[] = [];

    //State
    const [carosel, setCarosel] = useState('');
    const [area3D, setArea3D] = useState('');
    const [edicao, setEdicao] = useState(false);
    const [showModalVideo, setShowModalVideo] = useState(false);

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Context
    const toastContext = useContext(ToastContext);
    const sweetAlert = useContext(SweetAlertContext);
    const vincularImagensContext = useContext(VincularImagensContext);
    const vincularVideosContext = useContext(VincularVideosContext);
    const menuLateralContext = useContext(MenuLateralContext);
    const exibirOpcoesClienteContext = useContext(ExibirOpcoesClienteContext);

    //Carousel
    const prevIcon = <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: "#77a4e4", fontSize: "20px"}}/>
    const nextIcon = <FontAwesomeIcon icon={faArrowCircleRight} style={{color: "#77a4e4", fontSize: "20px"}}/>



    const onSave = async (data:any) => {
        try {
            
            formRef.current?.setErrors({});
            
            const message = "Este campo não pode ficar vazio."
            const schema = Yup.object().shape({
                largura: Yup.string().required(message),
                altura: Yup.string().required(message),
                quantidade: Yup.string().required(message),
                cor: Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  }).nullable().required(message),
            });
            await schema.validate(data, {
                
                abortEarly: false,
            });
            
            const vidros = (data: FormData) => {
                if(data.vidros.length > 0 || lista.length > 0){
                    data.vidros.map((item, index) => {
                        lista.map((vid, i) => {
                            if(Number(index) === Number(vid.index)){
                                data.vidros.splice(index, 1, {vidro:{ label:vid.descricao, value:vid.vidro}})                             
                            }
                        })
                    })

                    const vidros = data.vidros.map((vid) => {
                        return { descricao: vid.vidro.label, vidro: vid.vidro.value }
                    })

                    return vidros
                }           
            }

            setEdicao(false)
            
            const putObj = () => {
                if (vidros(data) !== undefined) {
                    return {
                        propostaItemId: tipologia.id,
                        largura: data.largura,
                        altura: data.altura,
                        quantidade: data.quantidade,
                        codigoCor: data.cor.value,
                        descricaoCor: data.cor.label,
                        vidros: vidros(data)
                    }
                }
                else {
                    return {
                        propostaItemId: tipologia.id,
                        largura: data.largura,
                        altura: data.altura,
                        quantidade: data.quantidade,
                        codigoCor: data.cor.value,
                        descricaoCor: data.cor.label,
                    }
                }
            }
            await api.put("/Propostas/AdicionarLogItem", putObj())
            menuLateralContext.setReloadLog(prevState => !prevState);
            toastContext.notification("Ajuste Salvo.", "success");
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    }

    const handleSelecao = (descricao:any, vidro:string, index:number) => {
        const valor = lista.find(item => item.vidro === vidro);
        if (lista.length === 0){
             lista.push({index:index, descricao:descricao.value, vidro:vidro})
        } else if (valor !== undefined){
            lista.map((item, i) => { if(item.vidro === vidro) lista.splice(i, 1, {index:index, descricao:descricao.value, vidro:vidro})})            
        } else{
            lista.push({index:index, descricao:descricao.value, vidro:vidro});
        };
    }
    
    const handleDesfazer = async (id: string) => {
      const titleAlert = `Deseja desfazer as alterações? `;
      const message = ` Após a confirmação isso não pode ser desfeito.`;
      sweetAlert.danger(titleAlert, message, async () => {
          try {
              sweetAlert.close();
              const putObj = {
                  id: id
              };
              await api.post(`/Propostas/RemoverLogItem`, putObj);
              menuLateralContext.setReloadLog(prevState => !prevState)
              toastContext.notification("Ajuste desfeito.", "success");
          } catch (error) {
              const message = getSingleErrorMessage(error);
              if (message) {
                  toastContext.notification(message, "warn");
              } else {
                  toastContext.notification(
                      "Ocorreu um erro ao realizar essa operação.",
                      "error"
                  );
              }
          }
      });
    }
    
    const handleDelete = async (id: string, descricao: string) => {
        const titleAlert = `Remover "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                const putObj = {
                    propostaItemId: id
                }
                await api.put("/Propostas/MarcarItemRemovido", putObj)
                menuLateralContext.setReloadLog(prevState => !prevState)
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        });
    }

    const generateCarouselItens = () => {
        switch (tipologia.imagens.length) {
            case 0:
                return <>
                    <Carousel fade nextIcon={nextIcon} prevIcon={prevIcon} interval={null}>
                        <CarouselItem onClick={()=> handleCarosel(tipologia.urlCroqui)} style={{height:250}} className='text-center'>
                            <img src={tipologia.urlCroqui ? tipologia.urlCroqui : "https://via.placeholder.com/420x270"} style={{height:250}} alt='Imagem do croqui' />
                        </CarouselItem>
                        <CarouselItem style={{height:250}}>
                            {tipologia.videos?.length > 0 ? (<>
                            <div className='text-center'>
                                <Button  
                                    id="videoCarousel"
                                    variant="outline-primary"
                                    title="Ver vídeo da esquadria"
                                    onClick={() => setShowModalVideo(true)}>
                                    <FontAwesomeIcon icon={faPlay} className='ml-2'/>
                                </Button>
                            </div>
                            </>
                            ):(<>
                            <div id="logo" className="text-center">
                                <img src={logo} className="h-5" alt=""  style={{height:250}} />
                            </div>
                            </>)}
                        </CarouselItem>
                    </Carousel>
                </>
            case 1:
                return <>
                    <Carousel fade nextIcon={nextIcon} prevIcon={prevIcon} interval={null}>
                        <CarouselItem style={{height:250}}>
                            <img 
                            src={tipologia.urlCroqui ? tipologia.urlCroqui : "https://via.placeholder.com/420x270"} 
                            style={{height:250}} 
                            alt='Imagem do croqui' 
                            />
                        </CarouselItem>
                        <CarouselItem style={{height:250}}>
                            <img 
                            src={tipologia.imagens[0].url ? tipologia.imagens[0].url : "https://via.placeholder.com/420x270"} 
                            style={{height:250}} 
                            alt='Imagem do item' 
                            />
                        </CarouselItem>
                        <CarouselItem style={{height:250}}>
                            {tipologia.videos?.length > 0 ? (<>
                            <div className='text-center'>
                                <Button  
                                    id="videoCarousel"
                                    variant="outline-primary"
                                    title="Ver vídeo da esquadria"
                                    onClick={() => setShowModalVideo(true)}>
                                    <FontAwesomeIcon icon={faPlay} className='ml-2'/>
                                    
                                </Button>
                            </div>
                            </>
                            ):(<>
                            <div id="logo" className="text-center">
                                <img src={logo} className="h-5" alt=""  style={{height:250}} />
                            </div>
                            </>)}
                        </CarouselItem>
                    </Carousel>
                </>
            default:
                return <>
                    <Carousel fade nextIcon={nextIcon} prevIcon={prevIcon} interval={null}>
                        <CarouselItem style={{height:250}}> 
                            <img 
                            src={tipologia.urlCroqui ? tipologia.urlCroqui : "https://via.placeholder.com/420x270"} 
                            alt='Imagem do croqui' 
                            style={{height:250}} 
                            />
                        </CarouselItem>
                        {tipologia.imagens?.map((imagem, index) =>
                        <CarouselItem key={index} style={{height:250}}>
                                <img 
                                src={imagem.url ? imagem.url : "https://via.placeholder.com/420x270"} 
                                alt='Imagem do item' 
                                style={{height:250}}   
                                />
                        </CarouselItem>
                        )}
                        <CarouselItem style={{height:250}}>
                            {tipologia.videos?.length > 0 ? (
                            <>
                            <div className='text-center'>
                                <Button  
                                    id="videoCarousel"
                                    variant="outline-primary"
                                    title="Ver vídeo da esquadria"
                                    onClick={() => setShowModalVideo(true)}>
                                    <FontAwesomeIcon icon={faPlay} className='ml-2'/>
                                </Button>
                            </div>
                            </>
                            ):(<>
                            <div id="logo" className="text-center">
                                <img src={logo} className="h-5" alt=""  style={{height:250}} />
                            </div>
                            </>)}
                        </CarouselItem>
                    </Carousel>
                </>
        }
        
    }
    const handleCarosel = async (car:string) => {
        return setCarosel(car);
    }
    return (
        <>
            <div className={`dimmer ${tipologia.removido ? ' active' : ''}`}>
                <div className={`dimmer-content ${tipologia.removido ? "item-removido" : ""}`}>
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col xs={12} md={3} className="d-flex flex-column align-items-center justify-content-center">
                                    <Row style={{ width:500, height:250}} className='img-fluid d-lg-block'>
                                       <div id="carousel" > 
                                            {generateCarouselItens()}
                                       </div>
                                       
                                    </Row>
                                </Col>
                                <div className="vr" />
                                <Col xs={12} md className="mt-5 mt-md-0 d-flex flex-column">
                                    {((tipologia.possuiLogAlteracao || tipologia.removido) && !fabricante) &&
                                        <Row>
                                            <Col className="text-right">
                                                {tipologia.removido && (
                                                    <span className="tag tag-red mb-3">Removido</span>
                                                )}
                                                {tipologia.possuiLogAlteracao && (
                                                    <span className="tag tag-blue mb-3 ml-2">Alterado</span>
                                                )}
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <label className="tipo"><b>Tipo:</b>{tipologia.tipo}</label>
                                        </Col>
                                        <Col className="btnEdicao col-9 col-md-12">
                                          <h4 className="col-9 col-md-8">{tipologia.descricao}</h4>
                                          {!fabricante && (<>
                                            {editavel && !tipologia.removido && (<>
                                              {!tipologia.possuiLogAlteracao && (<>
                                                {!edicao && (
                                                  <Button
                                                    variant="outline-primary"
                                                    title={"Editar o item"}
                                                    onClick={() => setEdicao(true)}
                                                    >
                                                      <FontAwesomeIcon icon={faPen}/>
                                                  </Button>
                                                )}</>
                                              )}</>
                                            )}</>
                                          )}
                                        </Col>
                                    </Row>
                                    {fabricante ? (<>
                                      {status >= 4  ? (<>
                                        {tipologia.possuiLogAlteracao ? 
                                            (<FormularioEdicao
                                              tipologia={tipologia}
                                              status={status}
                                              coresPerfis={coresPerfis}
                                              vidros={vidros} 
                                              editavel={editavel}
                                              onSave={onSave}
                                              onDelete={handleDelete}
                                              onUndo={handleDesfazer}  
                                              onSelecao={handleSelecao}                                                                 
                                            />
                                            ):(
                                            <DadosOriginais tipologia={tipologia} vidros={vidros}/>)
                                        }</>
                                        ):(<DadosOriginais tipologia={tipologia} vidros={vidros}/>)
                                        }</>
                                      ):( <>
                                        {editavel ? (<>
                                          {tipologia.possuiLogAlteracao ? (
                                            <FormularioEdicao
                                              tipologia={tipologia}
                                              status={status}
                                              coresPerfis={coresPerfis}
                                              vidros={vidros} 
                                              editavel={editavel}
                                              onSave={onSave}
                                              onDelete={handleDelete}
                                              onUndo={handleDesfazer}
                                              onSelecao={handleSelecao}                                                                   
                                            />
                                          ):(<>
                                            {edicao ? (
                                              <FormularioEdicao
                                                tipologia={tipologia}
                                                status={status}
                                                coresPerfis={coresPerfis}
                                                vidros={vidros} 
                                                editavel={editavel}
                                                onSave={onSave}
                                                onDelete={handleDelete}
                                                onUndo={handleDesfazer}
                                                onSelecao={handleSelecao}                                                                      
                                              />):(
                                              <DadosOriginais tipologia={tipologia} vidros={vidros}/>)
                                            }</>)
                                          }</>
                                        ):(<>
                                          {tipologia.possuiLogAlteracao === true ? 
                                            (<FormularioEdicao
                                              tipologia={tipologia}
                                              status={status}
                                              coresPerfis={coresPerfis}
                                              vidros={vidros} 
                                              editavel={editavel}
                                              onSave={onSave}
                                              onDelete={handleDelete}
                                              onUndo={handleDesfazer}
                                              onSelecao={handleSelecao}                                                                   
                                            />):(
                                            <DadosOriginais tipologia={tipologia} vidros={vidros}/>)
                                          }</>
                                        )}</>
                                      )}
                                    <Row className="mt-4 h-100 d-flex justify-content-center align-items-center">
                                    {fabricante && versao ?
                                    <>
                                        <Col sm={4} md={6} lg={4} xl="auto">
                                            <Button
                                                variant="outline-primary"
                                                className="px-0 px-xl-5 w-100 mb-3 mb-sm-0 mb-md-3 mb-lg-0"
                                                onClick={() => vincularImagensContext.handleModal(tipologia.id)}
                                                disabled={!editavel}
        
                                            >Vincular Imagens</Button>
                                        </Col>
                                        <Col sm={4} md={6} lg={4} xl="auto">
                                            <Button
                                                variant="outline-primary"
                                                className="px-0 px-xl-5 w-100"
                                                onClick={() => vincularVideosContext.handleModal(tipologia.id)}
                                                disabled={!editavel}
                                            >Vincular Vídeos</Button>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        {(!tipologia.removido && tipologia.possuiOpcoesVinculadas && editavel) && (
                                            <Col xs={12} md={10} lg xl="auto">
                                                <Button
                                                    variant="outline-primary"
                                                    className="px-0 px-xl-4 w-100 mt-3 mt-lg-0"
                                                    onClick={() => exibirOpcoesClienteContext.handleModal(tipologia.id)}
                                                    >
                                                    Mais opções para esse produto
                                                </Button>
                                            </Col>
                                        )}
                                    </>
                                    }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            
            {area3D === '3D'? (
                <div style={{borderTop: '1px solid #e0e0e0', marginTop:20, borderBottom: '1px solid #e0e0e0', marginBottom:20}}>
                    <Col className="area-3D mt-3">
                      <ModelViewer esquadriaUrl3D={menuLateralContext.tipologia.esquadriaUrl3D} />
                    </Col>
                </div>
            ):(<></>)}
            <Modal show={showModalVideo} onHide={() => setShowModalVideo(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Vídeos do Produto:</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    {tipologia.videos?.map((video)=>(
                    <Col key={video.id}>
                        <ReactPlayer
                            controls
                            playsinline
                            width='100%'
                            url={video.url}
                        /> 
                    </Col>
                    ))}
                </ModalBody>
            </Modal>
        </>
    );
}

export { Tipologia };

// import FloatingLabel from "react-bootstrap/FloatingLabel";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextArea from "../../../../components/TextArea";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import api from "../../../../services/api";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { useAuth } from "../../../../hooks/auth";

const Termo: React.FC = () => {
  //Ref
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);

  //States
  const [showModalTermo, setShowModalTermo] = useState(false);
  const [reload, setReload] = useState(false);

  //Parâmetros
  const { user } = useAuth();

  //Effects
  useEffect(() => {
    (async () => {
      try {
        const { data: termo } = await api.get(`/Termo/padrao/fabricante/${user?.fabricanteId}`);
        if(termo) termo.texto && formRef.current?.setFieldValue("textoTermo", termo.texto);
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })();
  }, [showModalTermo, reload]);

  //Handles
  const handleSubmitEditarTermo: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Esse campo não pode ficar vazio.";
      const schema = Yup.object().shape({
        textoTermo: Yup.string().required(message),
      });
      await schema.validate(dados, { abortEarly: false });
      
      //Passou pela validação
      const dadosTermos = {
        nome: "Termo Padrão",
        texto: dados.textoTermo,
        padrao: true,
      };

      await api.post("/Termo", dadosTermos);
      toastContext.notification("Termo atualizado.", "success");
      setReload(prevState => !prevState);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      } else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }
  };

  return (
    <>
      <div className="card personalizacao-termo">
        <div className="card-header">
          <Row>
            <h3 className="card-title">Termo de Uso | Garantia</h3>
          </Row>
        </div>
        <div className="card-body text-center">
          <Row>
            <Col>
              <Form ref={formRef} onSubmit={handleSubmitEditarTermo}>
                  <TextArea
                    name="textoTermo"
                    placeholder="Digite um texto..."
                    className="form-control"
                    maxLength={8000}
                    rows={8}
                  />
              </Form>              
            </Col>
          </Row>
        </div>
        <div className="card-footer text-right col-xs-12 col-sm-12 col-md-12">
          <Button
            variant="primary"
            title="Editar Termo"
            onClick={() => formRef.current?.submitForm()}
          >
            <i className="fa fa-save mr-2"></i>Salvar Termo | Garantia
          </Button>
        </div>                    
      </div>
    </>
  );
};

export default Termo;

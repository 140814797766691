import React, { useEffect, useRef, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";
import ReactPlayer from 'react-player';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: any;
    id?: any;
    title?: string;
    src: string;
}

const CheckboxVideo: React.FC<Props> = ({ name, id, title, src, defaultChecked, style, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, registerField } = useField(name);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref: HTMLInputElement) => {
                const dados = {videoId: ref.value, selecionada: ref.checked}
                return dados;
            },
            clearValue: (ref: HTMLInputElement) => {
                ref.checked = false;
            },
            setValue: (ref: HTMLInputElement, value: any) => {
                ref.checked = value;
            }
        });

    }, [fieldName, registerField]);

    return (

        <>
            <label className="card mb-4">
                <input id={id} ref={inputRef} type="checkbox" className="imagecheck-input" defaultChecked={defaultChecked} {...rest} />
                <div className="imagecheck-figure">
                    <div className="player-wrapper">
                        <ReactPlayer
                            light
                            controls
                            playsinline
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={src}
                        />
                    </div>
                    <div className="p-2">{title}</div>
                </div>
            </label>
        </>

    );
};

export default CheckboxVideo;
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import LoadingBlock from '../LoadingBlock';

enum valueTypeEnum {
    money,
    int,
    decimal
}

type Props = {
    labels: string[],
    values: number[],
    valueType?: valueTypeEnum
};

const DonutValor: React.FC<Props> = ({ labels, values, valueType = valueTypeEnum.int }) => {

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Valor de Orçamentos',
                data: values,
                backgroundColor: [
                    'purple',
                    '#c90d58',

                    // 'rgba(70, 127, 207, 0.2)',
                    // 'rgba(94, 186, 0, 0.2)'
                ],
                borderColor: [
                    '#b8054c',
                    '#770432',
                    // 'rgba(70, 127, 207, 1)',
                    // 'rgba(94, 186, 0, 1)'
                ],
                borderWidth: 1,
            },
        ],
    }

    const options = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    let prefix = valueType == valueTypeEnum.money ? 'R$' : '';
                    return prefix + data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                },
            }
        },
    }

    return (
        <LoadingBlock loading={!values}>
            <Doughnut  options={options} data={data} />
        </LoadingBlock>
    );

};

interface PropsLinhas {
    values: number[]
    linhas: string[]
};

const DonutLinha: React.FC<PropsLinhas> = ({ values, linhas }) => {

    const data = {
        labels: linhas,
        datasets: [
            {
                label: 'Valor de Orçamentos',
                data: values,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <LoadingBlock loading={!values}>
            <Doughnut data={data} />
        </LoadingBlock>
    );

};

export { DonutValor, DonutLinha }
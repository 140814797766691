
import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Stage, Layer, Text, Image, Group, Ellipse } from 'react-konva'
import RadioCheckPlantas from "../../components/RadioCheckPlantas"
import { NoData } from '../DataNotFound';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

import ModalGerenciarPlantas from '../../pages/PropostaDetalhes/Modais/ModalGerenciarPlantas';

import { ToastContext } from "../../contexts/toast/toastContext";
import { MenuLateralContext } from "../../contexts/menuLateral/menuLateralContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import "./styles.css";
import LoadingBlock from '../LoadingBlock';
import { useAuth } from '../../hooks/auth';

interface ITipos {
  id: string,
  tipo: string,
  posicaoEixoX: number,
  posicaoEixoY: number,
  isDragging: boolean
}
interface IRetornoCheckDimensions {
  imgWidth: number,
  imgHeight: number
}

interface IListPlantas {
  id: number,
  nome: string,
  url: string,
  checked?: boolean
}
interface IResumo {
  tipo: string,
  quantidadeDisponivel: number,
}
interface IEllipse{
  itens:IResumo[],
  heightLine: number;
}
interface IProps {
  versao: number,
  editavel: boolean,
  ativo: boolean
}

export const PlantaBaixaViewer = ({ versao, editavel, ativo }: IProps) => {
  //Parâmetros
  const { pId }: any = useParams();
  const { fabricante } = useAuth();

  //Refs
  const ref = useRef<any>(null);
  const stage = useRef<any>(null);
  const layerBolinhas = useRef<any>(null);
  const refCirculo = useRef<any>(null);
  const refTexto = useRef<any>(null);
  const menu = useRef<any>(null);

  //Contexts
  const toastContext = useContext(ToastContext);
  const menuLateralContext = useContext(MenuLateralContext);

  //States
  const [tipos, setTipos] = useState<ITipos[]>([])
  const [image, setImage] = useState<any>(null)
  const [imageSize, setImageSize] = useState<any>({ width: window.screen.width / 2, height: window.screen.height })
  const [scale, setScale] = useState(1)
  
  const [loading, setLoading] = useState(false);
  const [plantas, setPlantas] = useState<IListPlantas[]>([]);
  const [plantaSelecionada, setPlantaSelecionada] = useState(0);
  const [resumo, setResumo] = useState<IResumo[]>([])

  const [showModalGerenciarPlantas, setShowModalGerenciarPlantas] = useState(false);
  const [reloadPlantas, setReloadPlantas] = useState(false);
  const [bolinhaASerExcluida, setBolinhaASerExcluida] = useState("");
  
  const [ heightImg, setHeightImg ] = useState(90);
  const [ listTemp, setListTemp ] = useState<IResumo[]>([])
  const [ listEllipse, setListElipse ] = useState<IEllipse[]>([]);
  const [ escuta, setEscuta ] = useState(false);
  const [ heightViewer, setHeightViewer] = useState(90)
  
  const checkDimension = (img: HTMLImageElement) => {
    return new Promise<IRetornoCheckDimensions>(resolve => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const dimensions = { imgWidth: width, imgHeight: height }
        resolve(dimensions);
      };
    });
  }

  const resizeImage = async (img: HTMLImageElement) => {
    var MAX_WIDTH = 710;
    var MAX_HEIGHT = 900;
    var { imgWidth, imgHeight } = await checkDimension(img);
    
    if (imgWidth > imgHeight) {
      if (imgWidth > MAX_WIDTH) {
        imgHeight *= MAX_WIDTH / imgWidth;
        imgWidth = MAX_WIDTH;
      }
    } else {
      if (imgHeight > MAX_HEIGHT) {
        imgWidth *= MAX_HEIGHT / imgHeight;
        imgHeight = MAX_HEIGHT;
      }
    }
    setImageSize({ width: imgWidth, height: imgHeight })
    setTimeout(() => {
      setLoading(false);
    }, 1);
  }
  
  const handleSelectPlanta = async (id: number) => {
    listEllipse.length = 0;
   
    setPlantaSelecionada(id);
    layerBolinhas.current.removeChildren();
    
  const { data } = await api.get(`/Propostas/${pId}/versoes/${versao}/plantas/${id}`);
    setPlantas(prevState => (
      prevState.map((planta) =>
      planta.id === id ? { ...planta, checked: true } : { ...planta, checked: false }
      )
  ));

    const img = new window.Image();
    img.src = data.url
    if(data.url === null) {
      return (
        setPlantas([]),
        setImage(null), 
        setResumo([]),
        setTipos([])
        );
      }else{
        setEscuta(true);
        resizeImage(img);
        setImage(img);
        setResumo(data.resumo);
        setTipos(data.itens);
      };
  }

  const handleClone = (e: any, res: any) => {
    e.target.stopDrag();

    const cloneObj = listEllipse.map(item => 
      item.itens.find((res: any) => res.tipo === e.target.attrs.name)).filter(e => e !== undefined);

      if (cloneObj[0]?.tipo === e.target.attrs.name && cloneObj[0]?.quantidadeDisponivel !== 0) {
        var clone = e.target.clone({});
        clone.off('dragstart');        
        layerBolinhas.current.add(clone);
        clone.startDrag();
        listEllipse.map(item => 
          item.itens.map((dados, i) =>          
          dados.tipo === e.target.attrs.name ? 
          item.itens.splice(i,1, {tipo: e.target.attrs.name, quantidadeDisponivel: dados.quantidadeDisponivel - 1})
          : 
          { ...dados }
          ));       
          handleSave(false); 
    } else {     
      toastContext.notification("Não há mais tipologias desse tipo para posicionar.", "warn");
    }
  }
  
  const handleDragStart = (e: any) => {
    e.target.scaleX(1.2);
    e.target.scaleY(1.2);
  }

  const handleDragEnd = (e: any) => {
    e.target.scaleX(1);
    e.target.scaleY(1);
  }

  const handleSave = async (clone?:boolean) => {
    try {
      setLoading(true);

      const bolinhas = layerBolinhas.current.children.map((bolinha: any) => {
        const response = {
          tipo: bolinha.attrs.name,
          posicaoEixoX: bolinha.attrs.x,
          posicaoEixoY: bolinha.attrs.y
        }
        return response;
      })

      const objSave = {
        propostaVersaoId: versao,
        propostaPlantaBaixaId: plantaSelecionada,
        itens: bolinhas
      };

      if(plantaSelecionada > 0)
      {
        await api.put(`propostas/atualizarPlantaBaixaItens`, objSave);
        if(clone) toastContext.notification("Alterações na planta salvas.", "success");
      }
    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  }

  const handleContextMenu = (e: any) => {  
    console.log("handleContextMenu", e);  
    if (editavel) {
      e.evt.preventDefault();
      menu.current.style.display = 'initial';
      menu.current.style.top = e.evt.layerY + 35 + 'px';
      menu.current.style.left = e.evt.layerX + 45 + 'px';

      setBolinhaASerExcluida(e.target.parent._id);
    }
  }

  const handleDelete = () => {
    menu.current.style.display = 'none';

    const currentGroup = layerBolinhas.current.children.find((res: any) => res._id === bolinhaASerExcluida);
    const tipo = currentGroup.attrs.name;

    if (tipo) {
      listEllipse.map(item => 
        item.itens.map((dados, i) =>          
          dados.tipo === tipo ? 
            item.itens.splice(i,1, {tipo: tipo, quantidadeDisponivel: dados.quantidadeDisponivel + 1})
            : 
            { ...dados }
      ));
      currentGroup.destroy();
    }
    handleSave(false); 
  }

  const matrixEllipse = async (list:IResumo[]) => {
 
    var dados = list
    .sort((a, b) => {
        if(a.tipo > b.tipo)  return -1;
        if (a.tipo < b.tipo) return 1;
        return 0;
      })
    .reverse();
    
    var abilityEllipse = imageSize.width / 45 - 2;
    const numberLine = dados.length / abilityEllipse;

    if(resumo.length === 1){
      setHeightImg(30);
      setHeightViewer(imageSize.height + 45)
    } else{
      setHeightImg((numberLine + 1) * 28);
      setHeightViewer((numberLine + 1) * 15 + imageSize.height);
    }
    
    var capacidadeLine = 0;
    var indexItem = 0;
    var countTop = 15;
    while (capacidadeLine < Math.trunc(abilityEllipse)) {
      for (let index = 0; index < abilityEllipse && dados[indexItem] !== undefined; index++) {
        const element = dados[indexItem];
        listTemp.unshift({tipo: element.tipo, quantidadeDisponivel: element.quantidadeDisponivel})
        indexItem++;
      }
      if(listTemp.length !== 0){
        const dados = {
          itens: listTemp
            .map(e => e)
            .sort((a, b) => {
                if(a.tipo > b.tipo)  return -1;
                if (a.tipo < b.tipo) return 1;
                return 0;
              })
            .reverse(),
          heightLine: countTop,          
        };
        listEllipse.unshift(dados);        
        countTop = countTop + 28;
      }
      capacidadeLine++;
      listTemp.length = 0;
    }
    setEscuta(false);  
  }

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/Propostas/${pId}/versoes/${versao}/plantas`);        
        setPlantas(data);        

        if (data.length !== 0) {
          handleSelectPlanta(data[0].id)
        } else {
          setLoading(false);
        }

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })()
  }, [reloadPlantas])

  useEffect(() => {
    if(escuta) matrixEllipse(resumo);  
  },[imageSize.width, imageSize]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/Propostas/${pId}/versoes/${versao}/plantas`);
        
        if (data.length !== 0 && data[0].url !== null) {
          setPlantas(data);
          handleSelectPlanta(data[0].id)
        } else {
          setPlantas([]);
          setImage(null); 
          setResumo([]);
          setTipos([]);
          setLoading(false);
        }

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })()
  }, [reloadPlantas, versao])


  return (
    <>
      <Container>
        <LoadingBlock loading={loading}>
          <div ref={ref} style={plantas.length > 0 ? { display: "block" } : { display: "none" }}>

            <div style={!loading ? { display: "block" } : { display: "none" }}>
              <Row>
                <Col xs={12} style={{ width:"100%", height:heightViewer, display: "flex", overflow: "scroll"}}>
                  <Stage
                    ref={stage}
                    width={imageSize.width}
                    height={imageSize.height ? imageSize.height + 300 : 750 + 55}
                    scaleX={scale}
                    scaleY={scale}
                    x={imageSize.width / 2}
                    y={imageSize.height / 2}
                    onClick={() => menu.current.style.display = 'none'}
                  >
                    <Layer                      
                      x={imageSize.width / 2 - imageSize.width / 2}
                      y={heightImg + 5}
                      offsetX={imageSize.width / 2}
                      offsetY={imageSize.height / 2}
                    >
                      <Image
                        image={image}
                        width={imageSize.width}
                        height={imageSize.height}                      
                      />
                    </Layer>

                    <Layer
                      ref={layerBolinhas}
                      x={imageSize.width / 2}
                      offsetX={(imageSize.width/ 2) + (resumo.length === 1 ? 11 : 380)}
                      offsetY={imageSize.height / 2}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      onContextMenu={e => handleContextMenu(e)}
                      onMouseDown={e => menuLateralContext.setTipoSelecionado(e.target.parent?.attrs.name)}
                      onTouchStart={e => menuLateralContext.setTipoSelecionado(e.target.parent?.attrs.name)}
                    >
                      {tipos.map((tipo) => (

                        <Group
                          ref={refCirculo}
                          key={tipo.id}
                          id={tipo.id}
                          x={tipo.posicaoEixoX}
                          y={tipo.posicaoEixoY}
                          draggable={editavel}
                          name={tipo.tipo}                          
                        >
                          <Ellipse
                            x={14}
                            y={4}
                            width={40}
                            height={20}
                            radius={40}
                            fill='#005283'
                            stroke='#467fcf'
                            strokeWidth={4}
                            shadowColor="black"
                            shadowBlur={0}
                            shadowOpacity={0.5} radiusX={20} radiusY={10}
                            />
                          <Text
                            ref={refTexto}
                            fontSize={10}
                            text={tipo.tipo}
                            fill="#fff"
                            
                          />
                        </Group>
                      ))}
                    </Layer>
                    {listEllipse.map((item, a) => (
                      <Layer
                        key={a}
                        x={imageSize.width / 2}
                        offsetX={imageSize.width * 95 / 100}
                        offsetY={imageSize.height / 2}
                        onMouseDown={e => menuLateralContext.setTipoSelecionado(e.target.parent?.attrs.name)}
                        onTouchStart={e => menuLateralContext.setTipoSelecionado(e.target.parent?.attrs.name)}
                      >
                        {item.itens.map((res, i) => (
                          <>
                            <Group

                              key={i}
                              x={i * 45}
                              y={item.heightLine}
                              onDragStart={(e) => handleClone(e, res)}
                              onDragEnd={() => ("")}
                              draggable={editavel}
                              name={res.tipo}
                              opacity={res.quantidadeDisponivel === 0 ? 0.8 : 1}
                            >
                              <Ellipse
                                ref={refCirculo}
                                x={14}
                                y={4}
                                width={20}
                                height={20}
                                radius={22}
                                fill={res.quantidadeDisponivel === 0 ? "#b4a8a8" : "#005283"}
                                stroke={res.quantidadeDisponivel === 0 ? "#d1d1d1" : "#467fcf"}
                                strokeWidth={4}
                                shadowColor="black"
                                shadowBlur={0}
                                shadowOpacity={res.quantidadeDisponivel === 0 ? 0 : 0.5} radiusX={20} radiusY={10}
                              />
                              <Text
                                ref={refTexto}
                                fontSize={10}
                                text={res.tipo}
                                fill="#fff"
                              />
                            </Group>
                          </>
                        )
                        )}
                      </Layer>

                    ))} 
                  </Stage>
                </Col>
              </Row>
            </div>
            <div className='pt-5'>
              {fabricante && (
                <Row className='row justify-content-between'>
                  <Col sm={4} lg={4}>
                      <Button 
                      className="w-100 my-1"
                      variant="outline-primary" 
                      onClick={() => handleSave(true)} 
                      disabled={!editavel}
                      ><i className="fe fe-save mr-2"></i>
                      Salvar</Button>
                    </Col>
                    <Col sm={4} lg={4}>
                      <Button
                        variant="outline-primary"
                        className="w-100 my-1"
                        onClick={() => setShowModalGerenciarPlantas(true)}
                        disabled={!editavel}
                        ><i className="fe fe-settings mr-2" ></i>
                        Gerenciar Plantas
                      </Button>
                    </Col>
                </Row>
              )}
              <hr/>

              <div className="card-body">
                <Row>
                  <Col>
                    <h6>Plantas disponíveis:</h6>
                  </Col>

                </Row>
                <Row className="selecionar-plantas">
                  {plantas.map((planta) => (
                    <Col xs={6} sm={4} lg={3} className="mt-5" key={planta.id}>
                      <RadioCheckPlantas
                        onChange={() => handleSelectPlanta(planta.id)}
                        name="plantas"
                        title={planta.nome}
                        src={planta.url}
                        defaultChecked={!!planta.checked}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </LoadingBlock>
        <NoData visible={loading === false ? plantas?.length === 0 : false}>
          {(menuLateralContext.statusProposta === 1 || menuLateralContext.statusProposta === 4 || menuLateralContext.statusProposta === 9) ? (
            <>
              <p>Clique no botão abaixo adicionar uma planta.</p>
              <div className="col-12 col-sm-auto mt-5">
                <Button
                  variant="outline-primary"
                  className="px-5"
                  onClick={() => setShowModalGerenciarPlantas(true)}
                  disabled={!ativo}
                >
                  <i className="fe fe-settings mr-2" ></i>Gerenciar Plantas
                </Button>
              </div>
            </>
          ) : (
            <p>Não existe uma planta baixa para exibir.</p>
          )
          }
        </NoData>
      </Container>

      <div id="menu" ref={menu}>
        <div>
          <Button variant="outline-danger" onClick={handleDelete} title="Excluir">
            <i className="fe fe-trash-2"></i>
          </Button>
        </div>
      </div>

      <ModalGerenciarPlantas
        show={showModalGerenciarPlantas}
        close={() => setShowModalGerenciarPlantas(false)}
        reloadPlantas={reloadPlantas}
        setReloadPlantas={setReloadPlantas}
        plantas={plantas}
        setPlantas={setPlantas}
        versao={versao}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  )
}

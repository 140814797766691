import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

interface Props {
  name: string
  label?: string
}

type InputProps = JSX.IntrinsicElements['textarea'] & Props

export default function TextArea({ name, label, children, ...rest }: InputProps) {

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const { fieldName, defaultValue, registerField, error, clearError } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])
  
  return (
    <>
      {label && <label htmlFor={fieldName} className="form-label">{label}</label>}
      <div className="input-icon">
        <textarea
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          onFocus={clearError}
          className="form-control"
          {...rest}
        />
        {children}
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}
// import FloatingLabel from "react-bootstrap/FloatingLabel";
import React, { useEffect, useState } from 'react';
import { Modal, Row } from "react-bootstrap";
import { IListTipologias, IPropostaVersaoAtual } from '../../../../interfaces/proposta.interface';

interface Props {
  additional?:IPropostaVersaoAtual;
  dados:IListTipologias;
  versaoId:number;
}

const ModalDadosAdicionais: React.FC<Props> = ({additional, dados, versaoId}) => {
    const [total, setTotal] = useState<number>();
    const [totalArea, setTotalArea] = useState<number>();
    const [totalQtd, setTotalQtd] = useState<number>();

    useEffect(() => {
        let valueArea = 0;
        let valueQtd = 0;
        dados.versoes.map(versao => {
            if(versao.id === versaoId){
                versao.itens.map(item => {
                    valueArea = valueArea + (item.areaEsquadria * item.quantidade);
                    valueQtd = valueQtd + (item.quantidade);
            })    
            }
        });
        setTotalArea(valueArea);
        setTotalQtd(valueQtd);

        if(additional?.descontoTipo === "%"){
            const value = additional.valor * 100 / (100 - additional.desconto);
            setTotal(value);
        } else if(additional?.descontoTipo === "$"){
            const value = Number(additional.valor || 0) + Number(additional.desconto);
            setTotal(value);
        }else{
            setTotal(additional?.valor);
        }
    },[])

  return (
    <>
        <Modal.Header closeButton>
          <Modal.Title>Informações Adicionais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
                <Row className='px-4 mb-4 p-1' style={{backgroundColor:" #ececec"}}>
                <label><strong>Proposta</strong></label>
                <label>
                    <strong>Versão {additional?.ordem} | {additional?.codigoSistemaTecnico}</strong> 
                </label>
            </Row>    
            <Row className='mx-1' style={{borderBottom:"1px solid #bababa"}}>
                    <div><strong>Valores</strong></div>
                </Row>
                <Row className='mx-3 mt-2'>
                    <label>Valor total</label>
                    <label>
                    {`R$ ${total?.toLocaleString(
                            "pt-br",
                            {
                            minimumFractionDigits: 2,
                            }
                    )}`}
                    </label>
                </Row>
                {(additional?.descontoTipo === "$" || additional?.descontoTipo === "%") && (<>
                    <Row className='mx-3'>
                        <label>Desconto</label>
                        {additional.descontoTipo === "$" ? 
                            <label>
                                {`R$ ${additional.desconto.toLocaleString(
                                    "pt-br",
                                    {minimumFractionDigits: 2,})}
                                `}
                            </label>                
                            : 
                            <label> 
                                {additional.descontoTipo === "%" && (
                                    `${additional?.desconto} %`
                                )}
                            </label>
                        }
                    </Row>
                    <Row className='mx-3'>
                        <label>Valor total (com Desconto)</label>
                        <label>
                            <strong>
                            {`R$ ${additional?.valor.toLocaleString(
                                "pt-br",
                                {
                                    minimumFractionDigits: 2,
                                }
                            )}`}
                            </strong>
                        </label>
                    </Row>
                </>)}
            </>
            {(additional?.condicaoPagto || additional?.formaPagto) && (
                <>
                    <Row className='mx-1 mt-3' style={{borderBottom:"1px solid #bababa"}}>
                        <div><strong>Pagamento</strong></div>
                    </Row>
                    <Row className='mx-3 mt-2'>
                        <label>Condição</label>
                        <label>
                            {additional?.condicaoPagto || "Não informado."}
                            
                        </label>
                    </Row>
                    <Row className='mx-3 mt-2'>
                        <label>Forma</label>
                        <label>
                        { additional?.formaPagto || "Não informado."}
                        </label>
                    </Row>
                </>
            )}
            <>
                <Row className='mx-1 mt-3' style={{borderBottom:"1px solid #bababa"}}>
                    <div><strong>Área(s)</strong></div>
                </Row>
                <Row className='mx-3 mt-2'>
                    <label>Esquadria total</label>
                    <label>
                        {totalArea?.toFixed(2)} m²
                    </label>
                </Row>
            </>
            <>
                <Row className='mx-1 mt-3' style={{borderBottom:"1px solid #bababa"}}>
                    <div><strong>Quantidade de itens</strong></div>
                </Row>
                <Row className='mx-3 mt-2'>
                    <label>Quantidade total</label>
                    <label>
                        {totalQtd?.toFixed(0)} pç
                    </label>
                </Row>
            </>
        </Modal.Body>
     
    </>
  );
};

export default ModalDadosAdicionais;

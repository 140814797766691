import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Private: React.FC = (props) => {
  return (
    <div className="page" style={{minHeight: "100vh"}}>
      <div className="page-main">
        <Header />
        <div>{props.children}</div>
      </div>
      <Footer />
    </div>

  );
}

export default Private;
import React, { useEffect, useRef, InputHTMLAttributes, useState } from "react";
import { useField } from "@unform/core";
import { Col } from "react-bootstrap";

import './styles.css'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: any;
    id?: any;
    title?: string;
    src?: string;
    options: [
        {
            id: string,
            descricao: string,
            selecionado: boolean
            url: string
        }
    ]
}

const RadioCheckOpcaoMontagem: React.FC<Props> = ({ name, id, title, src, defaultChecked, style, options, ...rest }) => {

    const inputRefs = useRef<any>([]);
    const { fieldName, registerField } = useField(name);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue: (refs: any) => {
                const checked = refs.find((ref: HTMLInputElement) => ref.checked);

                return checked ? checked.value : null;
            },
            setValue(refs, value) {
                const item = refs.find((ref: HTMLInputElement) => ref.value === value);

                if (item) {
                    item.checked = true;
                }
            }
        });

    }, [fieldName, registerField]);

    return (

        <>
            {options.map((option, index) => (
                <Col xs={12} md={6} lg={4} key={option.id}>
                    <label className="card mb-4 selecionar-opcao">
                        <input id={option.id} ref={elRef => (inputRefs.current[index] = elRef)} type="radio" name={fieldName} value={option.id} className="imagecheck-input" defaultChecked={option.selecionado} {...rest} />
                        <figure className="imagecheck-figure">
                            <img src={option.url} alt={option.descricao} className={"img-cover" + (loading ? " skeleton-image" : "")} onLoad={() => setLoading(false)} />
                            <div className="p-2 bg-gray-lightest">{option.descricao}</div>
                        </figure>
                    </label>
                </Col>
            ))}
        </>

    );
};

export default RadioCheckOpcaoMontagem;
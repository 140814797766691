import React from "react";

import TypeTableHeader from "./components/TypeTableHeader";
import TypeTableBody from "./components/TypeTableBody";
import { Table } from "react-bootstrap";

const TypeTable = ({ headerGroups, bordered, className, ...rest }) => {
  return (
    <Table striped bordered={bordered} className={className} responsive>
      <TypeTableHeader headerGroups={headerGroups} />
      <TypeTableBody {...rest} />
    </Table>
  );
};

export default TypeTable;

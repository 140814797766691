import { createGlobalStyle } from 'styled-components';

import "bootstrap/dist/css/bootstrap.css";
import "../assets/template/css/dashboard.css";
import "../assets/fontawesome/css/all.min.css";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const GlobalStyle = createGlobalStyle`

body {
    overflow-y: unset;
}

:root {
    scroll-behavior: smooth;
}

input:disabled {
    
  color: #999999;
}

/* Small Inputs */
.form-input-small {
    height: calc(1em + 0.75rem + 2px);
}

.form-input-small.form-select {
    padding: .1375rem 2.25rem .1375rem .75rem;
}

.form-input-small .react-select__value-container{
    padding: 0 8px;
    transform: translateY(-1px);
}

.form-input-small .react-select__control {
    min-height: 29px;
    height: 29px;
}

.form-input-small .react-select__indicators {
    height: 28px;
}

/* Custom Select */

/* .form-select {
    display: block;
    width: 100%;
    padding: .4375rem 2.25rem .4375rem .75rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-color: #fff;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #dadcde;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
} */

.react-select__control {
    border: 1px solid rgba(0, 40, 100, 0.12) !important;
    min-height: 34px;
    border-radius: 3px !important;
}

.react-select__control--is-focused {
    color: inherit !important;
    background-color: #fff !important;
    border-color: #90b5e2 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(32 107 196 / 25%) !important;  
}

.react-select__control--is-disabled {    
    background-color: #f8f9fa !important;
    border-color: rgba(0, 40, 100, 0.12) !important;
}

.react-select__indicator {
    padding: 6px !important;
}

.react-select__placeholder {
    color:#a7abb1 !important;
}

/* Vertical Ruler */
.vr {
    margin-left: 1rem;
    margin-right: 1rem;
    border: 0;
    border-left: 1px solid rgba(0, 40, 100, 0.12);
}

/* Table Column Width */
.column-table-1b {
    width: 5%;
}
@media (min-width: 768px) {
    .column-table-2b {
        width: 19%;
    }
}
@media (min-width: 992px) {
    .column-table-2b {
        width: 14%;
    }
}
@media (min-width: 1280px) {
    .column-table-2b {
        width: 11%;
    }
}

/* Spinner Loading */
.small-box-loader {
    min-height: 200px;
}
.medium-box-loader {
    min-height: 400px;
}

/* Skeleton */
skeleton-load{from{background-position:0 0}to{background-position:100vw 0}}@keyframes skeleton-load{from{background-position:0 0}to{background-position:100vw 0}}.skeleton-avatar,.skeleton-heading:after,.skeleton-image,.skeleton-line:after{background-image:linear-gradient(to right,#f0f2f6 0,#dfe2e7 40%,#f0f2f6 80%);background-size:50vw 100%;background-attachment:fixed;-webkit-animation:skeleton-load 2s linear infinite;animation:skeleton-load 2s linear infinite}.skeleton-avatar{display:inline-block;vertical-align:bottom;width:2.5rem;height:2.5rem;border-radius:100rem}.skeleton-avatar-xs{width:1.25rem;height:1.25rem}.skeleton-avatar-sm{width:2rem;height:2rem}.skeleton-avatar-md{width:3.75rem;height:3.75rem}.skeleton-avatar-lg{width:5rem;height:5rem}.skeleton-avatar-xl{width:7rem;height:7rem}.skeleton-avatar-2xl{width:11rem;height:11rem}.skeleton-heading,.skeleton-line{padding:.375rem 0;display:block;line-height:0;height:1.25rem}.skeleton-heading:after,.skeleton-line:after{content:'';display:inline-block;height:100%;border-radius:4px;width:100%}.skeleton-heading:nth-child(5n+2):after,.skeleton-line:nth-child(5n+2):after{width:80%}.skeleton-heading:nth-child(5n+3):after,.skeleton-line:nth-child(5n+3):after{width:90%}.skeleton-heading:nth-child(5n+4):after,.skeleton-line:nth-child(5n+4):after{width:95%}.skeleton-heading:nth-child(5n+5):after,.skeleton-line:nth-child(5n+5):after{width:85%}.skeleton-line-full:after{width:100%!important}.skeleton-heading{height:1.5rem;margin-bottom:.5rem}.skeleton-heading:after{width:70%}.skeleton-image:after{content:'';padding-top:56.25%;display:block}.card-img-bottom .skeleton-image,.card-img-left .skeleton-image,.card-img-right .skeleton-image,.card-img-top .skeleton-image{border-radius:inherit} 

/* Image */
.img-responsive {
    background: no-repeat center/cover;
    padding-top: 75%;
}

/* Checkbox */
.checkinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.checkinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  border: 1px solid #dee2e6;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.checkinput-color:before {
  content: '';
  opacity: 0;
  position: absolute;
  top: .2rem;
  left: .2rem;
  height: 1.5rem;
  width: 1.5rem;
  transition: .3s opacity;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23467fcf' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}

.checkinput-input:checked ~ .checkinput-color:before {
  opacity: 1;
}

.checkinput-input:focus ~ .checkinput-color {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

/* Input Modificado */
.input-modificado {
    background-color: #dae5f5;
    border-color: #cbdbf2;
    font-weight: bold;
    color: #24426c;
}

.input-modificado .react-select__control {
    background-color: #dae5f5;
    border-color: #cbdbf2;
    font-weight: bold;
    color: #24426c;
}

.ribbon {
    position: absolute;
    top: .75rem;
    right: -.25rem;
    z-index: 1;
    padding: .25rem .75rem;
    font-size: .625rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background: #206bc4;
    border-color: #206bc4;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 2rem;
    min-width: 2rem
}

.ribbon:before {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 0;
    height: 0;
    content: "";
    filter: brightness(70%);
    border: .125rem solid;
    border-color: inherit;
    border-top-color: transparent;
    border-right-color: transparent
}

.ribbon.bg-blue {
    border-color: #206bc4
}

.ribbon.bg-blue-lt {
    border-color: #3478c9!important
}

.ribbon.bg-azure {
    border-color: #4299e1
}

.ribbon.bg-azure-lt {
    border-color: #53a2e4!important
}

.ribbon.bg-indigo {
    border-color: #4263eb
}

.ribbon.bg-indigo-lt {
    border-color: #5371ed!important
}

.ribbon.bg-purple {
    border-color: #ae3ec9
}

.ribbon.bg-purple-lt {
    border-color: #b54fce!important
}

.ribbon.bg-pink {
    border-color: #d6336c
}

.ribbon.bg-pink-lt {
    border-color: #da4579!important
}

.ribbon.bg-red {
    border-color: #d63939
}

.ribbon.bg-red-lt {
    border-color: #da4b4b!important
}

.ribbon.bg-orange {
    border-color: #f76707
}

.ribbon.bg-orange-lt {
    border-color: #f8751d!important
}

.ribbon.bg-yellow {
    border-color: #f59f00
}

.ribbon.bg-yellow-lt {
    border-color: #f6a817!important
}

.ribbon.bg-lime {
    border-color: #74b816
}

.ribbon.bg-lime-lt {
    border-color: #81be2b!important
}

.ribbon.bg-green {
    border-color: #2fb344
}

.ribbon.bg-green-lt {
    border-color: #42ba55!important
}

.ribbon.bg-teal {
    border-color: #0ca678
}

.ribbon.bg-teal-lt {
    border-color: #22ae84!important
}

.ribbon.bg-cyan {
    border-color: #17a2b8
}

.ribbon.bg-cyan-lt {
    border-color: #2caabe!important
}

.ribbon .icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem
}

.ribbon-top {
    top: -.25rem;
    right: .75rem;
    width: 2rem;
    padding: .5rem 0
}

.ribbon-top:before {
    top: 0;
    right: 100%;
    bottom: auto;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent
}

.ribbon-top.ribbon-start {
    right: auto;
    left: .75rem
}

.ribbon-top.ribbon-start:before {
    top: 0;
    right: 100%;
    left: auto
}

.ribbon-start {
    right: auto;
    left: -.25rem
}

.ribbon-start:before {
    top: auto;
    bottom: 100%;
    left: 0;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent
}

.ribbon-bottom {
    top: auto;
    bottom: .75rem
}

.ribbon-bookmark {
    padding-left: .25rem
}

.ribbon-bookmark:after {
    position: absolute;
    top: 0;
    right: 100%;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border: 1rem solid;
    border-color: inherit;
    border-right-width: 0;
    border-left-color: transparent;
    border-left-width: .5rem
}

.ribbon-bookmark.ribbon-left {
    padding-right: .5rem;
    padding-left: .5rem
}

.ribbon-bookmark.ribbon-left:after {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-right-width: .5rem;
    border-left-width: 0
}

.ribbon-bookmark.ribbon-top {
    padding-right: 0;
    padding-bottom: .25rem;
    padding-left: 0
}

.ribbon-bookmark.ribbon-top:after {
    top: 100%;
    right: 0;
    left: 0;
    border-color: inherit;
    border-width: 1rem;
    border-top-width: 0;
    border-bottom-color: transparent;
    border-bottom-width: .5rem
}

`
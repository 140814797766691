import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { DataNotFound } from "../../../../components/DataNotFound";
import CheckboxVideo from "../../../../components/CheckboxVideo";
import PaginationControl from "../../../../components/Pagination";
import api from "../../../../services/api";

import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { MenuLateralContext } from '../../../../contexts/menuLateral/menuLateralContext';
import { VincularVideosContext } from '../../../../contexts/vincularVideos/vincularVideosContext';
import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';

import { IListVideos, IFiltroVideos, ICheckedVideos } from "../../../../interfaces/vincularVideo.interface";

const ModalVincularVideos: React.FC = () => {

    //QueryString
    const queryString = require('querystring');

    //Ref
    const refVincularVideos = useRef<FormHandles>(null)

    //Contexts
    const toastContext = useContext(ToastContext);
    const vincularVideosContext = useContext(VincularVideosContext);
    const menuLateralContext = useContext(MenuLateralContext);


    //States
    const [videos, setVideos] = useState({} as IListVideos);
    const [videosLoading, setVideosLoading] = useState(false);
    const [checkedVideos, setCheckedVideos] = useState<ICheckedVideos[]>([])

    //Effects
    useEffect(() => {
        if (vincularVideosContext.show) {
            (async () => {
                try {
                    setVideosLoading(true);
                    const { data } = await api.get(`/Propostas/ObterListaVideos?${queryString.stringify(vincularVideosContext.filtroVideos)}`)
                    setVideos(data)
                    setVideosLoading(false);

                } catch (err) {
                    const message = getSingleErrorMessage(err);
                    if (message) {
                        toastContext.notification(message, "error");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                }
            })()
        }
    }, [vincularVideosContext.filtroVideos, vincularVideosContext.show])


    //Handles - Vídeos
    const handleCloseVideos = () => {
        setVideos({} as IListVideos);
        setCheckedVideos([]);
        vincularVideosContext.setShow(false);
        vincularVideosContext.setFiltroVideos({} as IFiltroVideos);
    }

    const handleTemporaryCheckedVideos = (e: any) => {
        if (checkedVideos.filter(ev => ev.videoId === e.target.value).length > 0) {
            const index = checkedVideos.findIndex(element => element.videoId == e.target.value);
            let newArr = checkedVideos;
            newArr[index] = { ...newArr[index], selecionada: e.target.checked }
            setCheckedVideos(newArr);
        } else {
            setCheckedVideos(prevArray => [...prevArray, { videoId: e.target.value, selecionada: e.target.checked }])
        }
    }

    const checkTemporaryCheckedVideos = (id: string, selecionadaNoBanco: boolean) => {
        const vid = checkedVideos.filter(ev => ev.videoId === id);
        if (selecionadaNoBanco && vid.length == 0) {
            return selecionadaNoBanco;
        }
        else if (vid.length != 0) {
            return vid[0].selecionada;
        }
    }

    const handleSubmitVincularVideos: SubmitHandler = async () => {
        try {
            const postData = { 
                propostaItemId: vincularVideosContext.filtroVideos.propostaItemId,
                videos: checkedVideos }
                
            await api.put("/Propostas/AtualizarListaVideosItem", postData);
            toastContext.notification("Alterações de vínculo de vídeos realizadas.", "success");
            menuLateralContext.setReload(prevState => !prevState);
            handleCloseVideos();

        } catch (error) {
            const message = getSingleErrorMessage(error);
            if (message) {
                toastContext.notification(message, "warn");
            } else {
                toastContext.notification(
                    "Ocorreu um erro ao realizar essa operação.",
                    "error"
                );
            }
        }
    }

    return (
        <Modal show={vincularVideosContext.show} onHide={handleCloseVideos} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Vincular Vídeos</Modal.Title>
            </Modal.Header>
            <Modal.Body className="vincular-videos">
                <Row>
                    <Col sm md="auto" className="mb-4 mb-md-0">
                        <Link className="btn btn-primary w-100" to="/videos" target="_blank">
                            <i className="fe fe-film"></i><span className="ml-2">Gerenciar Vídeos</span>
                        </Link>
                    </Col>
                    <Col md lg={4} className="ml-auto">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Busca..."
                                onChange={debounce((e) => vincularVideosContext.setFiltroVideos(prevState => ({ ...prevState, termoBusca: e.target.value })), 500)}
                            />
                            <span className="input-icon-addon">
                                <i className="fe fe-search"></i>
                            </span>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className={'dimmer' + (videosLoading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={'dimmer-content' + (videosLoading ? ' medium-box-loader' : '')}>
                        <Form ref={refVincularVideos} onSubmit={handleSubmitVincularVideos}>
                            <Row>
                                {videos.items?.map((video, index) => (
                                    <Col xs={6} lg={4} key={video.videoId}>
                                        <CheckboxVideo
                                            name={"video" + index}
                                            value={video.videoId}
                                            src={video.url}
                                            title={video.descricao}
                                            defaultChecked={checkTemporaryCheckedVideos(video.videoId, video.selecionada)}
                                            onChange={e => handleTemporaryCheckedVideos(e)}
                                        />
                                    </Col>
                                ))}
                                <DataNotFound visible={videosLoading === false ? videos.totalRecords === 0 : false} />
                            </Row>
                        </Form>
                        {videos.totalPages != 0 &&
                            <PaginationControl
                                pageCount={videos.page}
                                setPageCount={(p) => vincularVideosContext.setFiltroVideos(prevState => ({ ...prevState, pagina: p }))}
                                totalPages={videos.totalPages}
                            />
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseVideos}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={() => refVincularVideos.current?.submitForm()}>
                    <i className="fa fa-save mr-2"></i>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalVincularVideos;
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container, Row, Tabs, Tab, Button, Col, Modal } from "react-bootstrap";
import { DataNotFound } from "../../components/DataNotFound";
import { useAuth } from "../../hooks/auth";
import { ShowStatus } from "../../components/ShowStatus";
import api from "../../services/api";

import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../contexts/toast/toastContext";
import { MenuLateralContext } from "../../contexts/menuLateral/menuLateralContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { Tipologia } from "./Tipologia";
import { VisualizadorPlantaBaixa } from "./PlantaBaixa";
import { Visualizador3D } from "./3D";
import { VisualizadorImagem } from "./Imagem";
import { VisualizadorVideo } from "./Video";

import ModalVincularOpcoes from "./Modais/ModalVincularOpcoes";
import ModalVincularImagens from "./Modais/ModalVincularImagens";
import ModalVincularVideos from "./Modais/ModalVincularVideos";
import ModalExibirOpcoesCliente from "./Modais/ModalExibirOpcoesCliente";
import ModalEditarTermo from "./Modais/ModalEditarTermo";
import ModalEnviarProposta from "./Modais/ModalEnviarProposta";
import ModalDadosAdicionais from "./Modais/ModalDadosAdicionais";

import { IAprovarPO, IListTipologias, IPropostaVersaoAtual } from "../../interfaces/proposta.interface";
import { IOptionsString } from "../../interfaces/options.interface";
import { AtivarArea, NivelAcesso, StatusProposta } from "../../Enums/Enums";
import { TipoAcao } from "../../Enums/Enums";
import "./styles.css";
import { useResponsive } from "../../hooks/useResponsive";

const PropostaDetalhes: React.FC = () => {
  
  //Parâmetros
  const { pId }: any = useParams();
  
  //Auth
  const { fabricante, nivelAcesso } = useAuth();

  //History
  const history = useHistory();

  //Context
  const sweetAlert = useContext(SweetAlertContext);
  const { setTipo, setObservacao } = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const menuLateralContext = useContext(MenuLateralContext);
  const { isTablet } = useResponsive();

  //States
  const [areaAtiva, setAreaAtiva] = useState("default");
  const [propostaLoading, setPropostaLoading] = useState(true);
  const [tipologiasLoading, setTipologiasLoading] = useState(false);
  const [ativarBotaoEnviarProposta, setAtivarBotaoEnviarProposta] = useState(true);
  const [proposta, setProposta] = useState({} as IListTipologias);
  const [keyTab, setKeyTab] = useState<string | null>(null);
  const [versaoSelecionada, setVersaoSelecionada] = useState(0);
  const [coresPerfis, setCoresPerfis] = useState<IOptionsString[]>([]);
  const [vidros, setVidros] = useState<IOptionsString[]>([]);
  const [linkProposta, setLinkProposta] = useState("");
  const [showModalEnviarPO, setShowModalEnviarPO] = useState(false);
  const [showModalTermo, setShowModalTermo] = useState(false);
  const [showModalDados, setShowModalDados] = useState(false);
  const [enviadoClienteFinal, setenviadoClienteFinal] = useState(false);
  const [inforPO, setInforPO] = useState<IAprovarPO>({ordem:0, versao:0});
  const [isAtivo, setIsAtivo] = useState(false);
  const [ values, setValues ] = useState<IPropostaVersaoAtual>();


  // Handles
  const handleChangeTab = async (
    k: string | null,
    e: React.ChangeEvent<any>
  ) => {
    try {
      setKeyTab(k);
      const versaoId = e.target.id;
      setVersaoSelecionada(parseInt(versaoId));
      menuLateralContext.setVersao(parseInt(versaoId));
      const arrVersaoAtual = proposta.versoes.filter(
        (e) => e.id === parseInt(versaoId)
      );

      menuLateralContext.setTipoSelecionado(arrVersaoAtual[0].itens[0].tipo);
      menuLateralContext.setReload((prevState) => !prevState);

      setValues(arrVersaoAtual[0] as unknown as IPropostaVersaoAtual);

      if (arrVersaoAtual[0].ativo === true){
        setAtivarBotaoEnviarProposta(true);
      }
      else {
        setAtivarBotaoEnviarProposta(false);
      }

      setTipologiasLoading(true);
      const { data } = await api.get(`/Propostas/${pId}/versoes/${versaoId}`);
      setProposta((prevState) => ({
        ...prevState,
        valorTotal: data.valorTotal,
      }));

    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação. Tab2",
          "error"
        );
      }
    } finally {
      setTipologiasLoading(false);
    }
  };

  const handleRecusarPO = async () => {
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.RecusarPO) {
      try {
        var model = {
          idProposta: pId,
          PropostaVersaoId: inforPO.versao,
          Observacoes: sweetAlert.observacao,
        };
        await api.put(`/Propostas/RecusarProposta/`, model);
        toastContext.notification("Proposta recusada.", "success");
        
        const { data } = await api.get(`/Propostas/${pId}`);

        setProposta((prevState) => ({
          ...prevState,
          propostaStatus: data.propostaStatus,
        }));
        menuLateralContext.setStatusProposta(data.propostaStatus);
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      } finally {
        setTipo("");
        setObservacao("");
      }
    }      
  }

  const handleOpen = () => {
    const titleAlert = `Tem certeza que deseja recusar a proposta?`;
    const message = `O responsavel pela proposta será notificado do cancelamento.`;
    setTipo(TipoAcao.RecusarPO);

    sweetAlert.input(
      titleAlert,
      message,
      handleRecusarPO,
    );
  };

  const handleAprovarNegocio =async () => {
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.FecharPO) {
      try {
        const putObj = {
          propostaId: pId,
          propostaVersaoId: inforPO.versao,
          Observacoes: sweetAlert.observacao,
        };
        await api.put("/Propostas/AprovarPropostaVersaoClienteFinal", putObj);
        toastContext.notification(
          "Parabéns! Você fechou o negócio.",
          "success"
        );
        history.push("/propostas");
      } catch (err) {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      } finally{
        setTipo("");
        setObservacao("");
      }
    }
  };
  
  const handleFecharNegocio = () => {
    const titleAlert = `Tem certeza que deseja fechar o negócio?`;
    const message = "";
    setTipo(TipoAcao.FecharPO);
    sweetAlert.input(
      titleAlert, 
      message, 
      handleAprovarNegocio,
    );
  };
 
  const handleSolicitarAjustes = () => {
    const titleAlert = `Solicitação de ajustes`;
    const message = "Caso necessário, informe abaixo observações para essa proposta";
    setTipo(TipoAcao.AjustarPO);
    sweetAlert.input(
      titleAlert, 
      message, 
      handleSubmitSolicitarAjustes,
    );
  };

  const handleSubmitSolicitarAjustes = async () => {
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.AjustarPO){
      try {
        const putObj = {
          propostaId: pId,
          propostaVersaoId: versaoSelecionada,
          observacoes: sweetAlert.observacao,
        };
        setPropostaLoading(true);
        setTipologiasLoading(true);

        await api.put("/Propostas/AjustarPropostaVersao", putObj);

        toastContext.notification(
          "Ajustes enviados. Em breve você deve receber uma nova versão com esses ajustes.",
          "success"
        );
  
        const { data } = await api.get(`/Propostas/${pId}`);
  
        setProposta((prevState) => ({
          ...prevState,
          propostaStatus: data.propostaStatus,
        }));
        menuLateralContext.setStatusProposta(data.propostaStatus);
      } catch (err) {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      } finally {
        setPropostaLoading(false);
        setTipologiasLoading(false);
        setTipo("");
        setObservacao("");
      }
    }
  };

  const handleShowModalSendPO = async (dados: IListTipologias) => {
    if(dados.propostaStatus === StatusProposta.Enviada || dados.propostaStatus === StatusProposta.Aprovada){
      try {
        const { data } = await api.get(
          `/propostas/ObterLinkAcessoClienteFinal?propostaId=${dados.id}`
        );
        setLinkProposta(data);
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }
    setShowModalEnviarPO(true);
  };

  function editavel(
    status: number,
    enviadaClienteFinal: boolean,
    versaoCorrente: boolean
  ) {
    if (nivelAcesso === NivelAcesso.Cliente) {
      if (status === StatusProposta.Reprovada) return false;
      else return (status === StatusProposta.Enviada || status === StatusProposta.Ajustada) && versaoCorrente;
    } else {
      return (
        (status === StatusProposta.Nova || status === StatusProposta.Ajustar || status === StatusProposta.EmRevisao) &&
        enviadaClienteFinal === false
      );      
    }
  }

  function hideModalTermo(){
    menuLateralContext.setReloadLog((prevState) => !prevState)
    setShowModalTermo(false);
    setIsAtivo(true);
  }

  function hideModalDados(){
    setShowModalDados(false);
  }

  const handleVersaoSelecionada = (versao:any) => {
    setValues(versao);
  }

  //Effect
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/Propostas/${pId}`);
        let versao = data.versoes.find((e: any) => e.versaoCorrente === true);

        setProposta(data);
        if(!isAtivo){
          setValues(versao);
          setKeyTab("versao" + versao.ordem.toString());
        } 
        menuLateralContext.setVersao(versao.id);
        setVersaoSelecionada(versao.id);
        menuLateralContext.setStatusProposta(data.propostaStatus);
        setenviadoClienteFinal(versao.enviadaClienteFinal);        
        menuLateralContext.setIdProposta(pId);
        menuLateralContext.setTipoSelecionado(versao.itens[0].tipo);
        menuLateralContext.setReload((prevState) => !prevState);
        
        if (versao.ativo === true){
          setAtivarBotaoEnviarProposta(true);
        }
        else {
          setAtivarBotaoEnviarProposta(false);
        }

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      } finally {
        setPropostaLoading(false);
      }
    })();
  }, [menuLateralContext.reloadLog, menuLateralContext.statusProposta]);

  useEffect(() => {
    if (nivelAcesso === NivelAcesso.Cliente && proposta.propostaStatus === StatusProposta.Enviada) {
      (async () => {
        try {
          const { data } = await api.get(`/cores/perfis`);

          const cores: IOptionsString[] = data.map(
            (item: any) =>
              ({
                label: item.cor,
                value: item.codigo,
              } as IOptionsString)
          );
  
          setCoresPerfis(cores);
        } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      })();
    }
  }, [proposta.propostaStatus]);

  useEffect(() => {
    if (nivelAcesso === NivelAcesso.Cliente && proposta.propostaStatus === StatusProposta.Enviada) {
      (async () => {
        try {
          const { data } = await api.get(`/vidros`);
  
          const vidro: IOptionsString[] = data.map(
            (item: any, index: any) =>
              ({
                value: item.codigo,
                label: item.descricao,
              } as IOptionsString)
          );
            setVidros(vidro);
          } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      })();
    }
  }, [proposta.propostaStatus]);
  
  useEffect(() => {
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.RecusarPO) handleRecusarPO();
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.FecharPO) handleAprovarNegocio();
    if(sweetAlert.ativo === true && sweetAlert.tipo === TipoAcao.AjustarPO) handleSubmitSolicitarAjustes();
  }, [sweetAlert.ativo]);

  return (
    <>
      <div className="headerDetalhe my-md-5">
        <div className="container">
          <Row className="headerDetalheRow">
            <div className="col">
              <h1 className="page-title">Detalhes da Proposta</h1>
              <div>
                <ol
                  className="breadcrumb breadcrumb-alternate"
                  aria-label="breadcrumbs"
                >
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/propostas">Propostas</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {proposta.obra}
                  </li>
                </ol>
              </div>
            </div>
          </Row>
        </div>
        <Container className="containerDetalhe">
          <Row>
            <div className="card">
              <div className="card-body">
                <Row className="detalheRow row">
                  <Col xl={3} lg={4} md={4} sm={12}>
                    {propostaLoading ? (
                      <div id="title">
                        <h5 className="skeleton-heading col-md-12 col-6"></h5>
                        <label
                          id="cod"
                          className="skeleton-line col-md-12 col-6"
                        ></label>
                      </div>
                    ) : (
                      <Row >
                        <Col xs={12} sm={12}>
                            <h5>
                              {proposta.obra}
                            </h5>
                        </Col>
                        <Col xs={6} sm={12}>
                          <h5>
                            {proposta.nomeCliente}
                          </h5>
                        </Col>
                        <Col xs={6} sm={12}>
                          <label>
                            Cód.{values?.codigoSistemaTecnico}
                          </label>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xl={3} xs={6} sm={"auto"} className="d-flex flex-column">
                    
                      <label>
                        <b className="valor">Valor Total</b>
                      </label>
                      <label
                        className={
                          "m-0" + (propostaLoading ? " skeleton-line" : "")
                        }
                      >
                        {!propostaLoading &&
                          proposta.versoes?.length > 0 &&
                          `R$ ${values?.valor?.toLocaleString(
                            "pt-br",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}`}
                      </label>
                  </Col>
                  <Col xl={3} xs={6}  sm={"auto"} className="d-flex flex-column">
                    {propostaLoading ? (
                      <div className="skeleton-line"></div>
                    ) : (
                      <>
                        <label>
                          <span>Orçamentista</span>
                        </label>
                        <label>
                          <b>{proposta.orcamentista}</b>
                        </label>
                      </>
                    )}                  
                  </Col>
                  <Col xl={3} xs={12}  sm={"auto"}>
                    {propostaLoading ? (
                      <div className="skeleton-line"></div>
                    ) : (
                      <ShowStatus status={(values?.versaoCorrente && values.ativo) ? proposta.propostaStatus : 4} />
                    )}
                  </Col>
              </Row>
              <div className="btnDetalhe" style={{display: fabricante && isTablet ? "flex" : "block"}}> 
                <Row className="rowArea col-lg-6 col-12">
                  {propostaLoading ? (
                      <div className="skeleton-line" ></div>
                    ) : (
                      <Col xl={8} lg={8} md={8} sm={12} xs={12} className="btnDadosAdd">
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className="btnDadosCol">
                          <Button
                            variant="primary"
                            title="Inf. Adicionais"
                            className="btn-outline-secondary col-12"
                            onClick={() => setShowModalDados(true)}
                          >
                            <i className="fas fa-list mr-1"></i>
                            Inf. Adicionais
                          </Button>
                          <br />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className="btnTermo">
                          <Button
                            variant="primary"
                            title="Termo | Garantia"
                            className={"btn-outline-secondary col-12"}
                            onClick={() => setShowModalTermo(true)}
                          >
                            <i className="far fa-file-alt mr-1"></i>Termo | Garantia
                          </Button>
                          <br />
                        </Col>
                      </Col>
                    )}
                    {areaAtiva !== AtivarArea.plantaBaixa && (
                      <Col xl={4} lg={4} md={4} sm={12} xs={12} className="btnPlanta">
                        {propostaLoading ? (
                            <div className="skeleton-line"></div>
                          ) : (
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="btnplantabaixa">
                              <Button
                                onClick={() => setAreaAtiva(AtivarArea.plantaBaixa)}
                                variant="outline-secondary"
                                className="col-12"
                              >
                                <i className="fe fe-codepen mr-1"></i>Ver Planta Baixa
                              </Button>
                            </Col>
                          )}
                      </Col>
                    )}
                    {areaAtiva !== AtivarArea.default && (
                      <Col xl={4} lg={4} md={4} sm={4} xs={12} className="btnPlanta">
                        {propostaLoading ? (
                          <div className="skeleton-line"></div>
                        ) : (
                          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="btnplantabaixa">
                            <Button
                              onClick={() => setAreaAtiva(AtivarArea.default)}
                              variant="primary"
                              className="col-12"
                            >
                              <i className="fe fe-list mr-1"></i>Lista de Esquadrias
                            </Button>
                          </Col>
                        )}
                      </Col>
                    )}
                  </Row>            
                  {proposta.versoes?.map((versao, index: any) => (
                      <div key={index} className="btnSendPO col-lg-6 col-12">
                        {fabricante ? (
                          <>
                            {(!versao.enviadaClienteFinal && index === 0) && (
                              <Row className="btnSendPORow">
                                {propostaLoading ? (
                                  <div className="skeleton-line"></div>
                                ) : (
                                  <Col xl={fabricante ? 5:12} lg={fabricante ? 4:12} md={fabricante ? 12:4} sm xs={12}  className="ajusteCol">
                                    <Button
                                      variant="primary"
                                      className="col-12"
                                      onClick={() =>
                                        handleShowModalSendPO(proposta)}
                                      disabled={!values?.ativo || proposta.propostaStatus === StatusProposta.Enviada}
                                    >
                                      <i className="fe fe-send mr-1"></i>
                                      Enviar Proposta
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {editavel(proposta.propostaStatus, versao.enviadaClienteFinal,versao.versaoCorrente) 
                            || (proposta.propostaStatus !== StatusProposta.Ajustar && proposta.propostaStatus !== StatusProposta.EmRevisao) 
                            && versao.ativo === true && !versao.enviadaClienteFinal && (
                              <Row style={{justifyContent:"end"}}>
                                {propostaLoading ? (
                                  <div className="skeleton-line"></div>
                                ) : (
                                  <Col xl={fabricante ? 5:12} lg={fabricante ? 4:12} md={fabricante ? 12:4} sm xs={12} className="ajusteCol">
                                    <Button
                                      variant="primary btn-outline-primary"
                                      className="col-12"
                                      onClick={() =>
                                        handleShowModalSendPO(proposta)}
                                      disabled={!values?.ativo}
                                    ><i className="fe fe-send mr-1"></i>
                                      Enviar Proposta
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            )}
                          </>
                        ) : (
                          <>
                            {versao.versaoCorrente && (
                              <Row className="btnsCliente">                              
                                <Col xs={12} sm lg={4} md={4} className="colEnviar">
                                  <Button
                                    variant="outline-dark"
                                    className="col-12"
                                    onClick={handleSolicitarAjustes}
                                    disabled={
                                      !ativarBotaoEnviarProposta ||
                                      !editavel(
                                        proposta.propostaStatus,
                                        versao.enviadaClienteFinal,
                                        versao.versaoCorrente                                     
                                      )}
                                  >
                                    <i className="fe fe-send mr-1"></i>
                                    Enviar Ajustes
                                  </Button>
                                </Col>
                                <Col xs={12} sm lg={4} md={4} className="btnRecusar">
                                  <Button
                                    variant="danger"
                                    className="col-12"
                                    onClick={() => {
                                      setInforPO({versao:versao.id, ordem:versao.ordem})
                                      handleOpen();
                                    }}
                                    disabled={
                                      !ativarBotaoEnviarProposta ||
                                      !editavel(
                                        proposta.propostaStatus,
                                        versao.enviadaClienteFinal,
                                        versao.versaoCorrente
                                      )
                                    }
                                  >
                                    <i className="fa fa-regular fa-ban  mr-1"></i>
                                    Recusar Proposta
                                  </Button>
                                </Col>
                                <Col xs={12} sm lg={4} md={4} className="colFechar">
                                  <Button
                                    variant="success"
                                    className="col-12"
                                    onClick={() =>
                                      {
                                        setInforPO({versao:versao.id, ordem:versao.ordem})
                                        handleFecharNegocio()
                                      }
                                    }
                                    disabled={
                                      !ativarBotaoEnviarProposta ||
                                      !editavel(
                                        proposta.propostaStatus,
                                        versao.enviadaClienteFinal,
                                        versao.versaoCorrente
                                      )
                                    }
                                  >
                                    <i className="fe fe-thumbs-up mr-1"></i>
                                    Fechar Negócio
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </div>
                    ))} 
                  
                </div>
              </div>
            </div>
            <div className="card card-tabs-custom">
              <Container>
                <div className={"dimmer" + (propostaLoading ? " active" : "")}>
                  <div className="loader"></div>
                  <div
                    className={
                      "dimmer-content" +
                      (propostaLoading ? " medium-box-loader" : "")
                    }
                  >
                    <Tabs
                      activeKey={keyTab}
                      mountOnEnter
                      onSelect={(k, e) => handleChangeTab(k, e)}
                      className="tab-versao"
                    >
                      {proposta.versoes?.map((versao, index) => (
                        <Tab
                          onEnter={() => handleVersaoSelecionada(versao)}
                          eventKey={`versao${versao.ordem}`}
                          title={
                            <>
                              Versão {versao.ordem} <br />
                              {versao.codigoSistemaTecnico}
                            </>
                          }
                          key={index}
                          id={versao.id}
                          tabClassName={
                            versao.versaoCorrente ? "versaoCorrente" : "versao" 
                          }
                          >
                          <Row>
                            <div className="card-body col">
                              {versao.ativo && (
                                <div
                                  className="ribbon ribbon-end ribbon-top bg-green"
                                  title="Versão Corrente"
                                >
                                  <i className="fas fa-check"></i>
                                </div>
                              )}
                              {areaAtiva === "default" && (
                                <>
                                  <div
                                    className={
                                      "dimmer" +
                                      (tipologiasLoading ? " active" : "")
                                    }
                                  >
                                    <div className="loader"></div>
                                    <div
                                      className={
                                        "dimmer-content" +
                                        (tipologiasLoading
                                          ? " medium-box-loader"
                                          : "")
                                      }
                                    >
                                      {versao.itens?.length !== 0 && (
                                        <div className="valorTotal">
                                          <b className="pr-1">Valor Total:</b>
                                          {`R$ ${versao.valor?.toLocaleString(
                                            "pt-br",
                                            { minimumFractionDigits: 2 }
                                          )}`}
                                        </div>
                                      )}
                                      {versao.itens.map((tipologia, index) => (
                                        <Tipologia
                                          key={index}
                                          tipologia={tipologia}
                                          setAreaAtiva={setAreaAtiva}
                                          versao={versaoSelecionada}
                                          status={proposta.propostaStatus}
                                          editavel={editavel(
                                            proposta.propostaStatus,
                                            versao.enviadaClienteFinal,
                                            versao.versaoCorrente
                                          )}
                                          coresPerfis={coresPerfis}
                                          vidros={vidros}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                  <DataNotFound
                                    visible={
                                      propostaLoading === false
                                        ? versao.itens?.length === 0
                                        : false
                                    }
                                  ></DataNotFound>
                                </>
                              )}
                              {areaAtiva === AtivarArea.plantaBaixa &&
                                versao.id === versaoSelecionada && (
                                  <VisualizadorPlantaBaixa
                                    setAreaAtiva={setAreaAtiva}
                                    vidros={vidros}
                                    coresPerfis={coresPerfis}
                                    versao={versaoSelecionada}
                                    plantaBaixaEditavel={
                                      !versao.enviadaClienteFinal
                                    }
                                    active={versao.ativo}
                                    editavel={editavel(
                                      proposta.propostaStatus,
                                      versao.enviadaClienteFinal,
                                      versao.versaoCorrente
                                    )}
                                  />
                                )}
                              {areaAtiva === AtivarArea.tresD && (
                                <Visualizador3D
                                  setAreaAtiva={setAreaAtiva}
                                  coresPerfis={coresPerfis}
                                  editavel={editavel(
                                    proposta.propostaStatus,
                                    versao.enviadaClienteFinal,
                                    versao.versaoCorrente
                                  )}
                                  vidros={vidros}
                                />
                              )}
                              {areaAtiva === AtivarArea.imagem && (
                                <VisualizadorImagem
                                  setAreaAtiva={setAreaAtiva}
                                  coresPerfis={coresPerfis}
                                  editavel={editavel(
                                    proposta.propostaStatus,
                                    versao.enviadaClienteFinal,
                                    versao.versaoCorrente
                                  )}
                                  vidros={vidros}
                                />
                              )}
                              {areaAtiva === AtivarArea.video && (
                                <VisualizadorVideo
                                  setAreaAtiva={setAreaAtiva}
                                  coresPerfis={coresPerfis}
                                  editavel={editavel(
                                    proposta.propostaStatus,
                                    versao.enviadaClienteFinal,
                                    versao.versaoCorrente
                                  )}
                                  vidros={vidros}
                                />
                              )}
                            </div>
                          </Row>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </Container>
            </div>
          </Row>
        </Container>
      </div>

      {/* Modais */}
      <ModalVincularOpcoes />
      <ModalVincularImagens />
      <ModalVincularVideos />
      <ModalExibirOpcoesCliente />

     {/* Modal Editar Termo de Uso*/}
     <Modal
        show={showModalTermo}
        onHide={hideModalTermo}
        centered
        size="lg"
      >
        <ModalEditarTermo 
          onHideModal={hideModalTermo} 
          botaoSalvarTermo={!enviadoClienteFinal} 
          onEdit={!ativarBotaoEnviarProposta}
          additional={values} 
          dados={proposta}
          idVersao={versaoSelecionada}
        />
      </Modal>

      {/* Modal Enviar por Proposta*/}
      <Modal
        show={showModalEnviarPO}
        onHide={() => setShowModalEnviarPO(false)}
        centered
        size="lg"
      >
        <ModalEnviarProposta value={linkProposta} dados={proposta} onClose={() => setShowModalEnviarPO(false)}/>
      </Modal>
           
      <Modal
        show={showModalDados}
        onHide={hideModalDados}
        centered
        size="lg"
      >
         <ModalDadosAdicionais additional={values} dados={proposta} versaoId={versaoSelecionada}/>
      </Modal>
    </>
  );
};

export default PropostaDetalhes;

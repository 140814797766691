import React from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';

interface IPagination {
    pageCount: number,
    setPageCount: (value: number) => void,
    totalPages: number,
}

const PaginationControl: React.FC<IPagination> = ({pageCount, setPageCount, totalPages}) => {
    return (
        <Row className="d-flex flex-row align-items-center">
            <Col md="auto">
                <Pagination className="pt-2">
                    <Pagination.First
                        onClick={() => setPageCount(1)}
                        disabled={pageCount <= 1}
                    />
                    <Pagination.Prev
                        onClick={() => setPageCount(pageCount - 1)}
                        disabled={pageCount <= 1}
                    />
                    <Pagination.Next
                        onClick={() => setPageCount(pageCount + 1)}
                        disabled={totalPages === 1 || pageCount === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => setPageCount(totalPages)}
                        disabled={totalPages === 1 || pageCount === totalPages}
                    />
                </Pagination>
            </Col>
            <Col md="auto">
                <span>
                    Página{" "}
                    <strong>
                        {pageCount} de {totalPages}
                    </strong>{" "}
                </span>
            </Col>
        </Row>
    );
}

export default PaginationControl;
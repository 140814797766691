import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { NoData } from '../../../../components/DataNotFound';
import Compress from "browser-image-compression";
import Input from '../../../../components/Input';
import InputFile from '../../../../components/InputFile';
import { Link } from 'react-router-dom';
import api from '../../../../services/api';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../../helpers/getValidateErrors"

import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import { useAuth } from '../../../../hooks/auth';

interface IProjetos {
    id: number,
    url: string,
    descricao: string,
}

interface IShowImage {
    showing: boolean,
    src?: string,
}

const Projetos: React.FC = () => {

    //Auth
    const { user } = useAuth();

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Context    
    const sweetAlert = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);

    //States
    const [projetos, setProjetos] = useState<IProjetos[]>([]);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [reload, setReload] = useState(false);
    const [showImage, setShowImage] = useState<IShowImage>({ showing: false });

    const [showModalProjetos, setShowModalProjetos] = useState(false);

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get(`/Fabricantes/Projetos`)
                setProjetos(data);
                setLoading(false);
            } catch (err) {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        })()
    }, [reload])

    //Handles
    const handleShowImage = (url: string) => {
        setShowImage({
            showing: true,
            src: url,
        })
    }

    const handleDelete = ({ id, descricao }: IProjetos) => {
        const titleAlert = `Excluir "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                setLoading(true);
                await api.delete(`/Fabricantes/Projetos/${id}`);
                setProjetos((prevState) =>
                    prevState.filter(function (item) {
                        return item.id !== id;
                    })
                );
                setReload(prevState => !prevState);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        });
    };

    const handleSubmit: SubmitHandler<any> = async (dados) => {
        try {
            formRef.current?.setErrors({});

            const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                titulo: Yup.string().required(message),
                adicionarImagem: Yup.mixed()
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .jpg ou .png",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
            });
            await schema.validate(dados, { abortEarly: false });

            //Passou pela validação
            setLoading(true);

            const upload = new FormData();
            upload.append("Descricao", dados.titulo);

            const file = dados.adicionarImagem;
            // Compression config
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1080,
                useWebWorker: true,
                onProgress: (p: number) => setUploadProgress(p)
            }
            Compress(file, options)
                .then(async (compressedBlob) => {
                    // Convert the blob to file
                    const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                    upload.append("Arquivo", convertedBlobFile);
                    await api.post("/Fabricantes/Projetos", upload);
                    setReload(prevState => !prevState);
                    setShowModalProjetos(false);
                    setUploadProgress(0);
                    toastContext.notification("Novo projeto adicionado.", "success");
                })
                .catch(e => {
                    toastContext.notification("Erro ao comprimir imagem.", "error");
                    setUploadProgress(0);
                    formRef.current?.reset();
                    setLoading(false);
                })


        } catch (err: any) {
            setLoading(false);

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    };

    return (
        <>
            <div className="card personalizacao-projetos">
                <div className="card-header">
                    <h3 className="card-title">Projetos</h3>                    
                </div>
                <div className="card-body">
                    <div className={'dimmer' + (loading ? ' active' : '')}>
                        <div className="loader"></div>
                        <div className={'dimmer-content' + (loading ? ' small-box-loader' : '')}>
                            <Row>
                                {projetos.map((projeto) => (
                                    <div className="col-sm-6 col-lg-4" key={projeto.id}>
                                        <div className="card card-sm">
                                            <Link to="#" onClick={() => handleShowImage(projeto.url)} className="d-block">
                                                <img src={projeto.url} alt={projeto.descricao} className="card-img-top img-cover" />
                                            </Link>
                                            <div className="card-body p-4">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div>
                                                            <h6 className="mb-0">Título da Imagem</h6>
                                                        </div>
                                                        <div>{projeto.descricao}</div>
                                                    </div>
                                                    <div>
                                                        <Button variant="outline-danger" title="Excluir" onClick={() => handleDelete(projeto)}><i className="fe fe-trash"></i></Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <NoData visible={loading === false ? projetos?.length === 0 : false}>
                                    Que tal inserir alguns projetos?
                                </NoData>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right col-xs-12 col-sm-12 col-md-12">
                    <Button className="ml-auto" title="Adicionar Projeto" onClick={() => setShowModalProjetos(true)}>
                        <i className="fe fe-image"></i><span className="ml-2">Adicionar Projeto</span>
                    </Button>
                </div>
            </div>

            {/* Modal Lightbox */}
            <Modal show={showImage.showing} onHide={() => setShowImage({ showing: false })} centered size="lg">
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <img src={showImage.src} alt="Imagem" />
                </Modal.Body>
            </Modal>

            {/* Modal Adicionar Projetos */}
            <Modal show={showModalProjetos} onHide={() => setShowModalProjetos(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Projeto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'dimmer' + (uploadProgress !== 0 ? ' active' : '')}>
                        <div className="mx-5 progresso">
                            <h1 className="font-weight-light text-center">{uploadProgress}<span className="ml-1 h4">%</span></h1>
                            <div className="progress progress-sm">
                                <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }}></div>
                            </div>
                        </div>
                        <div className={'dimmer-content' + (uploadProgress !== 0 ? ' medium-box-loader' : '')}>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <div>
                                    <div className="card-body">
                                        <div className="">
                                            <div className="d-flex align-items-center flex-column">
                                                <div className="mb-4">
                                                    <span
                                                        className="bg-blue text-white text-uppercase btn btn-icon mx-2"
                                                        style={{ width: "42px", height: "40px", lineHeight: "1.7rem", fontSize: "0.7rem", cursor: "initial" }}
                                                    >
                                                        <strong>.jpg</strong>
                                                    </span>
                                                    <span
                                                        className="bg-azure text-white text-uppercase btn btn-icon mx-2"
                                                        style={{ width: "42px", height: "40px", lineHeight: "1.7rem", fontSize: "0.7rem", cursor: "initial" }}
                                                    >
                                                        <strong>.png</strong>
                                                    </span>
                                                </div>
                                                <div className="w-100 form-group">
                                                    <Input
                                                        type="text"
                                                        name="titulo"
                                                        className="form-control"
                                                        placeholder="Insira um título para o projeto..."
                                                        label="Título do Projeto"
                                                    />
                                                </div>
                                                <div className="w-100 form-group">
                                                    <InputFile name="adicionarImagem" label="Imagem Principal do Projeto" />
                                                    <small className='pt-1'>Tamanho máximo para o arquivo <strong>{user?.imagemTamanhoMaximoEnvioEmMB} MB</strong></small>
                                                </div>
                                                <Button variant="primary" className="mt-1" onClick={() => formRef.current?.submitForm()}>
                                                    <i className="fe fe-upload-cloud"></i><span className="ml-2">Upload</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Projetos;
import React from "react";

import { ITipologia } from "../../../../interfaces/proposta.interface";
import { IOptionsString } from "../../../../interfaces/options.interface";
interface IPropsVidros {
  tipologia: ITipologia;
  vidros: IOptionsString[];
}
const Vidros: React.FC<IPropsVidros> = ({ tipologia, vidros }) => {

  return (
    <>
    <b>Vidros:</b>
    <ul>
      {tipologia.vidros?.map((vidro, index) => (
        <>
          {tipologia.vidros && (
            <>
              <li>{tipologia.vidros[index].localizacao.replace(/[\\%\\]|_/g, '') + ": " + tipologia.vidros[index].descricao + " " + tipologia.vidros[index].cor }</li>
            </>
          )}
        </>
      ))
      }
    </ul>
    </>
  );
};

export { Vidros };

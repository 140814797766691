import React from 'react';
import { Line } from 'react-chartjs-2';
import LoadingBlock from '../LoadingBlock';

interface Props {
    values: number[]
    days: string[]
};

const LineChart: React.FC<Props> = ({ values, days }) => {

    const data = {
        labels: days,
        datasets: [
            {
                label: 'Valor dos Orçamentos',
                data: values,
                fill: false,
                backgroundColor: '#122d54',
                // 'rgb(255, 99, 132)',
                borderColor: '#528cde',
                // 'rgba(255, 99, 132, 0.2)',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function(value:any) {
                            return 'R$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        },    
                    },
                },
            ],                     
        },
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any) {
                    return 'R$ ' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }
        },                
    };

    return (
        <LoadingBlock loading={!values}>
            <Line data={data} options={options} />
        </LoadingBlock>
    );

};

export default LineChart
export interface IPaginacao {
    pageIndex: number,
    pageSize: number,
    sort: string,
    desc: boolean,
    globalFilter: string,
    filterOptional1?: string,
    filterOptional2?: string,
  }

export class Paginacao{
    pageIndex: number = 0;
    pageSize: number = 5;
    sort: string = '';
    desc: boolean = true;
    globalFilter: string = '';
    filterOptional1?: string | number;
    filterOptional2?: string | number;
}
import { createContext, useState } from "react";
import { IFiltroImagens, IListItemImagem } from "../../interfaces/vincularImagem.interface";

interface IVincularFornecedoresContext {    
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>, 
    handleModal: () => void,  
    listImagens: IListItemImagem,
    setListImagens: React.Dispatch<React.SetStateAction<IListItemImagem>>,
}

const VincularFornecedoresContext = createContext({} as IVincularFornecedoresContext);

const VincularFornecedoresProvider: React.FC = ({ children }) => {
    
    const [show, setShow] = useState(false);
    const [listImagens, setListImagens] = useState({} as IListItemImagem);

    const handleModal = () => {   
        setShow(true);
        setListImagens(prevState => ({...prevState, limite: 6}))
    }

    return(
        <VincularFornecedoresContext.Provider 
            value={{ show, setShow, handleModal, listImagens, setListImagens }}>
            {children}
        </VincularFornecedoresContext.Provider>
    );
}

export { VincularFornecedoresProvider, VincularFornecedoresContext };




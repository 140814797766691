import React, { useState, useRef, useEffect, useContext } from 'react';
import Compress from "browser-image-compression";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Pagination, Row } from 'react-bootstrap';
import Input from '../../../components/Input';
import InputFile from '../../../components/InputFile';
import { NoData } from '../../../components/DataNotFound';
import api from '../../../services/api'

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors"

import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import './styles.css';
import { useAuth } from '../../../hooks/auth';

interface IShowImage {
    showing: boolean,
    src?: string,
}

interface Images {
    id: string,
    descricao: string,
    recurso: string,
    url: string
}

const Imagens: React.FC = () => {

    //Auth
    const { user } = useAuth();

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Contexts
    const sweetAlert = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);

    //States
    const [showModalImagens, setShowModalImagens] = useState(false);
    const [showImage, setShowImage] = useState<IShowImage>({ showing: false });
    const [imagesLoading, setImagesLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [images, setImages] = useState<Images[]>([]);

    const [reload, setReload] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    //Constants
    const limit = 12;

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setImagesLoading(true)
                const { data } = await api.get(`/galeriaImagens?pagina=${pageCount}&limite=${limit}`)
                setImages(data.items);
                setTotalPages(data.totalPages);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            } finally {
                setImagesLoading(false);
            }
        })()
    }, [reload, pageCount])

    //Handles
    const handleCloseModalImagens = () => setShowModalImagens(false);
    const handleShowModalImagens = () => setShowModalImagens(true);

    const handleShowImage = (url: string) => {
        setShowImage({
            showing: true,
            src: url,
        })
    }

    const handleSubmit: SubmitHandler<any> = async (dados) => {
        try {
            formRef.current?.setErrors({});

            const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                titulo: Yup.string().required(message),
                adicionarImagem: Yup.mixed()
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .jpg ou .png",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
            });
            await schema.validate(dados, { abortEarly: false });

            //Passou pela validação
            setImagesLoading(true);

            const upload = new FormData();
            upload.append("Descricao", dados.titulo);

            const file = dados.adicionarImagem;
            // Compression config
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1080,
                useWebWorker: true,
                onProgress: (p: number) => setUploadProgress(p)
            }
            Compress(file, options)
                .then(async (compressedBlob) => {
                    const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                    upload.append("Arquivo", convertedBlobFile);
                    await api.post("/galeriaImagens", upload);
                    setReload(!reload);
                    handleCloseModalImagens();
                    setUploadProgress(0);
                    toastContext.notification("Nova imagem adicionada.", "success");
                })
                .catch(e => {
                    toastContext.notification("Erro ao comprimir imagem.", "error");
                    setUploadProgress(0);
                    formRef.current?.reset();
                    setImagesLoading(false);
                })


        } catch (err: any) {
            setImagesLoading(false);

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    };

    const handleDelete = ({ id, descricao }: Images) => {
        const titleAlert = `Excluir "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                setImagesLoading(true);
                await api.delete(`galeriaImagens/${id}`);
                setImages((prevState) =>
                    prevState.filter(function (item) {
                        return item.id !== id;
                    })
                );
                setReload(!reload);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
            finally{
                setImagesLoading(false);
            }
        });
    };
    

    return (
        <>
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="page-header">
                        <h1 className="page-title">Imagens</h1>
                    </div>
                </div>
                <div className="container px-5">
                    <div className="row">
                        <div className="card">
                            <div className="card-header p-5">                              
                                <Button className="ml-auto" title="Adicionar Imagem" onClick={handleShowModalImagens}>
                                    <i className="fe fe-image"></i><span className="ml-2">Adicionar Imagem</span>
                                </Button>
                            </div>
                            <div className="card-body">
                                <div className={'dimmer' + (imagesLoading ? ' active' : '')}>
                                    <div className="loader"></div>
                                    <div className={'dimmer-content' + (imagesLoading ? ' medium-box-loader' : '')}>
                                        <Row>
                                            {images.map((image) => (
                                                <div className="col-sm-6 col-lg-4" key={image.id}>
                                                    <div className="card card-sm">
                                                        <Link to="#" onClick={() => handleShowImage(image.url)} className="d-block">
                                                            <img src={image.url} alt={image.descricao} className="card-img-top img-cover" />
                                                        </Link>
                                                        <div className="card-body p-4">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <div>
                                                                        <h6 className="mb-0">Título da Imagem</h6>
                                                                    </div>
                                                                    <div>{image.descricao}</div>
                                                                </div>
                                                                <div>
                                                                    <Button variant="outline-danger" title="Excluir" onClick={() => handleDelete(image)}><i className="fe fe-trash"></i></Button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <NoData visible={imagesLoading === false ? images?.length === 0 : false}>
                                                Que tal inserir algumas imagens?
                                            </NoData>
                                        </Row>
                                        {images.length != 0 &&
                                            <Row className="d-flex flex-row align-items-center">
                                                <Col md="auto">
                                                    <Pagination className="pt-2">
                                                        <Pagination.First
                                                            onClick={() => setPageCount(1)}
                                                            disabled={pageCount <= 1}
                                                        />
                                                        <Pagination.Prev
                                                            onClick={() => setPageCount(pageCount - 1)}
                                                            disabled={pageCount <= 1}
                                                        />
                                                        <Pagination.Next
                                                            onClick={() => setPageCount(pageCount + 1)}
                                                            disabled={totalPages == 1 || pageCount == totalPages}
                                                        />
                                                        <Pagination.Last
                                                            onClick={() => setPageCount(totalPages)}
                                                            disabled={totalPages == 1 || pageCount == totalPages}
                                                        />
                                                    </Pagination>
                                                </Col>
                                                <Col md="auto">
                                                    <span>
                                                        Página{" "}
                                                        <strong>
                                                            {pageCount} de {totalPages}
                                                        </strong>{" "}
                                                    </span>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Lightbox */}
            <Modal show={showImage.showing} onHide={() => setShowImage({ showing: false })} centered size="lg">
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <img src={showImage.src} alt="Imagem" />
                </Modal.Body>
            </Modal>

            {/* Modal Upload Imagens */}
            <Modal show={showModalImagens} onHide={handleCloseModalImagens} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Upload de Imagens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'dimmer' + (uploadProgress != 0 ? ' active' : '')}>
                        <div className="mx-5 progresso">
                            <h1 className="font-weight-light text-center">{uploadProgress}<span className="ml-1 h4">%</span></h1>
                            <div className="progress progress-sm">
                                <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }}></div>
                            </div>
                        </div>
                        <div className={'dimmer-content' + (uploadProgress != 0 ? ' medium-box-loader' : '')}>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <div>
                                    <div className="card-body">
                                        <div className="">
                                            <div className="d-flex align-items-center flex-column">
                                                <div className="mb-4">
                                                    <span
                                                        className="bg-blue text-white text-uppercase btn btn-icon mx-2"
                                                        style={{ width: "42px", height: "40px", lineHeight: "1.7rem", fontSize: "0.7rem", cursor: "initial" }}
                                                    >
                                                        <strong>.jpg</strong>
                                                    </span>
                                                    <span
                                                        className="bg-azure text-white text-uppercase btn btn-icon mx-2"
                                                        style={{ width: "42px", height: "40px", lineHeight: "1.7rem", fontSize: "0.7rem", cursor: "initial" }}
                                                    >
                                                        <strong>.png</strong>
                                                    </span>
                                                </div>
                                                <div className="w-100 form-group">
                                                    <Input
                                                        type="text"
                                                        name="titulo"
                                                        className="form-control"
                                                        placeholder="Insira um título para a imagem..."
                                                        label="Título da Imagem"
                                                    />
                                                   
                                                </div>
                                                <div className="w-100 form-group">
                                                    <InputFile name="adicionarImagem" label="Nome do Arquivo" />
                                                    <small className='pt-1'>Tamanho máximo para o arquivo <strong>{user?.imagemTamanhoMaximoEnvioEmMB} MB</strong></small>
                                                    <br/><small className='pt-1'>O nome do arquivo não pode ter <strong>caracteres especiais ou símbolos</strong></small>
                                                </div>
                                                <Button variant="primary" className="mt-1" onClick={() => formRef.current?.submitForm()}>
                                                    <i className="fe fe-upload-cloud"></i><span className="ml-2">Upload</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Imagens;
import React, { useContext, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Input from '../Input'
import logo from '../../assets/img/proposta-online-logo.svg';

import { Form } from '@unform/web'
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup'
import getValidationErrors from '../../helpers/getValidateErrors';

import { useAuth } from '../../hooks/auth';
import { ToastContext } from '../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";

import { ILogin } from "../../interfaces/login.interface";

const FormLogin: React.FC = () => {

    //Ref
    const formRef = useRef<FormHandles>(null)

    //Contexts
    const { signIn } = useAuth();
    const toastContext = useContext(ToastContext);
    const sweetAlert = useContext(SweetAlertContext);

    //States
    const [loading, setLoading] = useState(false);
    const [verSenha, setVerSenha] = useState(false);  
    
    //Handles
    const handleSubmit: SubmitHandler<ILogin> = async (data) => {
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                Email: Yup.string().email('Digite um e-mail válido.').required('O e-mail é obrigatório.'),
                Senha: Yup.string()
                    .matches(
                        /^(?=.*?[a-z])/,
                        "A senha precisa ter pelo menos uma letra minúscula."
                    )
                    .matches(
                        /^(?=.*?[A-Z])/,
                        "A senha precisa ter pelo menos uma letra maiúscula."
                    )
                    .matches(
                        /^(?=.*?[0-9])/,
                        "A senha precisa ter pelo menos um número."
                    )
                    .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
                    .max(12,"Senha deve ter no máximo 12 caracteres")
                    .required('A senha é obrigatória.'),
            });
            
            await schema.validate(data, {
            abortEarly: false,
            });            
                           
            setLoading(true);
            await signIn(data);
            
        } catch (err: any) {

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            
            else {
                const message = getSingleErrorMessage(err);
                if(message !== "Usuário já está logado no sistema"){
                    if (message) {
                        toastContext.notification("Email ou senha inválidos", "error");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                } else {
                    const title = `Usuário já logado`;
                    const message = `Usuário já logado no sistema, deseja encerrar a sessão anterior e iniciar uma nova?`;
                    sweetAlert.warning(title, message, async () => {
                        data.inativarSessaoAnterior = true;
                        signIn(data);
                    });
                }
            }
            setLoading(false);
        }
    }
     return (
        <Form className="card" ref={formRef} onSubmit={handleSubmit}>
            <div className="card-body p-6">
                <div className="text-center mb-6">
                    <img src={logo} className="h-7" alt="" />
                </div>
                <div className="card-title">Insira seus dados de acesso</div>
                <div className="form-group">
                    <label className="form-label">E-mail</label>
                    <Input name="Email" placeholder="Digite seu e-mail" />
                </div>
                <div className="form-group">
                    <label className="form-label">
                        Senha
                        <Link to="/esqueci-senha" className="float-right small">Esqueceu sua senha?</Link>
                    </label>
                            
                    <Input type={verSenha ? "text" : "password"} name="Senha" placeholder="Digite sua senha">
                        <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                            <i className={verSenha ? "fe fe-eye" : "fe fe-eye-off"}
                                title={verSenha ? "Esconder Senha" : "Ver Senha"}
                                onClick={() => setVerSenha(!verSenha)}
                            ></i>
                        </span>
                    </Input>
                </div>
                <div className="form-footer">
                    <Button type="submit" className="btn-block" disabled={loading}>{loading ? "Entrando..." : "Entrar"}</Button>
                </div>
            </div>
        </Form>
    )
}

export default FormLogin;


import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactDateRangePicker from '../../components/DatePicker';
import BarChart from '../../components/Charts/verticalBar'
import LineChart from '../../components/Charts/line'
import { DonutValor } from '../../components/Charts/donut'
import CardStatus from '../../components/CardStatus';
import api from '../../services/api';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';
import { ToastContext } from '../../contexts/toast/toastContext';

interface IDashboard {
    orcamentosEnviados: number;
    orcamentosFechados: number;
    orcamentosDevolvidos: number;
    orcamentosRecusadas: number;
    orcamentosFechadosEEnviados: IDashboardItemGrafico;
    valorTotalOrcamentosPorDia: IDashboardItemGrafico;
}

interface IDashboardItemGrafico {
    rotulos: string[];
    valores: number[];
}

const Dashboard: React.FC = () => {

    const toastContext = useContext(ToastContext);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dashboard, setDashboard] = useState<IDashboard>({} as IDashboard);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get<IDashboard>('/dashboard');
                setDashboard(data);
                setLoading(false);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    return (

        <div className="my-3 my-md-5">
            <div className="container">

                <div className="page-header">
                    <div className='col m-0 p-0'>
                        <h1 className="page-title">Dashboard</h1>
                        <div className="page-pretitle">
                            Mês Atual
                        </div>
                    </div>
                    <Modal size="lg" show={show} onHide={handleClose}>
                        <Modal.Header>
                            <Modal.Title>Escolha o período</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex">
                                <ReactDateRangePicker />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={handleClose}>
                                Filtrar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
                <div className="row row-cards">
                    <div className="col-4 col-sm-4">
                        <CardStatus value={dashboard.orcamentosEnviados  || 0} title='Orçamentos Enviados' color='orange' ico='send'/>
                    </div>
                    <div className="col-4 col-sm-4">
                        <CardStatus value={dashboard.orcamentosFechados  || 0} title='Orçamentos Fechados' color='#08b57b' ico='lock' />
                    </div>
                    <div className="col-4 col-sm-4">
                        <CardStatus value={dashboard.orcamentosRecusadas || 0} title='Orçamentos Recusados' color='#b8054c' ico='slash' />
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card" style={{borderBottom:'6px solid #0275a2'}}>
                                    <div className="card-header">
                                        <h3 className="card-title">Orçamentos Enviados X Fechados</h3>
                                    </div>
                                    <div className="card-body">
                                        <BarChart 
                                            labels={dashboard.orcamentosFechadosEEnviados?.rotulos} 
                                            values={dashboard.orcamentosFechadosEEnviados?.valores} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card" style={{borderBottom:'6px solid #0275a2'}}>
                                    <div className="card-header">
                                        <h3 className="card-title">Valor Total de Orçamentos</h3>
                                    </div>
                                    <div className="card-body">
                                        <LineChart 
                                            values={dashboard.valorTotalOrcamentosPorDia?.valores}
                                             days={dashboard.valorTotalOrcamentosPorDia?.rotulos} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card"  style={{borderBottom:'6px solid #0275a2'}}>
                                    <div className="card-header">
                                        <h3 className="card-title">Valor de Orçamentos Enviados X Fechados</h3>
                                    </div>
                                    <div className="card-body">
                                        <DonutValor 
                                            labels={dashboard.orcamentosFechadosEEnviados?.rotulos} 
                                            values={dashboard.orcamentosFechadosEEnviados?.valores} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
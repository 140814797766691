import React from 'react';
import FormLogin from '../../components/FormLogin';
import { Link } from 'react-router-dom';

import './styles.css';


const SignIn: React.FC = () => {
  return (   

    <div className="page img-background-login">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">              
              <FormLogin/>
              <div className="text-center text-muted text-white">Ao realizar login você concorda com os <br/> <Link to="/" className="text-white">Termos de Uso e Privacidade.</Link></div>   
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});

api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error?.response?.status) {      
        api.defaults.headers.Authorization = '';
        localStorage.clear();
        window.location.href = "/";
      } else {
        return Promise.reject(error);
      }
    }
  );

export default api;
import React, { useState, useEffect } from 'react'
import './styles.css'

type Props = {
    show: number,
    children: any
}

const Carousel: React.FC<Props> = (props) => {

    const {children, show} = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children?.length)  

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children?.length)
    }, [children]) 

    // Touch
    const [touchPosition, setTouchPosition] = useState(null)
    
    const handleTouchStart = (e:any) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e:any) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 5) {
            next()
        }
    
        if (diff < -5) {
            prev()
        }
    
        setTouchPosition(null)
    }
    
    //Button Function
    const next = () => {
        if (currentIndex < (length - show)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {
                currentIndex > 0 &&
                <button onClick={prev} className="left-arrow">
                    <i className="fe fe-chevron-left"></i>
                </button>
                }
                <div className="carousel-content-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div className={`carousel-content show-${show} length-${length}`} style={{ transform: `translateX(-${currentIndex * (100)}%)` }}>
                        {children}
                    </div>
                </div>
                {
                currentIndex < (length - show) &&   
                <button onClick={next} className="right-arrow">
                    <i className="fe fe-chevron-right"></i>
                </button>
                }
            </div>
        </div>
    );
}

export default Carousel;
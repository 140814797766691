import React, { useState } from "react";
import {
    // DateRange,
    DateRangePicker,
    // OnChangeProps,
    // RangeWithKey,
    createStaticRanges
} from "react-date-range";

// import { pt } from 'date-fns/locale';

var _dateFns = require("date-fns");

var defineds = {
    startOfWeek: (_dateFns.startOfWeek)(new Date()),
    endOfWeek: (_dateFns.endOfWeek)(new Date()),
    startOfLastWeek: (_dateFns.startOfWeek)((_dateFns.addDays)(new Date(), -7)),
    endOfLastWeek: (_dateFns.endOfWeek)((_dateFns.addDays)(new Date(), -7)),
    startOfToday: (_dateFns.startOfDay)(new Date()),
    endOfToday: (_dateFns.endOfDay)(new Date()),
    startOfYesterday: (_dateFns.startOfDay)((_dateFns.addDays)(new Date(), -1)),
    endOfYesterday: (_dateFns.endOfDay)((_dateFns.addDays)(new Date(), -1)),
    startOfMonth: (_dateFns.startOfMonth)(new Date()),
    endOfMonth: (_dateFns.endOfMonth)(new Date()),
    startOfLastMonth: (_dateFns.startOfMonth)((_dateFns.addMonths)(new Date(), -1)),
    endOfLastMonth: (_dateFns.endOfMonth)((_dateFns.addMonths)(new Date(), -1))
};

const staticRanges: any = [
    // ...defaultStaticRanges,
    ...createStaticRanges([
        {
            label: 'Hoje',
            range: function range() {
                return {
                    startDate: defineds.startOfToday,
                    endDate: defineds.endOfToday
                };
            },
            isSelected() {
                return false;
            }
        }, {
            label: 'Ontem',
            range: function range() {
                return {
                    startDate: defineds.startOfYesterday,
                    endDate: defineds.endOfYesterday
                };
            },
            isSelected() {
                return false;
            }
        }, {
            label: 'Esta Semana',
            range: function range() {
                return {
                    startDate: defineds.startOfWeek,
                    endDate: defineds.endOfWeek
                };
            },
            isSelected() {
                return false;
            }
        }, {
            label: 'Última Semana',
            range: function range() {
                return {
                    startDate: defineds.startOfLastWeek,
                    endDate: defineds.endOfLastWeek
                };
            },
            isSelected() {
                return false;
            }
        }, {
            label: 'Este Mês',
            range: function range() {
                return {
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth
                };
            },
            isSelected() {
                return false;
            }
        }, {
            label: 'Último Mês',
            range: function range() {
                return {
                    startDate: defineds.startOfLastMonth,
                    endDate: defineds.endOfLastMonth
                };
            },
            isSelected() {
                return false;
            }
        }
    ])
];

const defaultInputRanges: any = [
    {
        label: 'dias até hoje',
        range(value: any) {
            return {
                startDate: _dateFns.addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: defineds.endOfToday,
            };
        },
        getCurrentValue(range: any) {
            if (!_dateFns.isSameDay(range.endDate, defineds.endOfToday)) return '-';
            if (!range.startDate) return '∞';
            return _dateFns.differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
        },
    },
    {
        label: 'dias depois de hoje',
        range(value: any) {
            const today = new Date();
            return {
                startDate: today,
                endDate: _dateFns.addDays(today, Math.max(Number(value), 1) - 1),
            };
        },
        getCurrentValue(range: any) {
            if (!_dateFns.isSameDay(range.startDate, defineds.startOfToday)) return '-';
            if (!range.endDate) return '∞';
            return _dateFns.differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
        },
    },
];

const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb']

const locale: any = {
    localize: {
        month: (n: any) => months[n],
        day: (n: any) => days[n],
    },
    formatLong: {}
}


// const range: RangeWithKey = {
//     startDate: new Date(),
//     endDate: new Date(),
//     key: 'selection'
// };



// const ReactDatePicker: React.FC = () => {


//     const handleChange = (range: OnChangeProps) => {
//         console.log(range);
//     }


//     return (
//         <div className="m-auto">
//             <DateRange
//                 linkedCalendars={true}
//                 ranges={[range]}
//                 onInit={handleChange}
//                 onChange={handleChange}
//                 theme={{
//                     Calendar: { width: 200 },
//                     PredefinedRanges: { marginLeft: 10, marginTop: 10 }
//                 }}
//                 classNames={{
//                     dateDisplay: 'dateDisplayCustom'
//                 }}
//             // locale={pt}

//             />
//         </div>
//     );

// }



const ReactDateRangePicker: React.FC = () => {


    const [range, setRange] = useState<any>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }                
    ]);

    return (
        <div className="m-auto">
            <DateRangePicker
                linkedCalendars={true}
                ranges={range}
                onInit={range}
                onChange={(item: any) => setRange([item.selection])}
                showSelectionPreview={true}
                editableDateInputs={true}
                showMonthArrow={true}
                months={1}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                weekStartsOn={0}
                dateDisplayFormat="dd/MM/yyyy"
                theme={{
                    Calendar: { width: 200 },
                    PredefinedRanges: { marginLeft: 10, marginTop: 10 }
                }}
                classNames={{
                    dateDisplay: 'dateDisplayCustom'
                }}
                locale={locale}
                staticRanges={staticRanges}
                inputRanges={defaultInputRanges}
            />
        </div>
    );

}

export default ReactDateRangePicker
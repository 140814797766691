import { useRef, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

import janelaPerfil from '../../assets/img/janela-perfil.svg'
import janelaVidro from '../../assets/img/janela-vidro.svg'
import janelaComponente from '../../assets/img/janela-componente.svg'
import environment from '../../assets/img/aircraft_workshop_01_1k.hdr'

import './styles.css'

export const ModelViewer = (esquadriaUrl3D) => {

  //Ref
  const modelRef = useRef(null);

  //State
  const [carregado, setCarregado] = useState(false);
  const [carregando, setCarregando] = useState(0);

  //Effect
  useEffect(() => {
    if (!carregado) {
      setTimeout(() => {
        setCarregando(carregando + 1)
      }, 400);
    } else {
      const perfis = modelRef.current.model.materials.find(element => element.name === 'Perfis');
      perfis.pbrMetallicRoughness.setBaseColorFactor([1, 1, 1, 1]);
      perfis.pbrMetallicRoughness.setMetallicFactor(0.8);
      perfis.pbrMetallicRoughness.setRoughnessFactor(0.6);

      const vidros = modelRef.current.model.materials.find(element => element.name === 'Vidros');
      vidros.pbrMetallicRoughness.setBaseColorFactor([1, 1, 1, 0.1]);
    }
    setCarregado(modelRef.current.loaded);
  }, [carregando])


  //Handles
  const handleChangePerfil = (e) => {
    const colorString = e.target.dataset.color;
    
    if (!colorString) {
      return;
    }
    
    const color = colorString
    .split(",")
    .map((numberString) => parseFloat(numberString));

    const material = modelRef.current.model.materials.find(element => element.name === 'Perfis');

    material.pbrMetallicRoughness.setBaseColorFactor(color);
    material.pbrMetallicRoughness.setMetallicFactor(0.8);
    material.pbrMetallicRoughness.setRoughnessFactor(0.6);
  }

  const handleChangeVidro = (e) => {
    const colorString = e.target.dataset.color;

    if (!colorString) {
      return;
    }

    const color = colorString
      .split(",")
      .map((numberString) => parseFloat(numberString));

    const material = modelRef.current.model.materials.find(element => element.name === 'Vidros');
    material.pbrMetallicRoughness.setBaseColorFactor(color);
  }

  const handleChangeComponente = (e) => {
    const colorString = e.target.dataset.color;

    if (!colorString) {
      return;
    }

    const color = colorString
      .split(",")
      .map((numberString) => parseFloat(numberString));

    const material = modelRef.current.model.materials.find(element => element.name === 'Componentes');
    material.pbrMetallicRoughness.setBaseColorFactor(color);
  }



  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
        </Helmet>
      </HelmetProvider>
      {carregado &&
        <div className="container-colors">
          <div className="colors">
            <Dropdown drop="up" className="d-inline mx-2">
              <Dropdown.Toggle as="div" id="dropdown-autoclose-true" title="Alterar Cor dos Perfis">
                <img src={janelaPerfil} width="70" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item className="cor branco" data-color="1,1,1,1" onClick={(e) => handleChangePerfil(e)}></Dropdown.Item>
                <Dropdown.Item className="cor cinza" data-color="0.255,0.255,0.255,1" onClick={(e) => handleChangePerfil(e)}></Dropdown.Item>
                <Dropdown.Item className="cor preto" data-color="0,0,0,1" onClick={(e) => handleChangePerfil(e)}></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown drop="up" className="d-inline mx-2">
              <Dropdown.Toggle as="div" id="dropdown-autoclose-true" title="Alterar Cor dos Vidros">
                <img src={janelaVidro} width="70" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item className="cor branco" data-color="1,1,1,0.1" onClick={(e) => handleChangeVidro(e)}></Dropdown.Item>
                <Dropdown.Item className="cor cinza" data-color="0.255,0.255,0.255,0.5" onClick={(e) => handleChangeVidro(e)}></Dropdown.Item>
                <Dropdown.Item className="cor verde" data-color="0.465,0.596,0.397,0.3" onClick={(e) => handleChangeVidro(e)}></Dropdown.Item>
                <Dropdown.Item className="cor azul" data-color="0.354,0.389,0.596,0.2" onClick={(e) => handleChangeVidro(e)}></Dropdown.Item>
                <Dropdown.Item className="cor preto" data-color="0,0,0,0.5" onClick={(e) => handleChangeVidro(e)}></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown drop="up" className="d-inline mx-2">
              <Dropdown.Toggle as="div" id="dropdown-autoclose-true" title="Alterar Cor dos Componentes">
                <img src={janelaComponente} width="70" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item className="cor branco" data-color="1,1,1,1" onClick={(e) => handleChangeComponente(e)}></Dropdown.Item>
                <Dropdown.Item className="cor cinza" data-color="0.255,0.255,0.255,1" onClick={(e) => handleChangeComponente(e)}></Dropdown.Item>
                <Dropdown.Item className="cor preto" data-color="0,0,0,1" onClick={(e) => handleChangeComponente(e)}></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      }
      <div className="container p-0">
        <div className="col-12 p-0">
          <model-viewer
            ref={modelRef}
            environment-image={environment}
            src={esquadriaUrl3D.esquadriaUrl3D}
            shadow-intensity="3"
            exposure="0.7"
            shadow-softness="1"
            ar
            camera-controls
            ar-modes="scene-viewer quick-look"
            ar-scale="auto"
            data-js-focus-visible
            style={{ width: 'auto', height: '70vh', outline: 'none' }}
            alt="Janela"
            outline="none"
          >
            <button
              slot="ar-button"
              style={{
                backgroundColor: 'white',
                borderRadius: '4px',
                border: 'none',
                position: 'absolute',
                top: '16px',
                right: '16px'
              }}
            >
              👋 Ativar Realidade Aumentada
            </button>
          </model-viewer>
        </div>
      </div>
    </>
  )
}

export enum NivelAcesso {
    Administrador = "Administrador",
    Fabricante = "Fabricante",
    Supervisor = "Supervisor",
    Vendedor = "Vendedor",
    Cliente = "Cliente",
}

export enum StatusProposta{
Nova = 1, // proposta adicionada via integração
Enviada = 2, // proposta enviada ao cliente final
Aprovada = 3, // proposta aprovada pelo cliente final
Ajustar = 4, // solicitado ajuste na proposta pelo cliente final
Concluida = 5, //proposta marcada como concluida pelo fabricante
Perdida = 6, // proposta marcada como perdida pelo fabricante
Ajustada = 7, //ajuste realizado pelo fabricante
Reprovada = 8, //Proposta reprovada pelo cliente
EmRevisao = 9    
}

export enum TipoAcao {
RecusarPO = "R",
FecharPO = "F",
AjustarPO = "A",
EnviarPO = "E",
}

export enum TipoEnvio{
whatsapp = "whatsapp",
email = "email",
link = "link",
}

export enum AtivarArea{
default = "default",
tresD = "3D",
imagem = "imagem",
video = "video",
plantaBaixa = "plantaBaixa"
}
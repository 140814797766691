export function getSingleErrorMessage(httpResponse) {  
    if (httpResponse.request?.status === 404) {
      if (httpResponse.request.response) {        
        const request = JSON.parse(httpResponse.request.response);
        const notifications = request.fail?.notifications;        
        
        if (notifications) {
          return notifications;
        }
      }
    }
    if (httpResponse.request?.status === 400) {
      if (httpResponse.request.response) {
        const request = JSON.parse(httpResponse.request.response);

        return request.fail.notifications.length > 0 ? request.fail.notifications[0] : request.fail.notifications;
      }
    }
    return null;
  }
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from "../../../components/DataTable";
import Input from '../../../components/Input';
import InputFile from '../../../components/InputFile';
import { NoData } from '../../../components/DataNotFound';
import Compress from "browser-image-compression";
import api from '../../../services/api';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors"

import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import './styles.css';

interface ICategorias {
    id: number,
    descricao: string,
    itens: IOpcoes[]
}

interface IOpcoes {
    id: number,
    descricao: string,
    url: string
}

interface IShowImage {
    showing: boolean,
    src?: string,
}

const Opções: React.FC = () => {

    //Ref
    const formRef = useRef<FormHandles>(null);
    const formRefModal = useRef<FormHandles>(null);

    //Contexts
    const sweetAlert = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);

    //States
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState<ICategorias[]>([]);
    const [reload, setReload] = useState(false);

    const [show, setShow] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [selected, setSelected] = useState<ICategorias | null>(null);
    const [showImage, setShowImage] = useState<IShowImage>({ showing: false });

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const { data } = await api.get(`/opcoesMontagem`)
                setCategorias(data);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            } finally {
                setLoading(false);
            }
        })()
    }, [reload])

    //Handles
    const handleSubmit: SubmitHandler = async (dados) => {
        try {
            formRef.current?.setErrors({});

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                descricao: Yup.string().required(message),
            });
            await schema.validate(dados, { abortEarly: false });

            setLoading(true);
            await api.post("/opcoesMontagem", dados);
            setReload(!reload);
            formRef.current?.reset();


        } catch (err: any) {
            setLoading(false);

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    }

    const handleDelete = ({ id, descricao }: ICategorias) => {
        const titleAlert = `Excluir "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                await api.delete(`/opcoesMontagem/${id}`);
                setCategorias((prevState) =>
                    prevState.filter(function (item) {
                        return item.id !== id;
                    })
                );
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        });
    }

    const handleEdit = (dados: ICategorias) => {
        setShow(true);
        setSelected(dados);
    }

    const handleClose = () => setShow(false);

    const handleShowImage = (url: string) => {
        setShowImage({
            showing: true,
            src: url,
        })
    }

    const handleSubmitModal: SubmitHandler = async (dados) => {
        try {
            formRefModal.current?.setErrors({});

            const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                nome: Yup.string().required(message),
                arquivo: Yup.mixed()
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .jpg ou .png",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
            });
            await schema.validate(dados, { abortEarly: false });

            setLoadingModal(true);
            const upload = new FormData();
            upload.append("opcaoMontagemId", dados.idCategoria);
            upload.append("descricao", dados.nome);

            const file = dados.arquivo;
            // Compression config
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1080,
                useWebWorker: true,
            }
            Compress(file, options)
                .then(async (compressedBlob) => {
                    // Convert the blob to file
                    const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                    upload.append("arquivo", convertedBlobFile);
                    await api.post("/opcoesMontagem/AdicionarItem", upload);
                    const { data } = await api.get(`/opcoesMontagem/${dados.idCategoria}`);
                    setSelected(data);
                    toastContext.notification("Nova opção adicionada.", "success");
                    formRefModal.current?.reset();
                    setReload(!reload);
                    setLoadingModal(false);
                })
                .catch(e => {
                    toastContext.notification("Erro ao comprimir imagem.", "error");
                    formRefModal.current?.reset();
                    setLoadingModal(false);
                })

        } catch (err: any) {
            setLoadingModal(false);

            if (err instanceof Yup.ValidationError) {
                formRefModal.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    }

    const handleDeleteModal = ({ id, descricao }: IOpcoes) => {
        const titleAlert = `Excluir "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                // setLoadingModal(true);
                await api.delete(`opcoesMontagem/${selected?.id}/opcoes/${id}`);
                setSelected((prevState: any) => ({
                    ...prevState,
                    itens: prevState.itens.filter(function (item: IOpcoes) {
                        return item.id !== id;
                    })
                }));
                setReload(!reload);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Categoria de Opção de Montagem",
                accessor: "descricao",
                label: "Categoria de Opção de Montagem",
            },
            {
                Header: "Ação",
                accessor: "acao",
                disableSortBy: true,
                display: "column-table-2b",
                Cell: ({ row: { original } }: { row: { original: ICategorias } }) => (
                    <div className='d-flex'>
                        <Button variant="outline-primary" onClick={() => handleEdit(original)} title="Editar">
                            <i className="fe fe-edit"></i>
                        </Button>
                        <Button variant="outline-danger" className="ml-3" onClick={() => handleDelete(original)} title="Excluir">
                            <i className="fe fe-trash-2"></i>
                        </Button>
                    </div>
                )
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="page-header">
                        <h1 className="page-title">Opções de Montagem</h1>
                    </div>
                </div>
                <div className="container px-5">
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <Row className="mb-4">
                                        <Col>
                                            <h5>Adicionar nova categoria:</h5>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-end">
                                        <Col xs={12} lg className="mb-4 mb-lg-0">
                                            <Input name="descricao" type="text" label="Categoria de Opções de Montagem" placeholder="Digite uma categoria para cadastrar..." />
                                        </Col>
                                        <Col xs="auto" className="mt-2 mx-auto">
                                            <Button onClick={() => formRef.current?.submitForm()}>
                                                <i className="fe fe-plus-circle"></i><span className="ml-2" >Cadastrar</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <div className="card mb-0">
                            <div className="card-body">
                                <Row className="mb-4">
                                    <Col>
                                        <h5>Categorias cadastradas:</h5>
                                    </Col>
                                </Row>
                                <div className={'dimmer' + (loading ? ' active' : '')}>
                                    <div className="loader"></div>
                                    <div className={'dimmer-content' + (loading ? ' small-box-loader' : '')}>
                                        {categorias && (
                                            <DataTable
                                                className="mb-0"
                                                columns={columns}
                                                data={categorias}
                                                bordered={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                <NoData visible={loading === false ? categorias?.length === 0 : false}>Que tal cadastrar uma categoria de opções de montagem?</NoData>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered dialogClassName="modal-opcoes">
                <Modal.Header closeButton>
                    <Modal.Title>Opções da Categoria - {selected?.descricao}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-5">
                    <div className="card">
                        <div className="card-body">
                            <Form ref={formRefModal} onSubmit={handleSubmitModal}>
                                <Row className="mb-4">
                                    <Col>
                                        <h5>Adicionar nova opção:</h5>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col xs={12} lg className="mb-4 mb-lg-0">
                                        <Input name="nome" type="text" label="Nome" placeholder="Digite a descrição dessa opção..." />
                                    </Col>
                                    <Col xs={12} lg className="mb-4 mb-lg-0">
                                        <InputFile name="arquivo" label="Arquivo" />
                                    </Col>
                                    <Input type="hidden" name="idCategoria" value={selected?.id} />
                                    <Col xs="auto" className="mt-2 mx-auto">
                                        <Button onClick={() => formRefModal.current?.submitForm()}>
                                            <i className="fe fe-plus-circle"></i><span className="ml-2" >Cadastrar</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <div className="card mb-0">
                        <div className="card-body">
                            <Row className="mb-4">
                                <Col>
                                    <h5>Opções cadastradas:</h5>
                                </Col>
                            </Row>
                            <div className={'dimmer' + (loadingModal ? ' active' : '')}>
                                <div className="loader"></div>
                                <div className={'dimmer-content' + (loadingModal ? ' small-box-loader' : '')}>
                                    <Row>
                                        {selected?.itens.map((opcao) => (
                                            <div className="col-sm-6 col-lg-4" key={opcao.id}>
                                                <div className="card card-sm">
                                                    <Link to="#" onClick={() => handleShowImage(opcao.url)} className="d-block">
                                                        <img src={opcao.url} alt={opcao.descricao} className="card-img-top opcoes-cover" />
                                                    </Link>
                                                    <div className="card-body p-4">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <div>
                                                                    <h6 className="">Nome da Opção</h6>
                                                                </div>
                                                                <div>{opcao.descricao}</div>
                                                            </div>
                                                            <div>
                                                                <Button variant="outline-danger" title="Excluir" onClick={() => handleDeleteModal(opcao)}><i className="fe fe-trash"></i></Button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                            <NoData visible={loadingModal === false ? selected?.itens.length === 0 : false}>Que tal cadastrar uma opção de {selected?.descricao}?</NoData>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal Lightbox */}
            <Modal show={showImage.showing} onHide={() => setShowImage({ showing: false })} centered size="lg">
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <img src={showImage.src} alt="Imagem" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Opções;
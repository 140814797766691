import React from 'react';
import { Bar } from 'react-chartjs-2';
import LoadingBlock from '../LoadingBlock';

type Props = {
  labels: string[],
  values: number[]  
};
//
const BarChart: React.FC<Props> = ({labels,values}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Orçamentos',
        data: values,
        backgroundColor: [   
          '#08b57b',       
          'orange',
          // 'rgba(70, 127, 207, 0.2)',
          // 'rgba(94, 186, 0, 0.2)'          
        ],
        borderColor: [ 
          '#08b57b',                   
          '#f68945', 
          // 'rgba(70, 127, 207, 1)',
          // 'rgba(70, 127, 207, 1)',
          // 'rgba(94, 186, 0, 1)', 
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false
    },
    tooltips: {
        callbacks: {
          label: function(tooltipItem:any) {
                  return tooltipItem.yLabel;
          }
        }
    }
  };

    return (
      <LoadingBlock loading={!values}>
        <Bar data={data} options={options} />
      </LoadingBlock>
    );
  
};

export default BarChart
import React from "react";

const TypeTableHeader = ({ headerGroups }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              rowSpan="1"
              colSpan="1"
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={column.style}
              className={`th ${column.display}`}
            >
              {column.render("Header")}
              <span className="pl-1 align-middle">
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <i className="fe fe-arrow-down"/>
                  ) : (
                    <i className="fe fe-arrow-up" />
                  )
                ) : (
                  ""
                )}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TypeTableHeader;

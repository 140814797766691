import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { DataNotFound } from "../../../../components/DataNotFound";
import CheckboxImage from "../../../../components/CheckboxImage";
import PaginationControl from "../../../../components/Pagination";
import api from "../../../../services/api";

import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { MenuLateralContext } from '../../../../contexts/menuLateral/menuLateralContext';
import { VincularImagensContext } from '../../../../contexts/vincularImagens/vincularImagensContext';
import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';

import { IListImagens, IFiltroImagens, ICheckedImagens } from "../../../../interfaces/vincularImagem.interface";

const ModalVincularImagens: React.FC = () => {

    //QueryString
    const queryString = require('querystring');

    const history = useHistory()

    //Ref
    const refVincularImagens = useRef<FormHandles>(null)

    //Context
    const toastContext = useContext(ToastContext);
    const vincularImagensContext = useContext(VincularImagensContext);
    const menuLateralContext = useContext(MenuLateralContext);


    //States
    const [imagens, setImagens] = useState({} as IListImagens);
    const [imagensLoading, setImagensLoading] = useState(false);
    const [checkedImages, setCheckedImages] = useState<ICheckedImagens[]>([])

    //Effects
    useEffect(() => {
        if (vincularImagensContext.show) {
            (async () => {
                try {
                    setImagensLoading(true);
                    const { data } = await api.get(`/Propostas/ObterListaImagens?${queryString.stringify(vincularImagensContext.filtroImagens)}`);
                    setImagens(data)
                    setImagensLoading(false);

                } catch (err) {
                    const message = getSingleErrorMessage(err);
                    if (message) {
                        toastContext.notification(message, "error");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                }
            })()
        }
    }, [vincularImagensContext.filtroImagens, vincularImagensContext.show])


    //Handles - Imagens
    const handleCloseImagens = () => {
        setImagens({} as IListImagens);
        setCheckedImages([]);
        vincularImagensContext.setShow(false);
        vincularImagensContext.setFiltroImagens({} as IFiltroImagens)
    };

    const handleTemporaryCheckedImagens = (e: any) => {
        if (checkedImages.filter(ev => ev.imagemId === e.target.value).length > 0) {
            const index = checkedImages.findIndex(element => element.imagemId == e.target.value);
            let newArr = checkedImages;
            newArr[index] = { ...newArr[index], selecionada: e.target.checked }
            setCheckedImages(newArr);
        } else {
            setCheckedImages(prevArray => [...prevArray, { imagemId: e.target.value, selecionada: e.target.checked }])
        }
    }

    const checkTemporaryCheckedImagens = (id: string, selecionadaNoBanco: boolean) => {
        const img = checkedImages.filter(ev => ev.imagemId === id);
        if (selecionadaNoBanco && img.length == 0) {
            return selecionadaNoBanco;
        }
        else if (img.length != 0) {
            return img[0].selecionada;
        }
    }

    const handleSubmitVincularImagens: SubmitHandler = async (data) => {
        try {
            const postData = { imagens: checkedImages, propostaItemId: vincularImagensContext.filtroImagens.propostaItemId }
           await api.put("/Propostas/AtualizarListaImagensItem", postData);
            menuLateralContext.setReload(prevState => !prevState);
            handleCloseImagens();
            history.push(`/propostas/${menuLateralContext.idProposta}`)
            toastContext.notification("Alterações de vínculo de imagens realizadas.", "success");
            
        } catch (error) {
            const message = getSingleErrorMessage(error);
            if (message) {
                toastContext.notification(message, "warn");
            } else {
                toastContext.notification(
                    "Ocorreu um erro ao realizar essa operação.",
                    "error"
                    );
                }
        }
    }

    return (
        <Modal show={vincularImagensContext.show} onHide={handleCloseImagens} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Vincular Imagens</Modal.Title>
            </Modal.Header>
            <Modal.Body className="vincular-imagens">
                <Row>
                    <Col sm md="auto" className="mb-4 mb-md-0">
                        <Link className="btn btn-primary w-100" to="/imagens" target="_blank">
                            <i className="fe fe-image"></i><span className="ml-2">Gerenciar Imagens</span>
                        </Link>
                    </Col>
                    <Col md lg={4} className="ml-auto">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Busca..."
                                onChange={debounce((e) => vincularImagensContext.setFiltroImagens(prevState => ({ ...prevState, termoBusca: e.target.value })), 500)}
                            />
                            <span className="input-icon-addon">
                                <i className="fe fe-search"></i>
                            </span>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className={'dimmer' + (imagensLoading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={'dimmer-content' + (imagensLoading ? ' small-box-loader' : '')}>
                        <Form ref={refVincularImagens} onSubmit={handleSubmitVincularImagens}>
                            <Row>
                                {imagens.items?.map((imagem, index) => (
                                    <Col xs={6} lg={4} key={imagem.imagemId}>
                                        <CheckboxImage
                                            name={"image" + index}
                                            value={imagem.imagemId}
                                            src={imagem.url}
                                            title={imagem.descricao}
                                            defaultChecked={checkTemporaryCheckedImagens(imagem.imagemId, imagem.selecionada)}
                                            onChange={e => handleTemporaryCheckedImagens(e)}
                                        />
                                    </Col>
                                ))}
                                <DataNotFound visible={imagensLoading === false ? imagens.totalRecords === 0 : false} />
                            </Row>
                        </Form>
                        {
                            imagens.totalPages != 0 &&
                                <PaginationControl
                                    pageCount={imagens.page}
                                    setPageCount={(p) => vincularImagensContext.setFiltroImagens(prevState => ({ ...prevState, pagina: p }))}
                                    totalPages={imagens.totalPages}
                                />
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseImagens}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={() => refVincularImagens.current?.submitForm()}>
                    <i className="fa fa-save mr-2"></i>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalVincularImagens;
import { Button, Col, Modal } from "react-bootstrap";
import PhoneInput from "../../../../components/PhoneInput";
import { useContext, useRef, useState } from "react";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import { MenuLateralContext } from "../../../../contexts/menuLateral/menuLateralContext";
import { useParams } from "react-router-dom";
import Input from "../../../../components/Input";

import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { IListTipologias } from "../../../../interfaces/proposta.interface";
import { StatusProposta, TipoEnvio } from "../../../../Enums/Enums";
import api from "../../../../services/api";

interface Propos {
    value: string;
    dados:  IListTipologias;
    onClose: () => void;
}

const ModalEnviarProposta: React.FC<Propos> = ({value, dados, onClose}) => {
  
  const { pId }: any = useParams();
  //Ref
  const formRefEnviar = useRef<FormHandles>(null);

  const [linkCopiado, setLinkCopiado] = useState(false);
  const [linkProposta, setLinkProposta] = useState(value);    
  const [valueSend, setvalueSend] = useState("link");
  const toastContext = useContext(ToastContext);
  const [propostaLoading, setPropostaLoading] = useState(true);
  const [tipologiasLoading, setTipologiasLoading] = useState(false);
  const [proposta, setProposta] = useState<IListTipologias>(dados);
  const [valueEmail, setValueEmail] = useState(dados.clienteEmail);

  const menuLateralContext = useContext(MenuLateralContext);

  const handleCopiarLink = async () => { 
    if ("clipboard" in navigator) {
        setLinkCopiado(true);
        return await navigator.clipboard.writeText(linkProposta);
      } else {
        setLinkCopiado(true);
        return document.execCommand("copy", true, linkProposta);
      }
    };

    const handleSend: SubmitHandler = async (form) => {
       try {
         const schema = Yup.object().shape({
            emailInput : Yup.string().email("Digite um email válido."),
            telefone : Yup.string(),
            mensagem: Yup.string()
          });          
          
          await schema.validate(form,  { abortEarly: false });          
          if(form.telefone === "" && form.emailInput === ""){
            toastContext.notification("Preencha o campo obrigatório.", "error");
          } else{
            handleEnviarProposta(form);            
          }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          formRefEnviar.current?.setErrors(getValidationErrors(err));
        } else {
          const message = getSingleErrorMessage(err);
          if (message) {
            toastContext.notification(message, "error");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
        }}} 
     };
    
   const handleEnviarProposta = async (form:any) => {
     try {  
        const dados = {
          propostaId: parseInt(pId),
          propostaVersaoId: menuLateralContext.versao,
          UsuarioVendedorId: proposta.usuarioVendedor.id,
          observacao: form.mensagem,
          email: form.emailInput,
          telefone: form.telefone,
          tipo: valueSend,
         };
         
         setPropostaLoading(true);
         setTipologiasLoading(true);
         const responser   = await api.put("/Propostas/EnviarPropostaVersaoClienteFinal", dados);
         var { linkAcesso } = responser?.data.data;
         
         const data = await api.get(`/Propostas/${pId}`);
         
          setProposta((prevState) => ({
            ...prevState,
            propostaStatus: data.data.propostaStatus,
          }));
          menuLateralContext.setStatusProposta(data.data.propostaStatus);
          toastContext.notification("Proposta enviada para o cliente.","success");
        if(valueSend === TipoEnvio.whatsapp) handleWhatsApp(form, linkAcesso);
      } catch (err) {
        setPropostaLoading(false);
        setTipologiasLoading(false);
        
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
            );
          }            
        } finally{
          onClose();
          setPropostaLoading(false);  
          setTipologiasLoading(false);          
        }       
   };

   const handleWhatsApp = async (form:any, link: string) => {
    const numeroTelefone = parseInt(form.telefone.match(/\d/g).join(''));
       const whatsappLink ="https://web.whatsapp.com/send?phone=55" + numeroTelefone + "&text=" + form.mensagem + "%0A%0A" + link;
       
      if (window.name === TipoEnvio.whatsapp) {
        window.postMessage(whatsappLink, "whatsapp");
      }else{
        window.open(whatsappLink, "whatsapp");
      }
   }

    return(
    <>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Proposta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRefEnviar} onSubmit={handleSend}>
            <Col>
              <label className="form-label">Selecione o tipo do envio</label>
              <Col>
                <div className="form-check mt-3">
                  <input className="form-check-input" type="radio" name="email" value="email" checked={valueSend === TipoEnvio.email} onChange={e => setvalueSend(e.target.value)}/>
                  <label className="form-check-label" >
                    Email
                  </label>
                    <Input
                      name="emailInput"
                      className="form-control"
                      maxLength={80}
                      placeholder="Digite o e-mail..."  
                      value={valueEmail} 
                      onChange={(e) => setValueEmail(e.target.value)}          
                    />
                    {valueSend === TipoEnvio.email ? <div>Campo obrigatório.</div> : formRefEnviar.current?.clearField("emailInput")}
                </div>
              </Col>
              <Col>
                <div className="form-check mt-3">
                  <input className="form-check-input" type="radio" name="whatsapp" value="whatsapp" checked={valueSend === TipoEnvio.whatsapp} onChange={e => setvalueSend(e.target.value)}/>
                  <label className="form-check-label" >
                    Whatsapp
                  </label>
                    <PhoneInput name="telefone" placeholder="Digite o telefone... (apenas números com DDD)"/>
                    {valueSend === TipoEnvio.whatsapp ? <div>Campo obrigatório.</div> : formRefEnviar.current?.clearField("telefone")}
                </div>
                <label className="form-label mt-3">Mensagem (opcional)</label>
                <Input
                  name="mensagem"
                  type="textarea"                  
                  placeholder="Digite uma mensagem..."
                  className="form-control"
                  maxLength={400}
                  style={{height:'70px'}}
                  disabled={valueSend !== TipoEnvio.whatsapp}
                />
              </Col>           
            </Col>
              {(proposta.propostaStatus === StatusProposta.Enviada || proposta.propostaStatus === StatusProposta.Aprovada) && (
                <Col className="mt-3">
                    <label className="form-label">
                      Link da Proposta
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={value}
                        style={{color:"rgb(69, 129, 207)"}}
                        disabled
                      />
                      <button className="btn" type="button" onClick={handleCopiarLink}>
                        {linkCopiado ? "Copiado!" : "Copiar"}
                      </button>
                    </div>
                </Col>
              )}
          <Modal.Footer className="mt-3">
              <Button
                variant="secondary"
                onClick={onClose}
              >
                Fechar
              </Button>
              <Button
                variant="primary"
                disabled={valueSend === TipoEnvio.link}
                onClick={() => formRefEnviar.current?.submitForm()}
              >
                Enviar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </>

    )
}

export default ModalEnviarProposta;
import React, { useRef, useEffect } from "react";
import ReactSelect, { createFilter } from "react-select";
import makeAnimated from 'react-select/animated';
import { useField } from "@unform/core";

interface OptionType {
  [key: string]: any;
}

interface SelectProps {
  id?: string;
  name: string;
  options: OptionType[];
  isMulti?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  className?: string;
  defaultValue?: any;
  value?: any;
  onChange?: (e: React.ChangeEvent) => void;
  isDisabled?: any;
  getOptionLabel?: any;
}

const Select: React.FC<SelectProps> = ({ name, options, placeholder, className, onChange, defaultValue, isMulti, value, isDisabled, isClearable, getOptionLabel, ...rest }) => {
  const animatedComponents = makeAnimated();
  const { fieldName, registerField, error } = useField(name);
  const selectRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => ref.state.value,     
      setValue: (ref, value: any) => {
        if (value == null)
          ref.select.setValue({ value: null, label: null });
        else
          ref.select.setValue(value.value != null || (Array.isArray(value) && value.length != 0) ? value || null : null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      }
    });
  }, [fieldName, registerField]);


  return (
    <>
      <ReactSelect      
        closeMenuOnSelect={!isMulti}
        ref={selectRef}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        isClearable={isClearable}
        placeholder={placeholder}
        isMulti={isMulti}
        classNamePrefix="react-select"
        className={className}
        components={animatedComponents}
        filterOption={createFilter({ ignoreAccents: false })}
        value={value}
        isDisabled={isDisabled}
        noOptionsMessage={() => "Sem dados"}
        getOptionLabel={getOptionLabel}
        {...rest}
      />

      {error && <div className="invalid-feedback">{error}</div>}

    </>
  );
};

export default Select;

import React from 'react';

import { AuthProvider } from './auth';
import { SweetAlertProvider } from "../contexts/sweetAlert/alertContext";
import { ToastProvider } from '../contexts/toast/toastContext'
import { MenuLateralProvider } from '../contexts/menuLateral/menuLateralContext'
import { VincularOpcoesProvider } from '../contexts/vincularOpcoes/vincularOpcoesContext'
import { VincularImagensProvider } from '../contexts/vincularImagens/vincularImagensContext'
import { VincularVideosProvider } from '../contexts/vincularVideos/vincularVideosContext'
import { ExibirOpcoesClienteProvider } from '../contexts/exibirOpcoesCliente/exibirOpcoesClienteContext'
import PropostaDetalhes from '../pages/PropostaDetalhes';
import { VincularFornecedoresProvider } from '../contexts/vincularFornecedores/vincularFornecedoresContext';

const AppProvider: React.FC = ({ children }) => (
    <AuthProvider>
            <VincularFornecedoresProvider>
                <SweetAlertProvider>
                    <ToastProvider>
                        <VincularOpcoesProvider>
                            <VincularImagensProvider>
                                <VincularVideosProvider>
                                    <ExibirOpcoesClienteProvider>
                                        <MenuLateralProvider>
                                            {children}
                                        </MenuLateralProvider>
                                    </ExibirOpcoesClienteProvider>
                                </VincularVideosProvider>
                            </VincularImagensProvider>
                        </VincularOpcoesProvider>
                    </ToastProvider>
                </SweetAlertProvider>
            </VincularFornecedoresProvider>
    </AuthProvider>
)

export default AppProvider;
import React, { useRef, InputHTMLAttributes, useState } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: any;
    id?: any;
    title?: string;
    src: string;
}

const RadioCheckPlantas: React.FC<Props> = ({ name, id, title, src, defaultChecked, style, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(true);

   

    return (

        <>            
            <label className="card mb-4">
                <input id={id} ref={inputRef} type="radio" name={name} className="imagecheck-input" checked={defaultChecked} {...rest}/>
                <figure className="imagecheck-figure">
                    <img src={src} alt={title} className={"imagecheck-image img-cover" + (loading ? " skeleton-image" : "")} onLoad={() => setLoading(false)} />
                    <div className="p-2">{title}</div>
                </figure>
            </label>
        </>

    );
}

export default RadioCheckPlantas;
import { useAuth } from "../../hooks/auth";

interface IStatus {
    status: number
    tabela?: boolean
}

export const ShowStatus = (props: IStatus) => {
    //Auth
    const { fabricante } = useAuth();

    if (fabricante) {

        switch (props.status) {
            case 1:
                return (
                    <>
                        <span className="status-icon bg-primary" title="Nova Proposta"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Nova Proposta</span>
                    </>
                )
            case 2:
                return (
                    <>
                        <span className="status-icon bg-warning" title="Enviada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline text-center" : ""}`}>Enviada para o cliente</span>
                    </>
                )
            case 3:
                return (
                    <>
                        <span className="status-icon bg-success" title="Aprovada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Aprovada</span>
                    </>
                )
            case 4:
                return (
                    <>
                        <span className="status-icon bg-devolvida" title="Devolvida para ajustes"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Devolvida para ajustes</span>
                    </>
                )
            case 5:
                return (
                    <>
                        <span className="status-icon bg-success" title="Finalizado"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Finalizado</span>
                    </>
                )
            case 6:
                return (
                    <>
                        <span className="status-icon bg-danger" title="Rejeitada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Rejeitada</span>
                    </>
                )
            case 7:
                    return (
                        <>
                            <span className="status-icon bg-revEnviada" title="Revisão enviada ao cliente"></span>
                            <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Revisão enviada ao cliente</span>
                        </>
                    )
            case 8:
                    return (
                        <>
                            <span className="status-icon bg-danger"  title="Proposta Recusada pelo Cliente"></span>
                            <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Proposta Recusada pelo Cliente</span>
                        </>
                    )
            case 9:
                return (
                    <>
                        <span className="status-icon bg-revisao" title="Proposta em revisão pelo Adm"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Em Revisão pelo ADM</span>
                    </>
                )
            default:
                return (
                    <>
                        <span className="status-icon bg-NaoDefinido" title="Não definido"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Não Definido</span>
                    </>
                )
        }

    }
    else {
        switch (props.status) {
            case 1:
                return (
                    <>
                        <span className="status-icon bg-primary" title="Nova Proposta"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Nova Proposta</span>
                    </>
                )
            case 2:
                return (
                    <>
                        <span className="status-icon bg-warning" title="Proposta Recebida"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Proposta Recebida</span>
                    </>
                )
            case 3:
                return (
                    <>
                        <span className="status-icon bg-success" title="Proposta Aprovada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Proposta Aprovada</span>
                    </>
                )
            case 4:
                return (
                    <>
                        <span className="status-icon bg-devolvida" title="Enviada para Ajustes"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Enviada para Ajustes</span>
                    </>
                )
            case 5:
                return (
                    <>
                        <span className="status-icon bg-success" title="Finalizada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Finalizada</span>
                    </>
                )
            case 6:
                return (
                    <>
                        <span className="status-icon bg-danger" title="Rejeitada"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Rejeitada</span>
                    </>
                )
            case 7:
                    return (
                        <>
                            <span className="status-icon bg-revEnviada" title="Revisão recebida"></span>
                            <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Revisão recebida</span>
                        </>
                )     
            case 8:
                    return (
                        <>
                            <span className="status-icon bg-danger" title="Proposta Recusada pelo Cliente"></span>
                            <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Recusada pelo Cliente</span>
                        </>
                    )
            case 9:
            return (
                <>
                    <span className="status-icon bg-revisao" title="Em revisão pelo Adm"></span>
                    <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Aguardando revisão</span>
                </>
            )
            default:
                return (
                    <>
                        <span className="status-icon bg-NaoDefinido" title="Não definido"></span>
                        <span className={`ml-1 ${props.tabela ? "d-md-none d-lg-block d-xl-inline" : ""}`}>Não Definido</span>
                    </>
                )
        }
    }

}

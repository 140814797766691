import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Compress from "browser-image-compression";
import { NoData } from '../../../../components/DataNotFound';
import Input from '../../../../components/Input';
import InputFile from '../../../../components/InputFile';
import DataTable from "../../../../components/DataTable";
import api from '../../../../services/api';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../../helpers/getValidateErrors"

import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";

import './styles.css';

interface IProps {
  show: boolean,
  close: () => void,
  reloadPlantas: boolean,
  setReloadPlantas: React.Dispatch<React.SetStateAction<boolean>>,
  plantas: IListPlantas[],
  setPlantas: React.Dispatch<React.SetStateAction<IListPlantas[]>>,
  versao: any
  loading: boolean,
  setLoading: (value: boolean) => void,
}

interface IListPlantas {
  id: number,
  nome: string,
  url: string,
}

const ModalGerenciarPlantas: React.FC<IProps> = ({ show, close, reloadPlantas, setReloadPlantas, plantas, setPlantas, versao, loading, setLoading }) => {

  //Parâmetros
  const { pId }: any = useParams();

  //Ref
  const formRef = useRef<FormHandles>(null);

  //Contexts
  const sweetAlert = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

      const message = "Esse campo não pode ficar vazio.";
      const schema = Yup.object().shape({
        nome: Yup.string().required(message),
        arquivo: Yup.mixed()
          .test(
            "fileFormat",
            "O arquivo deve ser .jpg ou .png",
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
      });
      await schema.validate(dados, { abortEarly: false });

      setLoading(true);
      const upload = new FormData();
      upload.append("propostaVersaoId", versao);
      upload.append("nome", dados.nome);

      const file = dados.arquivo;
      // Compression config
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      }
      Compress(file, options)
        .then(async (compressedBlob) => {
          // Convert the blob to file
          const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
          upload.append("arquivo", convertedBlobFile);

          await api.post("/propostas/adicionarPlantaBaixa", upload);
          setReloadPlantas((reloadPlantas) => !reloadPlantas);
          toastContext.notification("Nova imagem adicionada.", "success");
          formRef.current?.reset();
        })
        .catch(e => {
          toastContext.notification("Erro ao comprimir imagem.", "error");
          formRef.current?.reset();
          setLoading(false);
        })

    } catch (err: any) {
      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      }
      else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }

  }

  const handleDelete = ({ id, nome }: IListPlantas) => {
    const titleAlert = `Excluir "${nome}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlert.danger(titleAlert, message, async () => {
      try {
        setLoading(true);
        const deleteObj = {
          propostaVersaoId: versao,
          propostaPlantaBaixaId: id
        }
        await api.delete(`propostas/removerPlantaBaixa`, { data: deleteObj });
        setReloadPlantas((reloadPlantas) => !reloadPlantas);
        sweetAlert.close();

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  }

  const columns = useMemo(
    () => [
      {
        accessor: "url",
        display: "column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListPlantas } }) => (
          <img src={original.url} alt={original.nome} className="thumb" />
        ),
      },
      {
        Header: "Nome da Planta",
        accessor: "nome",
        label: "Nome",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "column-table-1b",
        Cell: ({ row: { original } }: { row: { original: IListPlantas } }) => (
          <Button variant="outline-danger" onClick={() => handleDelete(original)} title="Excluir">
            <i className="fe fe-trash-2"></i><span className="d-md-none ml-2">Excluir</span>
          </Button>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Modal show={show} onHide={close} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gerenciar Plantas</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5">
        <div className={'dimmer' + (loading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={'dimmer-content' + (loading ? ' medium-box-loader' : '')}>
            <div className="card mb-1">
              <div className="card-body">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Row className="mb-4">
                    <Col>
                      <h5>Adicionar nova planta:</h5>
                    </Col>
                  </Row>
                  <Row className="align-items-end">
                    <Col xs={12} lg className="mb-4 mb-lg-0">
                      <Input name="nome" type="text" label="Nome" placeholder="Digite algo que descreva a planta..." />
                    </Col>
                    <Col xs={12} lg className="mb-4 mb-lg-0">
                      <InputFile name="arquivo" label="Arquivo" />
                    </Col>
                    <Col xs="auto" className="mt-2 mx-auto">
                      <Button onClick={() => formRef.current?.submitForm()}>
                        <i className="fe fe-upload"></i><span className="ml-2">Upload de Planta</span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            <div>
              <small className='pl-2'>O nome do arquivo não pode ter <strong>caracteres especiais ou símbolos</strong></small>
            </div>
            {plantas.length > 0 && (
              <div className="card mb-0">
                <div className="card-body">
                  <Row className="mb-4">
                    <Col>
                      <h5>Plantas vinculadas:</h5>
                    </Col>
                  </Row>

                  <DataTable
                    className="mb-0"
                    columns={columns}
                    data={plantas}
                    bordered={false}
                    paginate={plantas.length > 5}
                    initialState={{
                      pageSize: 5,
                      sortBy: [
                        {
                          id: "codigo",
                          desc: false,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalGerenciarPlantas;
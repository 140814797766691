import React, { createContext, useState } from 'react';

interface IExibirOpcoesClienteContext {    
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>, 
    handleModal: (value: string) => void,  
    tipologiaId: string,
}

const ExibirOpcoesClienteContext = createContext({} as IExibirOpcoesClienteContext);

const ExibirOpcoesClienteProvider: React.FC = ({ children }) => {    
    
    const [show, setShow] = useState(false)
    const [tipologiaId, setTipologiaId] = useState("")

    const handleModal = (id:string) => {
        setTipologiaId(id)
        setShow(true);
    }
   

    return (
        <ExibirOpcoesClienteContext.Provider
            value={{ show, setShow, handleModal, tipologiaId }}>
            {children}
        </ExibirOpcoesClienteContext.Provider>
    );
}

export { ExibirOpcoesClienteProvider, ExibirOpcoesClienteContext };
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import DataTable from "../../../components/DataTable";
import { NoData } from "../../../components/DataNotFound";
import api from "../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../helpers/getValidateErrors";

import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import LoadingBlock from "../../../components/LoadingBlock";

interface IUsuarios {
  id?: number;
  nome?: string;
  email?: string;
  perfil?: number;
}
interface IFormUsuarios {
  id?: number;
  nome: string;
  email: string;
  perfil: {
    label: string;
    value: number;
  };
}
interface ILicencas {
  available: number;
  licences: number;
  usersCount: number;
  status: { title: string; info: string };
}

const Usuarios: React.FC = () => {

  const options = [
    { label: "Vendedor", value: 2 },
    { label: "Supervisor", value: 3 },
  ];

  const DataTableTS: any = DataTable;

  //Ref
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);
  const sweetAlert = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IUsuarios[]>([]);
  let [numeroLicencas, setNumeroLicencas] = useState<ILicencas[]>([]);
  let [licencasUtilizadas, setLicencasUtilizadas] = useState<ILicencas[]>([]);
  const [licencasRestantes, setLicencasRestantes] = useState<ILicencas[]>([]);
  const [atingiuNumeroLicenca, setAtingiuNumeroLicenca] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IUsuarios | null>(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  //Effect
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get(`/usuarios`);
        setTableData(data.items);
        setTableLoading(false);
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })();
  }, [reload]);

  //Licenças
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(
          `/ExtranetIntegracoes/module-information`
        );
        setNumeroLicencas(data.licences);
        setLicencasUtilizadas(data.usersCount);
        setLicencasRestantes(data.available);
        if (data.available === 0) {
          setAtingiuNumeroLicenca(true);
        } else {
          setAtingiuNumeroLicenca(false);
        }
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })();
  }, [reload]);

  //Filtrar DefaultValue Select
  const defaultSelect = options.find(function (obj, index) {
    if (obj.value == selected?.perfil) return true;
  });

  //Handles
  const handleClose = () => {
    setSelected(null);
    setShow(false);
  };

  const handleAdd = () => {
    setShow(true);
  };

  const handleEdit = (dados: IUsuarios) => {
    setShow(true);
    setSelected(dados);
  };

  const handleDelete = ({ id, nome }: IUsuarios) => {
    const title = `Excluir "${nome}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlert.danger(title, message, async () => {
      try {
        sweetAlert.close();
        await api.delete(`usuarios/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        setReload(reload);
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSubmit: SubmitHandler<IFormUsuarios> = async (dados) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const message = "Esse campo não pode ficar vazio.";
      const schema = Yup.object().shape({
        nome: Yup.string().required(message),
        email: Yup.string().email("Insira um e-mail válido.").required(message),
        perfil: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      await schema.validate(dados, { abortEarly: false });

      if (!selected) {
        const dadosTratados = {
          nome: dados.nome,
          email: dados.email,
          perfil: dados.perfil.value,
        };
        await api.post("/usuarios", dadosTratados);
        toastContext.notification("O usuário foi cadastrado.", "success");
      } else {
        const dadosTratados = {
          id: selected?.id,
          nome: dados.nome,
          email: dados.email,
          perfil: dados.perfil.value,
        };
        await api.put(`/usuarios`, dadosTratados);
        toastContext.notification("O usuário foi editado.", "success");
      }

      setReload(!reload);
      handleClose();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      } else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        label: "Nome",
      },
      {
        Header: "Email",
        accessor: "email",
        label: "Email",
      },
      {
        Header: "Perfil",
        accessor: "perfil",
        label: "Perfil",
        Cell: ({ row: { original } }: { row: { original: IUsuarios } }) => (
          <>
            {original.perfil === 2 && "Vendedor"}
            {original.perfil === 3 && "Supervisor"}
          </>
        ),
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IUsuarios } }) => (
          <div className="d-flex">
            <Button
              variant="outline-primary"
              onClick={() => handleEdit(original)}
              title="Editar"
            >
              <i className="fe fe-edit"></i>
            </Button>
            <Button
              variant="outline-danger"
              className="ml-3"
              onClick={() => handleDelete(original)}
              title="Excluir"
            >
              <i className="fe fe-trash-2"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="page-header">
            <div className="col">
              <h1 className="page-title">Usuários</h1>
            </div>
            <div className="col licencas text-right text-muted">
              <i className="far fa-id-badge icon-4x"></i> Licenças Utilizadas: {licencasUtilizadas}/{numeroLicencas}
            </div>
          </div>
        </div>
        <Container>
          <div className="card">
            <div className="card-header p-5">
              <div className="ml-auto">
                <Button onClick={handleAdd} disabled={atingiuNumeroLicenca}>
                  <i className="fe fe-user-plus mr-2"></i>Novo Usuário
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className={"dimmer" + (tableLoading ? " active" : "")}>
                <div className="loader"></div>
                <div
                  className={
                    "dimmer-content" + (tableLoading ? " small-box-loader" : "")
                  }
                >
                  {tableData && (
                    <DataTableTS
                      columns={columns}
                      data={tableData}
                      bordered={false}
                      initialState={{
                        sortBy: [
                          {
                            id: "codigo",
                            desc: false,
                          },
                        ],
                      }}
                    />
                  )}

                  <NoData
                    visible={
                      tableLoading === false ? tableData?.length === 0 : false
                    }
                  >
                    O que acha de adicionar mais usuários?
                  </NoData>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!selected ? "Criar Novo Usuário" : "Editar Usuário"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingBlock loading={loading}>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="form-group">
                <Input
                  name="nome"
                  label="Nome"
                  defaultValue={selected?.nome}
                  maxLength={60}
                />
              </div>
              <div className="form-group">
                <Input
                  name="email"
                  label="E-mail"
                  defaultValue={selected?.email}
                  maxLength={80}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Perfil de Usuário</label>
                <Select
                  name="perfil"
                  options={options}
                  placeholder=""
                  defaultValue={defaultSelect}
                />
              </div>
            </Form>
          </LoadingBlock>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => formRef.current?.submitForm()}
            disabled={loading}
          >
            <i className="fa fa-save mr-2"></i>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Usuarios;

import React, { useRef, useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Compress from "browser-image-compression";
import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';
import InputFile from '../../../../components/InputFile';
import api from '../../../../services/api';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup';
import getValidationErrors from "../../../../helpers/getValidateErrors";

import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';

import imgPlaceholder from '../../../../assets/img/img-placeholder-ret.jpg';

import './styles.css';

interface IEmpresa {
    imagem: string,
    titulo: string,
    texto: string,
}

interface IFormEmpresa {
    imagem: File,
    titulo: string,
    texto: string,
}

const Empresa: React.FC = () => {

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Context
    const toastContext = useContext(ToastContext);

    //States
    const [empresa, setEmpresa] = useState({} as IEmpresa);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [reload, setReload] = useState(false);

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get(`/Fabricantes/Empresa`)
                setEmpresa(data)
                data.texto && formRef.current?.setFieldValue('texto', data.texto);
                data.titulo && formRef.current?.setFieldValue('titulo', data.titulo);
                setLoading(false);

            } catch (err) {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        })()
    }, [reload])

    //Handles
    const handleSubmit: SubmitHandler<IFormEmpresa> = async (dados) => {
        try {
            formRef.current?.setErrors({});

            const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg', "image/svg+xml"];

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                titulo: Yup.string().required(message),
                texto: Yup.string().required(message),
                imagem: Yup.mixed()
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .jpg, .png ou .svg",
                        value => value ? SUPPORTED_FORMATS.includes(value.type) : true
                    )
            });
            await schema.validate(dados, { abortEarly: false });

            //Passou pela validação

            if (dados.imagem === undefined) {
                const upload = new FormData();
                upload.append("titulo", dados.titulo);
                upload.append("texto", dados.texto);
                await api.post("Fabricantes/Empresa", upload);
                formRef.current?.reset();
                toastContext.notification("Dados de empresa atualizados.", "success");
                setReload(prevState => !prevState);
            }
            else {
                const upload = new FormData();
                upload.append("titulo", dados.titulo);
                upload.append("texto", dados.texto);

                const file = dados.imagem;
                // Compression config
                const options = {
                    maxSizeMB: 0.2,
                    maxWidthOrHeight: 1080,
                    useWebWorker: true,
                    onProgress: (p: number) => setUploadProgress(p)
                }
                Compress(file, options)
                    .then(async (compressedBlob) => {
                        // Convert the blob to file
                        const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                        upload.append("arquivo", convertedBlobFile);
                        await api.post("Fabricantes/Empresa", upload);
                        formRef.current?.reset();
                        setUploadProgress(0);
                        toastContext.notification("Dados de empresa atualizados.", "success");
                        setReload(prevState => !prevState);
                    })
                    .catch(e => {
                        toastContext.notification("Erro ao comprimir imagem.", "error");
                        setUploadProgress(0);
                        formRef.current?.reset();
                    })

            }
        } catch (err: any) {

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    };


    return (
        <>
            <div className="card personalizacao-empresa">
                <div className="card-header">
                    <h3 className="card-title">Empresa</h3>
                </div>
                <div className="card-body">
                    <div className={'dimmer' + (loading ? ' active' : '')}>
                        <div className="loader"></div>
                        <div className={'dimmer-content' + (loading ? ' small-box-loader' : '')}>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <div className={'dimmer' + (uploadProgress !== 0 ? ' active' : '')}>
                                    <div className="mx-5 progresso">
                                        <h1 className="font-weight-light text-center">{uploadProgress}<span className="ml-1 h4">%</span></h1>
                                        <div className="progress progress-sm">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }}></div>
                                        </div>
                                    </div>
                                    <div className={'dimmer-content' + (uploadProgress !== 0 ? ' small-box-loader' : '')}>
                                        <Row>
                                            <Col md={6} className="mx-auto">
                                                <Row>
                                                    <Col>
                                                        <div className="form-label">Imagem atual</div>
                                                        <div className="card bg-responsive" style={{ background: `url(${empresa.imagem ? empresa.imagem : imgPlaceholder}) no-repeat` }}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <div className="vr" />
                                            <Col md className="mx-auto">
                                                <div className="form-group">
                                                    <InputFile name="imagem" label="Nova Imagem (Dimensão recomendada 700 x 400)" />
                                                </div>
                                                <div className="form-group">
                                                    <Input name="titulo" type="text" label="Título" placeholder="Digite um título..." maxLength={120}/>
                                                </div>
                                                <div className="form-group">
                                                    <TextArea name="texto" label="Descrição da Empresa" placeholder="Digite um texto que descreva a sua empresa..."  maxLength={800}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right col-xs-12 col-sm-12 col-md-12">
                    <Button variant="primary" onClick={() => formRef.current?.submitForm()}>
                    <i className="fa fa-save mr-2"></i>Salvar Empresa
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Empresa;
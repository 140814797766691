import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { ILogin } from "../interfaces/login.interface";

interface User {
  nome: string,
  tipo: string,
  perfil: string,
  token: string,
  imagemTamanhoMaximoEnvioEmMB: number,
  videoTamanhoMaximoEnvioEmMB: number,
  fabricanteId: number,
}

interface AuthContextData {
  signed: boolean;
  user: User | null;
  fabricante: boolean;
  nivelAcesso: string;
  logo: string;
  signIn(login: ILogin): Promise<void>;
  signOut(): void;
  SignInCliente(codigo: string): Promise<void>;
  alterLogoFabricante(url: string): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {

  function initialData() {
    const storagedUser = localStorage.getItem('@App:user');
    const storagedToken = localStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      return JSON.parse(storagedUser);
    }
  }

  function initialFabricante() {
    const storagedUser = localStorage.getItem('@App:user');
    if (storagedUser) {
      const usuario = JSON.parse(storagedUser);
      if (usuario.tipo === "Fabricante") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function initialNivelAcesso() {
    const storagedUser = localStorage.getItem('@App:user');
    if (storagedUser) {
      const usuario = JSON.parse(storagedUser);
      return usuario.perfil
    } else {
      return false;
    }
  }

  function initialLogo() {
    const logo = localStorage.getItem('@App:urlLogo');
    if (logo) {
      return logo
    } else
      return "";
  }

  const [user, setUser] = useState<User | null>(initialData);
  const [fabricante, setFabricante] = useState(initialFabricante);
  const [nivelAcesso, setNivelAcesso] = useState(initialNivelAcesso);
  const [logo, setLogo] = useState(initialLogo);

  async function signIn(login: ILogin) {
    const { data } = await api.post('/Autenticacao/Fabricante', login);
    api.defaults.headers.Authorization = `Bearer ${data.token}`

    setLogo(data.urlLogo);
    setFabricante(data.tipo === "Fabricante");
    setNivelAcesso(data.perfil);
    setUser(data);
    localStorage.setItem('@App:user', JSON.stringify(data));
    localStorage.setItem('@App:token', data.token);
    localStorage.setItem('@App:urlLogo', data.urlLogo);
  }

  function alterLogoFabricante(url: string) {
    localStorage.setItem('@App:urlLogo', url);
    setLogo(url);
  }

  function signOut() {
    setUser(null);
    setFabricante(false);
    setNivelAcesso("");
    setLogo("");

    localStorage.removeItem('@App:user');
    localStorage.removeItem('@App:token');
    localStorage.removeItem('@App:urlLogo');
  }

  async function SignInCliente(codigo: string) {
    const login = {
      codigoAcesso: codigo
    }
    
    const { data } = await api.post('/Autenticacao/Cliente', login);
    api.defaults.headers.Authorization = `Bearer ${data.token}`
    
    setLogo(data.urlLogo);
    setNivelAcesso(data.perfil);
    setFabricante(data.tipo === "Fabricante");
    setUser(data);

    localStorage.setItem('@App:user', JSON.stringify(data));
    localStorage.setItem('@App:token', data.token);
    localStorage.setItem('@App:urlLogo', data.urlLogo);


  }
  
  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, fabricante, nivelAcesso, logo, signIn, signOut, SignInCliente, alterLogoFabricante }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("hook must be used with an Provider")
  }

  return context;
}

export { AuthProvider, useAuth };
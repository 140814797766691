import React, { createContext, useState } from 'react';

interface IVincularOpcoesContext {    
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>, 
    handleModal: (value: string) => void,  
    tipologiaId: string,
}

const VincularOpcoesContext = createContext({} as IVincularOpcoesContext);

const VincularOpcoesProvider: React.FC = ({ children }) => {
    
    
    const [show, setShow] = useState(false)
    const [tipologiaId, setTipologiaId] = useState("")

    const handleModal = (id:string) => {
        setTipologiaId(id)
        setShow(true);
    }
   

    return (
        <VincularOpcoesContext.Provider
            value={{ show, setShow, handleModal, tipologiaId }}>
            {children}
        </VincularOpcoesContext.Provider>
    );
}

export { VincularOpcoesProvider, VincularOpcoesContext };
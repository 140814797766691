import React, { createContext, useState } from 'react';
import { IFiltroImagens } from "../../interfaces/vincularImagem.interface";


interface IVincularImagensContext {    
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>, 
    handleModal: (value: string) => void,  
    filtroImagens: IFiltroImagens,
    setFiltroImagens: React.Dispatch<React.SetStateAction<IFiltroImagens>>,
}

const VincularImagensContext = createContext({} as IVincularImagensContext);

const VincularImagensProvider: React.FC = ({ children }) => {
    
    
    const [show, setShow] = useState(false)
    const [filtroImagens, setFiltroImagens] = useState({} as IFiltroImagens);


    const handleModal = (id:string) => {       
        setFiltroImagens(prevState => ({...prevState, propostaItemId: id, limite: 6}))
        setShow(true);
    }
    
    return (
        <VincularImagensContext.Provider
            value={{ show, setShow, handleModal, filtroImagens, setFiltroImagens }}>
            {children}
        </VincularImagensContext.Provider>
    );
}

export { VincularImagensProvider, VincularImagensContext };
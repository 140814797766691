import React, { createContext, useState } from 'react';
import { IFiltroVideos } from "../../interfaces/vincularVideo.interface";


interface IVincularVideosContext {    
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>, 
    handleModal: (value: string) => void,  
    filtroVideos: IFiltroVideos,
    setFiltroVideos: React.Dispatch<React.SetStateAction<IFiltroVideos>>,
}

const VincularVideosContext = createContext({} as IVincularVideosContext);

const VincularVideosProvider: React.FC = ({ children }) => {
    
    
    const [show, setShow] = useState(false)
    const [filtroVideos, setFiltroVideos] = useState({} as IFiltroVideos);


    const handleModal = (id:string) => {       
        setFiltroVideos(prevState => ({...prevState, propostaItemId: id, limite: 6}))
        setShow(true);
    }
   

    return (
        <VincularVideosContext.Provider
            value={{ show, setShow, handleModal, filtroVideos, setFiltroVideos }}>
            {children}
        </VincularVideosContext.Provider>
    );
}

export { VincularVideosProvider, VincularVideosContext };
import { Button, Card, Col, Row } from "react-bootstrap";
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from "@unform/core";
import Input from "../../../../components/Input";

import { ITipologia } from "../../../../interfaces/proposta.interface";
import { IOptionsString } from "../../../../interfaces/options.interface";

import { SelectVidros } from "../SelectVidros";
import Select from "../../../../components/Select";
import { useAuth } from "../../../../hooks/auth";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { Vidros } from "../Vidros";

interface IPropsTipologia {
    tipologia: ITipologia,
    status: number,
    coresPerfis:IOptionsString[],    
    vidros:IOptionsString[],
    editavel:boolean,
    onSave: SubmitHandler<FormData>,
    onDelete:(id: string, descricao: string) => void,
    onUndo:(id: string) => void,
    onSelecao: (e: React.ChangeEvent, localizacao:string, index:number) => void;
}
interface FormData {
    propostaItemId: string
    largura: number,
    altura: number,
    quantidade: number,
    cor: { label: string, value: string },
    vidros: {
        descricaoVidro: string,
        vidro: { label: string, value: string }
    }[]
}
const FormularioDados: React.FC<IPropsTipologia> = ({tipologia, status, editavel, coresPerfis, vidros, onSave, onDelete, onUndo, onSelecao}) => {
    const { fabricante } = useAuth();
  
    const formRef = useRef<FormHandles>(null);
    const [edicao, setEdicao] = useState(false);
    
    return (
    <>
        <Row style={{justifyContent:"flex-end"}}>
            <div className="col-auto col-md-auto">
                {!fabricante && (<>
                    {editavel && !tipologia.removido && 
                    (<>{tipologia.possuiLogAlteracao && !edicao ? 
                        (<div style={{marginTop:"-30px"}}>
                            <Button
                                type='submit'
                                variant="outline-primary"
                                title="Salvar alterações"
                                className="mr-1"
                                onClick={()=> formRef.current?.submitForm()}>
                                    <FontAwesomeIcon icon={faSave}/>
                            </Button> 
                            <Button 
                                title="Desfazer alterações"
                                onClick={() => onUndo(tipologia.propostaItemLog.id)}
                                >
                                    <FontAwesomeIcon icon={faUndo}/>
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="mx-1"
                                style={{borderRadius:7}}
                                title="Remover item"
                                onClick={() => onDelete(tipologia.id, tipologia.descricao)}
                                ><i className="fa fa-times"></i>
                            </Button>
                        </div>
                        ):(<>
                            {!edicao &&
                                (<Row style={{marginTop:"-30px"}}>
                                    <Button
                                        type='submit'
                                        variant="outline-primary"
                                        title="Salvar alterações"
                                        style={{borderRadius:7}}
                                        onClick={()=> formRef.current?.submitForm()}>
                                            <FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        title="Remover item"
                                        className="mx-1"
                                        style={{borderRadius:7}}
                                        onClick={() => onDelete(tipologia.id, tipologia.descricao)}
                                        ><i className="fa fa-times"></i>
                                    </Button> 
                                </Row>)
                            }</>
                        )}
                    </>)
                    }</>)
                }
            </div>
        </Row>
        <Form ref={formRef} onSubmit={onSave}>
            <Row className="mt-4">
                <Col>
                    <label className="h6 mr-2">Largura</label>
                    <Input
                        name="largura"
                        type="text"
                        placeholder={tipologia.possuiLogAlteracao? String(tipologia.propostaItemLog.largura) : String(tipologia.largura)}
                        className={`form-control form-input-small text-center`}
                        defaultValue={tipologia.possuiLogAlteracao? tipologia.propostaItemLog.largura : tipologia.largura}
                        disabled={fabricante || tipologia.removido || status >= 3 || !editavel}
                        maxLength={5}
                        onlyNumbers
                    />
                </Col>
                <Col>
                    <label className="h6 mr-2">Altura</label>
                    <Input
                        name="altura"
                        type="text"
                        placeholder={tipologia.possuiLogAlteracao? String(tipologia.propostaItemLog.altura) : String(tipologia.altura)}
                        className={`form-control form-input-small text-center`}
                        defaultValue={tipologia.possuiLogAlteracao? tipologia.propostaItemLog.altura : tipologia.altura}
                        maxLength={5}
                        onlyNumbers
                        disabled={fabricante || tipologia.removido || status >= 3 || !editavel}
                    />
                </Col>
                <Col>
                    <label className="form-label">Quantidade</label>
                    <Input
                        name="quantidade"
                        type="text"
                        placeholder={tipologia.possuiLogAlteracao? String(tipologia.propostaItemLog.quantidade) : String(tipologia.quantidade)}
                        className={`form-control form-input-small text-center`}
                        defaultValue={tipologia.possuiLogAlteracao? tipologia.propostaItemLog.quantidade : tipologia.quantidade}
                        maxLength={3}
                        onlyNumbers
                        disabled={fabricante || tipologia.removido || status >= 3 || !editavel}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <label className="form-label">Cor do Produto</label>
                    <Select
                        name="cor"
                        options={coresPerfis}
                        placeholder={tipologia.possuiLogAlteracao ? tipologia.propostaItemLog.cor.codigo : tipologia.cor.codigo}
                        defaultValue={tipologia.possuiLogAlteracao ? {label:tipologia.propostaItemLog.cor.descricao, value:tipologia.propostaItemLog.cor.descricao } : {label:tipologia.cor.codigo, value:tipologia.cor.codigo}}
                        isDisabled={fabricante || tipologia.removido || status >= 3 || !editavel}
                    />
                </Col>
            </Row>
            {tipologia.vidros && (
                <>
                    <label className="form-label mt-3 p-0">Vidro(s)</label>
                    <SelectVidros tipologia={tipologia} status={status} vidros={vidros} editavel={editavel} onSelecao={onSelecao}/>
                    
                </>
            )} 
        </Form>
        <Row className="mt-5">
            <Col>
                <div className={`card ${tipologia.removido ? "mb-0" : ""} px-3 my-0`} style={{ backgroundColor: "rgb(245 247 251 / 70%)" }}>
                    <div className={`card-status ${!tipologia.removido ? "bg-blue" : "bg-secondary"}`}></div>
                    <div className="card-body px-4 pt-4 pb-0">
                        <h5>Dados originais:</h5>
                        <label style={{background:'#dbe9f7'}} className="p-1"><b className="mr-2" style={{color: "rgb(69, 129, 207)"}}>Tipo:</b>{tipologia.tipo}</label>
                        <h4 className="text-uppercase">{tipologia.descricao}</h4>
                        <Row className="d-md-flex mt-5 justify-content-between" style={{borderBottom: '1px solid #e0e0e0'}}>
                            <div className="col-12 d-flex">
                                <label className="mb-1 mr-2"><b>Cor do Produto:</b></label>
                                <label>{tipologia.cor.codigo}</label>
                            </div>
                        </Row>
                        <Row className="d-md-flex mt-3 justify-content-between" style={{borderBottom: '1px solid #e0e0e0'}}>
                            <div id='glass' className="col-12 col-md-12 d-flex " >
                                <label className="mr-2"><b>Área Esquadria:</b>{tipologia.areaEsquadria?.toFixed(2)}</label>
                            </div>
                            {tipologia.vidros && (<div id='miter' className="col-12 col-md-6">
                                <label><b className="mr-2">Área Vidro:</b>{tipologia.vidros.reduce((total,vidro)=>{
                                        return total + vidro.areaVidro;
                                    },0)?.toFixed(2)}
                                </label>
                            </div>)}
                        </Row>   
                        {tipologia.vidros && (
                        <Row className="vidro">
                            <div id='glass' className="col-12 col-md-12 d-flex">
                                <Vidros tipologia={tipologia} vidros={vidros}/>
                            </div>
                        </Row>)}
                        <Row  style={{borderBottom: '1px solid #e0e0e0'}}>
                            <div id='miter' className="col-12 col-md-6 mt-2">
                                <label className="mr-2"><b>Localização:</b>{tipologia.localizacao}</label>
                            </div>
                        </Row>
                        <Row className="m-0 align-items-center my-4">
                            <Col lg={4} md={12}>
                                <Row>
                                    <label><b style={{color: "rgb(69, 129, 207)"}} >Linha:</b>{tipologia.linhaTipologia}</label>
                                </Row>
                                <Row>
                                    <label ><b className="mr-2">Qtd:</b>{tipologia.quantidade}</label>
                                    <label className="px-6"><b className="mr-2">L:</b>{tipologia.largura}</label>
                                    <label><b className="mr-1">H:</b>{tipologia.altura}</label>
                                </Row>
                            </Col>
                            <Col lg={7} md={12} className="d-flex px-lg-9 text-center">
                                <Card className="mb-0">
                                    <label><b style={{color: "rgb(69, 129, 207)"}}>Valor (un):</b></label>
                                    <label>{`R$ ${(tipologia.valor/tipologia.quantidade)?.toLocaleString('pt-br',{minimumFractionDigits:2})}`}</label>
                                </Card>
                                <Card className="ml-5 mb-0">
                                    <label><b style={{color: "#01d101"}}>Valor Total:</b></label>
                                    <label>{`R$ ${tipologia.valor?.toLocaleString('pt-br',{minimumFractionDigits:2})}`}</label>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>);
}

export default FormularioDados;
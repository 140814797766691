import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Tabs, Tab, Container, Row } from "react-bootstrap";
import RadioCheckOpcaoMontagem from '../../../../components/RadioCheckOpcaoMontagem';
import api from '../../../../services/api';

import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core';

import { ExibirOpcoesClienteContext } from '../../../../contexts/exibirOpcoesCliente/exibirOpcoesClienteContext';
import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';


interface IOpcoesCliente {
    opcaoMontagem: string,
    opcoes: [
        {
            id: string,
            descricao: string,
            selecionado: boolean
            url: string
        }
    ]
}

const ModalExibirOpcoesCliente: React.FC = () => {

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Context
    const toastContext = useContext(ToastContext);
    const exibirOpcoesClienteContext = useContext(ExibirOpcoesClienteContext);

    //States
    const [loading, setLoading] = useState(false);
    const [opcoesDeMontagem, setOpcoesDeMontagem] = useState<IOpcoesCliente[]>([]);

    //Effects
    useEffect(() => {
        if (exibirOpcoesClienteContext.show) {
            (async () => {
                try {
                    setLoading(true);
                    const { data } = await api.get(`/Propostas/ObterListaOpcoesMontagemComValores?propostaItemId=${exibirOpcoesClienteContext.tipologiaId}`)
                    setOpcoesDeMontagem(data)
                    setLoading(false);

                } catch (err) {
                    const message = getSingleErrorMessage(err);
                    if (message) {
                        toastContext.notification(message, "error");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                }
            })()
        }
    }, [exibirOpcoesClienteContext.show])

    //Handles
    const handleClose = () => {
        exibirOpcoesClienteContext.setShow(false);
        setOpcoesDeMontagem([])
    };

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            const filteredValues = Object.entries(data).filter(([_, v]) => v != null);
            const idsSelecionados = filteredValues.map(value => value[1]);
            const putObj = {
                propostaItemId: exibirOpcoesClienteContext.tipologiaId,
                opcoesIds: idsSelecionados
            }
            await api.put(`/Propostas/AtualizarListaOpcoesMontagemValorItem`, putObj)
            toastContext.notification("Alteração de opções de montagem realizadas.", "success");
            handleClose();

        } catch (err) {
            const message = getSingleErrorMessage(err);
            if (message) {
                toastContext.notification(message, "error");
            } else {
                toastContext.notification(
                    "Ocorreu um erro ao realizar essa operação.",
                    "error"
                );
            }
        }
    }

    return (
        <Modal show={exibirOpcoesClienteContext.show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Opções de Montagem Disponíveis</Modal.Title>
            </Modal.Header>
            <Modal.Body className="vincular-imagens">
                <div className={'dimmer' + (loading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={'dimmer-content' + (loading ? ' medium-box-loader' : '')}>
                        <p className="pb-5 pt-2 text-center">Ao abrir as categorias abaixo você pode ver e alterar a opção de montagem do seu produto.</p>
                        <div className="card card-tabs-custom">
                            <Container>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <Tabs>
                                        {opcoesDeMontagem.map((opcao, idx) => (
                                            <Tab eventKey={opcao.opcaoMontagem} title={opcao.opcaoMontagem} key={idx}>
                                                <Row>
                                                    <div className="card-body col">
                                                        <Row>
                                                            <RadioCheckOpcaoMontagem
                                                                name={opcao.opcaoMontagem}
                                                                options={opcao.opcoes}
                                                            />
                                                        </Row>
                                                    </div>
                                                </Row>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </Form>
                            </Container>
                        </div>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={() => formRef.current?.submitForm()}>
                    <i className="fa fa-save mr-2"></i>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalExibirOpcoesCliente;
import React from 'react';

import Title from '../../components/Title';

const Contact: React.FC = () => {
  return (
    <Title>Contact</Title>
 );
}

export default Contact;
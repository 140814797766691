
import { Router } from 'react-router-dom';
import { GlobalStyle } from './styles/global';

import Routes from './routes';
import AppProvider from './hooks';
import history from './services/history';

import './services/api-fake';

export function App() {
  return (
    <AppProvider>
      <Router history={history}>
        <Routes />
      </Router>
      <GlobalStyle />
    </AppProvider>
  );
}


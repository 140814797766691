import React, { useContext, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ColunaLateral } from "../ColunaLateral";
import { NoData } from '../../../components/DataNotFound';

import { MenuLateralContext } from '../../../contexts/menuLateral/menuLateralContext';
import { IOptionsString } from '../../../interfaces/options.interface';

// import './styles.css';
interface IShowImage {
    showing: boolean,
    src?: string,
}

interface IPropsVisualizadorImagem {
    setAreaAtiva: React.Dispatch<React.SetStateAction<string>>,
    editavel : boolean,
    coresPerfis: IOptionsString[],
    vidros:IOptionsString[]
}

const VisualizadorImagem: React.FC<IPropsVisualizadorImagem> = ({ setAreaAtiva,editavel,coresPerfis,vidros }) => {

    //Contexts
    const menuLateralContext = useContext(MenuLateralContext);

    //States
    const [showImage, setShowImage] = useState<IShowImage>({ showing: false });

    //Handles
    const handleShowImage = (url: string) => {
        setShowImage({
            showing: true,
            src: url,
        })
    }

    return (
        <>
            <hr />

            <Row>
                <Col md={12} lg>
                    <Row className="mb-3">
                        <Col>
                            <h4>Imagens do produto:</h4>
                        </Col>
                        <Col xs="auto" className="ml-auto">
                            <Button onClick={() => setAreaAtiva("plantaBaixa")} variant="outline-secondary" className="toggle-lateral"><i className="fe fe-x"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        {menuLateralContext.tipologia.imagens.map((imagem) => (
                            <Col md={6} key={imagem.id}>
                                <div className="card card-sm">
                                    <Link to="#" onClick={() => handleShowImage(imagem.url)} className="d-block">
                                        <img src={imagem.url} className="card-img-top img-cover" />
                                    </Link>
                                </div>
                            </Col>
                        ))}
                        <NoData
                            visible={menuLateralContext.loading === false ? menuLateralContext.tipologia.imagens?.length === 0 : false}
                        >
                            Não existe uma imagem para esse produto.
                        </NoData>
                    </Row>
                </Col>
                <div className="vr"></div>
                <ColunaLateral setAreaAtiva={setAreaAtiva} editavel={editavel} coresPerfis={coresPerfis} vidros={vidros} />

            </Row>

            {/* Modal Lightbox */}
            <Modal show={showImage.showing} onHide={() => setShowImage({ showing: false })} centered size="lg">
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <img src={showImage.src} alt="Imagem" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export { VisualizadorImagem };
import { createServer } from "miragejs"

const server = createServer({
    routes() {

        // this.namespace = "fake"


        this.get("/propostas", () => {
            return {
                items: [
                    {
                        id: 3,
                        codigo: "0000003",
                        obra: "Residência Sr. Mario Jorge",
                        validade: "02/10/2022",
                        valorTotal: 9999.99,
                        usuarioPropostaOnline: "João da Silva",
                        orcamentista: "Marcos da Silveira",
                        propostaStatus: 5,
                    },
                    {
                        id: 2,
                        codigo: "0000002",
                        obra: "Residência Sr. Paulo Sérgio",
                        validade: "02/10/2022",
                        valorTotal: 9999.99,
                        usuarioPropostaOnline: "João da Silva",
                        orcamentista: "Luiz Carlos",
                        propostaStatus: 4,
                    },
                    {
                        id: 1,
                        codigo: "0000001",
                        obra: "Residência Sr. Pedro João",
                        validade: "02/10/2022",
                        valorTotal: 19999.99,
                        usuarioPropostaOnline: "-",
                        orcamentista: "Matheus Castro",
                        propostaStatus: 2,
                    }
                ],
                totalPages: 1
            }
        }, { timing: 500 })

        this.get("/tipologias", () => [
            {
                id: 1,
                nome: "PORTA DE CORRER INTEGRADA - 2 FOLHAS - RECOLHEDOR DE FITA - LINHA SUPREMA",
                largura: 1200,
                altura: 2200,
                quantidade: 1,
                cor: { label: "BRANCO", value: 1 },
                vidro: { label: "TEMPERADO 6MM", value: 1 },
                valor: "R$ 9.999,99",
            },
            {
                id: 2,
                nome: "JANELA DE CORRER - 4 FOLHAS - LINHA SUPREMA",
                largura: 1600,
                altura: 1000,
                quantidade: 1,
                cor: { label: "BRANCO", value: 1 },
                vidro: { label: "TEMPERADO 6MM", value: 1 },
                valor: "R$ 9.999,99",
            },

        ], { timing: 500 })

        this.get("/Usuarios/ObterListaItemSelecao", () => [
            { label: "Usuário 1", value: 1 },
            { label: "Usuário 2", value: 2 },
        ])

        this.get("/galeriaVideos", () => (
            {
                items: [
                    {
                        titulo: "Título Vídeo 1",
                        url: "https://www.youtube.com/embed/pBOPrU5aLvc",
                        id: 1
                    },
                    {
                        titulo: "Título Vídeo 2",
                        url: "https://vimeo.com/601088734",
                        id: 2
                    },
                    {
                        titulo: "Título Vídeo 3",
                        url: "https://www.youtube.com/embed/pBOPrU5aLvc",
                        id: 3
                    },
                    {
                        titulo: "Título Vídeo 4",
                        url: "https://vimeo.com/601088734",
                        id: 4
                    },
                    {
                        titulo: "Título Vídeo 5",
                        url: "https://www.youtube.com/embed/pBOPrU5aLvc",
                        id: 5
                    },
                    {
                        titulo: "Título Vídeo 6",
                        url: "https://vimeo.com/601088734",
                        id: 6
                    },
                    {
                        titulo: "Título Vídeo 7",
                        url: "https://www.youtube.com/embed/pBOPrU5aLvc",
                        id: 7
                    },
                    {
                        titulo: "Título Vídeo 8",
                        url: "https://vimeo.com/601088734",
                        id: 8
                    }
                ]
            }
        ))

    },

})

if (process.env.REACT_APP_API !== "")
    server.shutdown();
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Input from '../../../../components/Input';
import PhoneInput from '../../../../components/PhoneInput';
import TextArea from '../../../../components/TextArea';
import SwitchCheckbox from '../../../../components/SwitchCheckbox';
import api from '../../../../services/api';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup';
import getValidationErrors from "../../../../helpers/getValidateErrors";

import { ToastContext } from '../../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../../helpers/getSingleErrorMessage';

import './styles.css';

interface IContatos {
    endereco: string,
    telefones: [
        {
            telefone: string,
            whatsapp: boolean
        }
    ],
    emails: [
        {
            email: string
        }
    ]
}

const Contato: React.FC = () => {

    //Ref
    const formRef = useRef<FormHandles>(null);

    //Context
    const toastContext = useContext(ToastContext);

    //States
    const [loading, setLoading] = useState(false);
    const [contatos, setContatos] = useState({} as IContatos);
    const [reload, setReload] = useState(false);

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get(`/Fabricantes/Contatos`)
                setContatos(data)

                formRef.current?.setData({
                    endereco: data.endereco ? data.endereco : "",
                    emails: data.emails?.length > 0 ? data.emails : [],
                    telefones: data.telefones?.length > 0 ? data.telefones : []
                });
            } catch (err) {
                console.log(err);
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
            finally{
                setLoading(false);
            }
        })()
    }, [reload])

    //Handles
    const handleSubmit: SubmitHandler = async (dados) => {
        try {
            formRef.current?.setErrors({});

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                endereco: Yup.string().required(message),
                emails: Yup.array()
                    .of(
                        Yup.object().shape({
                            email: Yup.string()
                                .email("Digite um e-mail válido.")
                                .test({
                                    name: 'Valida se o E-mail 1 está preenchido',
                                    message: message,
                                    test: (value, context) => {
                                        if (context.path === "emails[0].email" && !value) {
                                            return false;
                                        }
                                        return true;
                                    }
                                }),
                        })
                    ),
                telefones: Yup.array()
                    .of(
                        Yup.object().shape({
                            telefone: Yup.string()
                                .test({
                                    name: 'Valida se o Telefone 1 está preenchido',
                                    message: message,
                                    test: (value, context) => {
                                        if (context.path === "telefones[0].telefone" && !value) {
                                            return false;
                                        }
                                        return true;
                                    }
                                }),
                        })
                    )

            });
            await schema.validate(dados, { abortEarly: false });

            //Passou pela validação

            await api.post("Fabricantes/Contatos", dados);
            toastContext.notification("Contatos atualizados.", "success");
            formRef.current?.reset();
            setReload(true);

        } catch (err: any) {

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    };

    return (
        <>
            <div className="card personalizacao-contato">
                <div className="card-header">
                    <h3 className="card-title">Contato</h3>
                </div>
                <div className="card-body">
                    <div className={'dimmer' + (loading ? ' active' : '')}>
                        <div className="loader"></div>
                        <div className={'dimmer-content' + (loading ? ' small-box-loader' : '')}>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row>
                                    <Col md className="endereco">
                                        <div className="form-group">
                                            <TextArea name="endereco" label="Endereço" placeholder="Digite o endereço da sua empresa..." maxLength={250} />
                                        </div>
                                    </Col>
                                    <div className="vr" />
                                    <Col md className="emails">
                                        <div className="form-group">
                                            <Input name="emails[0].email" type="text" label="E-mail 1" placeholder="Digite um e-mail..." maxLength={80} />
                                        </div>
                                        <hr />
                                        <div className="form-group">
                                            <Input name="emails[1].email" type="text" label="E-mail 2" placeholder="Opcional. Digite outro e-mail..."  maxLength={80} />
                                        </div>
                                    </Col>
                                    <div className="vr" />
                                    <Col md className="telefones">
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between align-items-end">
                                                <label className="form-label">Telefone 1</label>
                                                <SwitchCheckbox id="telefone1" name="telefones[0].whatsapp" label="É WhatsApp?" />
                                            </div>
                                            <PhoneInput name="telefones[0].telefone" placeholder="Digite um telefone..." />
                                        </div>
                                        <hr className="mt-5" />
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between align-items-end">
                                                <label className="form-label">Telefone 2</label>
                                                <SwitchCheckbox id="telefone2" name="telefones[1].whatsapp" label="É WhatsApp?" />
                                            </div>
                                            <PhoneInput name="telefones[1].telefone" placeholder="Opcional. Digite outro telefone..." />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right col-xs-12 col-sm-12 col-md-12">
                    <Button variant="primary" onClick={() => formRef.current?.submitForm()}>
                    <i className="fa fa-save mr-2"></i>Salvar Contato
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Contato;
import React, { createContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

interface Tipos {
  warning: any,
  danger: any,
  info: any,
  custom: any,
  alert: any,
  input:any,
  close: () => void,
  observacao: string,
  setObservacao:React.Dispatch<React.SetStateAction<string>>, 
  ativo:boolean,
  tipo: string,
  setTipo:React.Dispatch<React.SetStateAction<string>>,
}

interface Dados {(
  title: string, 
  message: string,
  onConfirm: (value?:string) => void,
):any}

const SweetAlertContext = createContext({} as Tipos);

const initialState = {
  alert: null,
};

const SweetAlertProvider:React.FC = ({ children }) => {
  const [state, setState] = useState<any>(initialState);  
  const [ativo, setAtivo] = useState(false);
  const [observacao, setObservacao] = useState("");
  const [tipo, setTipo] = useState("")
  

  const close = () => {
    setState({ ...state, alert: null });
  };

  const confirmEvent = async(onConfirm: any) => {
    try {
      await onConfirm();
      close();
    } catch{
      close();
    }
  }

  const closeEvent = async(onClose: any) => {
    try {
      await onClose();
      close();
    } catch{
      close();
    }
  }

  const warning: Dados = (title, message, onConfirm, onClose = close)  => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          warning
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          confirmBtnBsStyle="warning"
          showCancel         
          openAnim
          onConfirm={() => confirmEvent(onConfirm())}
          onCancel={() => closeEvent(onClose)}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"         
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const info: Dados = (title, message, onConfirm) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          confirmBtnBsStyle="info"
          showCancel          
          openAnim
          onConfirm={onConfirm}
          onCancel={close}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const danger: Dados = (title, message, onConfirm) => {      
    setState({
      ...state,      
      alert: (        
        <SweetAlert
          show
          danger
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          openAnim
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={onConfirm}
          onCancel={close}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"
          >      
          {message}          
        </SweetAlert>
      ),
    });
  };

  const custom: Dados = (title, message, buttons) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          onConfirm={() => {
            return true;
          }}
          openAnim
          customButtons={<>{buttons}</>}
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const alert: Dados = (title, message) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          onConfirm={close}
          openAnim
        >
         {message}
        </SweetAlert>
      ),
    });
  };

  const input: Dados = (title, message, onConfirm, onClose = close)  => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          showCancel        
          warning
          cancelBtnText="Cancelar"
          title={title}
          style={{ display: "block" }}
          onConfirm={() =>
            {
              setAtivo(true);
              confirmEvent(onConfirm());
              
            }
          }
          
          onCancel={() => {
            closeEvent(onClose);
            setTipo("")
          }}
          customClass="alerts"
          confirmBtnBsStyle="warning"
          openAnim
          confirmBtnText="Confirmar"         
        >
          {message}
          <Form>
            <Form.Group className="my-3">
              <Form.Label style={{fontSize:"1.125em", fontWeight:400}}>Observação</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                style={{border:"2px solid #467fcf"}}
                placeholder="Digite uma observação..."
                onChange={e => setObservacao(e.currentTarget.value)}
                />
            </Form.Group>
          </Form>
        </SweetAlert>
      ),
    });
  };

  useEffect(() => {    
    if(ativo === true) setAtivo(false);
  }, [ativo, tipo]);

  return (
    <SweetAlertContext.Provider
      value={{
        warning,
        danger,
        info,
        custom,
        alert,
        input,
        close,
        observacao,
        setObservacao,
        ativo,
        tipo,
        setTipo
      }}
    >
      {children}
      {state?.alert}
    </SweetAlertContext.Provider>
  );
};

export { SweetAlertProvider, SweetAlertContext };

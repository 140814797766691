import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Input from '../../components/Input';
import logo from '../../assets/img/proposta-online-logo.svg';
import api from '../../services/api';

import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../helpers/getValidateErrors';

import { ToastContext } from '../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';

import './styles.css';

interface FormData {
  Email: string
}

const EsqueciSenha: React.FC = () => {

  //Ref
  const formRef = useRef<FormHandles>(null)

  //Context
  const toastContext = useContext(ToastContext);

  //States
  const [loading, setLoading] = useState(false);

  //Handles
  const handleSubmit: SubmitHandler<FormData> = async (data) => {

    //Validação
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        Email: Yup.string().email('Digite um e-mail válido.').required('O e-mail é obrigatório.')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      //Validação passou
      setLoading(true);
      await api.post("/Autenticacao/EnviarEmailEsqueciSenha", data)
      toastContext.notification("E-mail enviado. Acesse seu e-mail e siga as instruções para criar uma nova senha.", "success");
      formRef.current?.reset();

    } catch (err) {

      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      }
      else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }      
    } finally {
      setLoading(false);      
    }
  }

  return (
    <div className="page img-background-login">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <Form className="card" ref={formRef} onSubmit={handleSubmit}>
                <div className="card-body p-6">
                  <div className="text-center mb-6">
                    <img src={logo} className="h-7" alt="" />
                  </div>
                  <div className="card-title">Esqueceu sua senha?</div>
                  <p className="text-muted">Sem problemas, digite o e-mail e enviaremos um link para criar uma nova senha.</p>
                  <div className="form-group">
                    <label className="form-label">E-mail</label>
                    <Input name="Email" className="form-control" placeholder="Digite o e-mail cadastrado" />
                  </div>
                  <div className="form-footer">
                    <Button type="submit" className="btn-block" disabled={loading}>{loading ? "Validando..." : "Enviar E-mail"}</Button>
                  </div>
                </div>
              </Form>
              <div className="text-center text-muted text-white">Lembrou sua senha? <Link to="/" className="text-white">Fazer login.</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EsqueciSenha;
import './styles.css'

interface IProps {
    loading: boolean;
    children: React.ReactNode
}

const LoadingBlock: React.FC<IProps> = ({ loading, children }) => {
    return (
        <div className={'dimmer' + (loading ? ' active' : '')}>
            <div className="loader"></div>
            <div className='dimmer-content'>
                {children}
            </div>
        </div>)
}

export default LoadingBlock;
import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};
  if (err instanceof ValidationError) {

    err.inner.forEach((error: any) => {
      if (error.path) {
        validationErrors[error?.path] = error?.message;
      }
    });
  }

  return validationErrors;
}
import React, { useContext, useState} from 'react';
import { Button, Card, Carousel, CarouselItem, Col, Collapse, Modal, ModalBody, Row } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';

import logo from '../../../assets/img/proposta-online-logo.svg'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight, faPlay } from "@fortawesome/free-solid-svg-icons";

import { VincularImagensContext } from '../../../contexts/vincularImagens/vincularImagensContext';
import { VincularVideosContext } from '../../../contexts/vincularVideos/vincularVideosContext';
import { MenuLateralContext } from "../../../contexts/menuLateral/menuLateralContext";

import './styles.css';
import { IOptionsString } from '../../../interfaces/options.interface';
import ReactPlayer from 'react-player';
import { Vidros } from '../Tipologia/Vidros';

 //Carousel
 const prevIcon = <FontAwesomeIcon icon={faArrowCircleLeft} style={{color: "#77a4e4", fontSize: "24px"}}/>
 const nextIcon = <FontAwesomeIcon icon={faArrowCircleRight} style={{color: "#77a4e4", fontSize: "24px"}}/>

interface IProps {
    setAreaAtiva: (value: string) => void,
    editavel: boolean,
    coresPerfis: IOptionsString[],
    vidros:IOptionsString[]
}

const ColunaLateral: React.FC<IProps> = ({ setAreaAtiva, editavel, coresPerfis,vidros }) => {

    //Auth
    const { fabricante } = useAuth();

    //Contexts
    const menuLateralContext = useContext(MenuLateralContext);
    const vincularImagensContext = useContext(VincularImagensContext);
    const vincularVideosContext = useContext(VincularVideosContext);

    //States
    const [aberta, setAberta] = useState(true);
    const [carosel, setCarosel] = useState('');
    
    const [showModalVideo, setShowModalVideo] = useState(false);
    const handleCarosel = async (car:string) => {
        return setCarosel(car);
    }
    return (
        <>
            <Col lg={aberta ? 4 : "auto"} className="grow-left coluna-lateral" style={{border:'1px solid #e0e0e0', borderRadius:10}}>


                <Collapse in={aberta} dimension="width">
                    <div className={`dimmer ${menuLateralContext.tipologia.removido ? ' active' : ''}`}>
                        <div className={`dimmer-content ${menuLateralContext.tipologia.removido ? "item-removido" : ""}`}>
                            <div key={menuLateralContext.tipologia.id}>
                                 <Col className="d-flex flex-column">
                                    <Row id='' className='justify-content-center a mt-3' style={{height: 270}}>
                                        <Carousel nextIcon={nextIcon} prevIcon={prevIcon} fade interval={1500} style={{ background:'#eceff5'}}>
                                            <CarouselItem style={{ height: 270}} onClick={()=> handleCarosel(menuLateralContext.tipologia.urlCroqui)}>
                                                <img
                                                style={{ alignContent:'center', height: 270, width:350}}
                                                className=''
                                                src={menuLateralContext.tipologia.urlCroqui ? menuLateralContext.tipologia.urlCroqui : "https://via.placeholder.com/420x270" }
                                                alt='Imagem do item'/>
                                            </CarouselItem>
                                            {menuLateralContext.tipologia.imagens?.map((imagem)=>(
                                                <CarouselItem style={{ height: 270}} onClick={()=> handleCarosel(imagem.url)}>
                                                    <img
                                                    style={{ height: 270, width:350}}
                                                    className=''
                                                    src={imagem.url ? imagem.url : "https://via.placeholder.com/420x270"}
                                                    alt='Imagem do item'/>
                                                </CarouselItem>
                                            ))}
                                            <CarouselItem style={{ height: 270, width:350}}>
                                            {menuLateralContext.tipologia.videos?.length > 0 ? (
                                                <div className='text-center'>
                                                    <Button 
                                                    id="videoCarousel"
                                                    variant="outline-primary" 
                                                    title="Ver vídeo da esquadria" 
                                                    onClick={() => setShowModalVideo(true)}>
                                                        <FontAwesomeIcon icon={faPlay} className='ml-1'/>
                                                    </Button>
                                                </div>
                                               ):(<> 
                                                <div id="logo" className="text-center">
                                                    <img src={logo} className="h-5" alt="" />
                                                </div>
                                               </>)}
                                            </CarouselItem>
                                            
                                        </Carousel>
                                    </Row>
                                </Col>
                               <Col>
                                    <Row>
                                        <h4 className='mt-6'>{menuLateralContext.tipologia.descricao}</h4>
                                    </Row>
                                    <Row  className='linhas'>
                                        <div className="d-flex">
                                            <label><b>Cor:</b></label>
                                            <label className='ml-2'>{menuLateralContext.tipologia.cor?.codigo}</label>
                                        </div>
                                    </Row>                                    <Row className='linhas'>
                                        <label><b className='mr-2'>Área Esquadria:</b>{menuLateralContext.tipologia.areaEsquadria?.toFixed(2)}</label>
                                    </Row>
                                    {menuLateralContext.tipologia.vidros && (
                                        <Row className="vidro">
                                            <div id='glass' className="col-12 col-md-12 d-flex px-0">
                                                <Vidros tipologia={menuLateralContext.tipologia} vidros={vidros}/>
                                            </div>
                                        </Row>
                                    )}
                                    <Row  className='linhas'>
                                        <label><b className='mr-2'>Localização:</b>{menuLateralContext.tipologia.localizacao}</label>
                                    </Row>
                                    <Row className='mt-4 justify-content-center'>
                                        <label><b style={{color:'rgb(69,129,207)'}} className='mr-2'>Linha:</b>{menuLateralContext.tipologia.linhaTipologia}</label>
                                        <label><b style={{color:'rgb(69,129,207)'}} className='ml-5 mr-2'>Tipo:</b>{menuLateralContext.tipologia.tipo}</label>
                                    </Row>
                                    <Row className='my-1 justify-content-center'>
                                        <label><b className='mr-2'>Qtd:</b>{menuLateralContext.tipologia.quantidade}</label>
                                        <label className='px-6'><b className='mr-2'>L:</b>{menuLateralContext.tipologia.largura}</label>
                                        <label><b className='mr-2'>H:</b>{menuLateralContext.tipologia.altura}</label>
                                    </Row>
                                    <Col className='d-flex px-7 text-center justify-content-between'>
                                        <Card>
                                            <label className='mt-2'><b style={{color: "rgb(69, 129, 207)"}}>Valor (un):</b></label>
                                            <label>{`R$ ${(menuLateralContext.tipologia.valor/menuLateralContext.tipologia.quantidade)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}</label>
                                        </Card>
                                        <Card className='ml-3'>
                                            <label className='mt-2'><b style={{color: "#01d101"}}>Valor Total:</b></label>
                                            <label>{`R$ ${menuLateralContext.tipologia.valor?.toLocaleString('pt-br',{minimumFractionDigits:2})}`}</label>
                                        </Card>
                                    </Col>
                               </Col>
                                <Row className="my-2 d-flex flex-column flex-sm-row flex-lg-column justify-content-center align-items-center">
                                    {fabricante ?
                                        <>
                                            <Col sm={4} lg={8}>
                                                <Button
                                                    variant="outline-primary"
                                                    className="px-0 px-xl-5 w-100 mb-3 mb-sm-0 mb-lg-3"
                                                    onClick={() => vincularImagensContext.handleModal(menuLateralContext.tipologia.id)}
                                                    disabled={!editavel}
                                                >
                                                    Vincular Imagens
                                                </Button>
                                            </Col>
                                            <Col sm={4} lg={8}>
                                                <Button
                                                    variant="outline-primary"
                                                    className="px-0 px-xl-5 w-100"
                                                    onClick={() => vincularVideosContext.handleModal(menuLateralContext.tipologia.id)}
                                                    disabled={!editavel}
                                                >
                                                    Vincular Vídeos
                                                </Button>
                                            </Col>
                                            {menuLateralContext.tipologia.esquadriaUrl3D && 
                                            <>
                                            <Col sm={4} lg={8}>
                                                <Button
                                                    className="px-0 px-xl-5 w-100 mt-3"
                                                    variant="outline-secondary"
                                                    title="Ver modelo 3D da esquadria"
                                                    onClick={() => setAreaAtiva('3D')}
                                                    ><i className="fe fe-box"></i>
                                                    Visualizar 3D
                                                </Button>
                                            </Col>
                                            </>}
                                        </>
                                        :
                                        <>
                                        {menuLateralContext.tipologia.esquadriaUrl3D && 
                                        <>
                                            <Button
                                                className="col-10 mr-3 mb-5"
                                                variant="outline-secondary"
                                                title="Ver modelo 3D da esquadria"
                                                onClick={() => setAreaAtiva('3D')}
                                                ><i className="fe fe-box mr-2"></i>
                                                Vizualize em 3D
                                            </Button>
                                        </>}
                                        </>
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Col>
            <Modal show={showModalVideo} onHide={() => setShowModalVideo(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Vídeos do Produto:</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    {menuLateralContext.tipologia.videos?.map((video)=>(
                    <Col key={video.id}>
                        <ReactPlayer
                            controls
                            playsinline
                            width='100%'
                            url={video.url}
                        /> 
                    </Col>
                    ))}
                </ModalBody>
            </Modal>
        </>
    );
}

export { ColunaLateral };
import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Card,
  Pagination,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { DataNotFound } from "../../components/DataNotFound";
import api from "../../services/api";
import { ShowStatus } from "../../components/ShowStatus";
import { useAuth } from "../../hooks/auth";

import { ToastContext } from "../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { IPropostas } from "../../interfaces/proposta.interface";
import { Paginacao } from "../../interfaces/paginacao.interface";
import { IOptionsNumber } from "../../interfaces/options.interface";

import "./styles.css";

const Propostas: React.FC = () => {
  const status = [
    { label: "Nova Proposta", value: 1 }, 
    { label: "Enviada para o cliente", value: 2 },
    { label: "Aprovada", value: 3 },
    { label: "Devolvida para ajustes", value: 4 },
    { label: "Proposta Recusada pelo Cliente", value: 8 },
    { label: "Em Revisão pelo ADM", value: 9 },
  ];

  //Auth
  const { nivelAcesso, fabricante } = useAuth();


  // History
  let history = useHistory();

  //Context
  const toastContext = useContext(ToastContext);

  //States
  const [pagination, setPagination] = useState<Paginacao>(new Paginacao());
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterUsuario, setFilterUsuario] = useState<number | undefined>();
  const [filterStatus, setFilterStatus] = useState<number | undefined>();
  const [showModalLinkProposta, setShowModalLinkProposta] = useState(false);
  const [usuarios, setUsuarios] = useState<IOptionsNumber[]>([]);
  const [linkProposta, setLinkProposta] = useState("");
  const [linkCopiado, setLinkCopiado] = useState(false);
  const [ numPagina, setNumPagina ] = useState(0);

  //Fetch  
  const fetchData = useCallback(async (paginacao) => {
    try {
      setTableLoading(true);
        const { data } = await api.get(
          `/propostas?${paginacao}`);

        setTableData(data.items);
        setFilterTableData(data.items);
        setPageCount(data.totalPages);
        setTableLoading(false);
    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setTableLoading(false);
    }
  }, [filterStatus, filterUsuario, filter]);
  
  const handleFilter = (numPagina:number) => {
    if(filterStatus && filterUsuario && filter.length > 2) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilter=${filter}&GlobalFilterOptional1=${filterUsuario}&GlobalFilterOptional2=${filterStatus}`;
      return value;
    } else if(filterUsuario && filterStatus) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilterOptional1=${filterUsuario}&GlobalFilterOptional2=${filterStatus}`;
      return value;
    } else if(filterUsuario && filter.length > 2) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilter=${filter}&GlobalFilterOptional1=${filterUsuario}`;
      return value;
    }else if(filterStatus && filter.length > 2) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilter=${filter}&GlobalFilterOptional2=${filterStatus}`;
      return value;
    } else if(filterUsuario) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilterOptional1=${filterUsuario}`;
      return value;
    }else if(filterStatus) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilterOptional2=${filterStatus}`;
      return value;
    } else if(filter.length > 2) {
      const value = `PageIndex=${numPagina}&PageSize=5&Desc=true&GlobalFilter=${filter}`;
      return value;
    } else {
      if(filter === ""){
        const value = `PageIndex=${numPagina}&PageSize=5&Desc=true`;
        return value;
      } 
    }
  };

  
  useEffect(() => {
    const newFilter = handleFilter(numPagina);
    fetchData(newFilter);
   }, [filterStatus, filterUsuario, filter, numPagina]);

   const handleShowModalLinkProposta = async (dados: IPropostas) => {
    try {
      const { data } = await api.get(
        `/propostas/ObterLinkAcessoClienteFinal?propostaId=${dados.id}`
      );
      setLinkProposta(data);
    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setTableLoading(false);
    }
    setShowModalLinkProposta(true);
  };

  //Effect
  useEffect(() => {
    (async () => {
      if (nivelAcesso && nivelAcesso !== "Cliente") {
        try {
          const { data } = await api.get(`Usuarios/ObterListaItemSelecao`);
          setUsuarios(data);
        } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      }
    })();
  }, []);
  
  //Handles'
  const handleOpen = (dados: IPropostas) => {
    history.push({
      pathname: `/propostas/${dados.id}`,
      state: dados,
    });
  };

  const handleCopiarLink = async () => {
    if ("clipboard" in navigator) {
      setLinkCopiado(true);
      return await navigator.clipboard.writeText(linkProposta);
    } else {
      setLinkCopiado(true);
      return document.execCommand("copy", true, linkProposta);
    }
  };

  function GerarPaginacao(): any {
    if (pagination.pageIndex === 0) {
      return (
        <>
          <Pagination.Item active={true}>
            {pagination.pageIndex + 1}
          </Pagination.Item>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex + 1)}
          >{pagination.pageIndex + 2}
          </Pagination.Item>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex + 2)}
          >{pagination.pageIndex + 3}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => changePagination(pagination.pageIndex + 1)}
          ></Pagination.Next>
          <Pagination.Last onClick={() => changePagination(pageCount - 1)}/>
        </>
      );
    } else if (pagination.pageIndex === (pageCount - 1)) {
      return (
        <>
          <Pagination.First onClick={() => changePagination(pageCount - pageCount)}/>
          <Pagination.Prev
            onClick={() => changePagination(pagination.pageIndex - 1)}
          ></Pagination.Prev>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex - 2)}
          >{pagination.pageIndex - 1}
          </Pagination.Item>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex - 2)}
          >{pagination.pageIndex}
          </Pagination.Item>
          <Pagination.Item active={true}>
            {pageCount}
          </Pagination.Item>
        </>
      );
    } else {
      return (
        <>
          <Pagination.First onClick={() => changePagination(pageCount - pageCount)}/>
          <Pagination.Prev
            onClick={() => changePagination(pagination.pageIndex - 1)}
          ></Pagination.Prev>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex - 1)}
          >
            {pagination.pageIndex}
          </Pagination.Item>
          <Pagination.Item active={true}>
            {pagination.pageIndex + 1}
          </Pagination.Item>
          <Pagination.Item
            onClick={() => changePagination(pagination.pageIndex + 1)}
          >
            {pagination.pageIndex + 2}
          </Pagination.Item>
          
          <Pagination.Next
            onClick={() => changePagination(pagination.pageIndex + 1)}
          ></Pagination.Next>
          <Pagination.Last onClick={() => changePagination(pageCount - 1)}/>
       </>
      );
    }
  }

  function changePagination(nextPage: number): any {
    setNumPagina(nextPage);
    pagination.pageIndex = nextPage;
  }
  return (
    <div className="my-3 my-md-5">
      <div className="container">
        <div className="page-header">
          <h1 className="page-title">Propostas On-line</h1>
        </div>
      </div>
      <div className="container px-5">
        <div className="row">
          <div style={{ width: "100%" }}>
            {fabricante && (
              <div className="card-header p-5 border-0">
                <Container fluid>
                  <Row className="justify-content-end">
                    {nivelAcesso !== "Vendedor" && (
                      <Col md lg={3} xl={2} className="mb-4 mb-md-0">
                        <Select
                          options={usuarios}
                          classNamePrefix="react-select"
                          onChange={(e) => setFilterUsuario(e?.value)}
                          placeholder="Orçamentista..."
                          isClearable
                        />
                      </Col>
                    )}
                    <Col md lg={3} xl={2} className="mb-4 mb-md-0">
                      <Select
                        options={status}
                        classNamePrefix="react-select"
                        onChange={(e) => setFilterStatus(e?.value)}
                        placeholder="Status..."
                        isClearable
                      />
                    </Col>
                    <Col md lg={3} xl={2}>
                      <div className="input-icon">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Busca..."
                          onChange={(e) => {
                            setFilter(e.target.value || "");
                          }}
                        />
                        <span
                          className="input-icon-addon"
                          style={{ cursor: "pointer", zIndex: 10 }}
                          onClick={() => fetchData(pagination)}
                        >
                          <i className="fe fe-search"></i>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}

            <Container>
              <div className={"dimmer" + (tableLoading ? " active" : "")}>
                <div className="loader"></div>
                <div
                  className={
                    "dimmer-content" +
                    (tableLoading ? " medium-box-loader" : "")
                  }
                >
                  {filterTableData.map((proposta: IPropostas, index) => {
                    return (
                      <Card
                        key={index}
                        className="card-dados"
                        style={{
                          background: proposta.propostaAberta
                            ? "none"
                            : "#e3eafa",
                        }}
                      >
                        <Card.Body className="mr-0">
                          <Row>
                            <Col lg={3} sm={12}>
                              <Row>
                                <Col xs={12} sm={4} lg={12}>
                                  <h5>
                                    {proposta.obra}
                                  </h5>
                                </Col>
                                <Col xs={12} sm={4} lg={12}>
                                  <h5>
                                    {proposta.nomeCliente}
                                  </h5>
                                </Col>
                                <Col xs={12} sm={4} lg={12}>
                                  <label>
                                    Cód.{proposta.codigoSistemaTecnico}
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="versao">
                              <label>Nº Versão</label>
                              <div>
                                {proposta.propostaStatus === 9 ? (
                                  <>
                                    {fabricante ? (
                                      <>{proposta.totalVersao}</>
                                    ) : (
                                      <>{proposta.totalVersao - 1}</>
                                    )}
                                  </>
                                ) : (
                                  <>{proposta.totalVersao}</>
                                )}
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              sm={12}
                              className="justify-content-between pr-lg-0 mr-lg-0"
                            >
                              <Row className="dado">
                                <Col xs={6} sm={4}>
                                  <label>
                                    <span id="valor">Valor</span>
                                  </label>
                                  <div>
                                    <b>
                                      {proposta.propostaStatus === 9 ? (
                                        <>
                                          {fabricante ? (
                                            <>
                                              {!tableLoading &&
                                                `R$ ${proposta.valorTotal?.toLocaleString(
                                                  "pt-br",
                                                  { minimumFractionDigits: 2 }
                                                )}`}
                                            </>
                                          ) : (
                                            <>Aguardando</>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {!tableLoading &&
                                            `R$ ${proposta.valorTotal?.toLocaleString(
                                              "pt-br",
                                              { minimumFractionDigits: 2 }
                                            )}`}
                                        </>
                                      )}
                                    </b>
                                  </div>
                                </Col>

                                <Col xs={6} sm={4}>
                                  <label>
                                    <span>Orçamentista</span>
                                  </label>
                                  <div>
                                    <b>{proposta.orcamentista}</b>
                                  </div>
                                </Col>

                                <Col xs={12} sm={4} className="pt-3 pt-sm-0">
                                  <ShowStatus
                                    status={proposta.propostaStatus}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} sm={12} className="statusBtt">
                              <Button
                                variant="primary"
                                onClick={() => handleOpen(proposta)}
                                title="Abrir proposta"
                                className="mr-1 col-md-auto col-6"
                              >
                                <i className="fe fe-external-link"></i>
                                <span className="d-md d-col-none">
                                  {" "}
                                  Abrir Proposta
                                </span>
                              </Button>
                              {(proposta.propostaStatus === 2 ||
                                proposta.propostaStatus === 7) &&
                                fabricante && (
                                  <Button
                                    className="link col-md-2 col-6"
                                    variant="primary"
                                    onClick={() =>
                                      handleShowModalLinkProposta(proposta)
                                    }
                                    title="Link de acesso do cliente"
                                  >
                                    <i className="fas fa-link"></i>
                                    <span className="d-md-none d-col-none ml-1">
                                      Link
                                    </span>
                                  </Button>
                                )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    );
                  })}
                  <Pagination>{GerarPaginacao()}</Pagination>

                  <DataNotFound
                    visible={
                      tableLoading === false ? tableData?.length === 0 : false
                    }
                  ></DataNotFound>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>

      <Modal
        show={showModalLinkProposta}
        onHide={() => setShowModalLinkProposta(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Copiar link da proposta</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5">
          <div className="mb-3">
            <label className="form-label">Link</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                readOnly
                value={linkProposta}
              />
              <button className="btn" type="button" onClick={handleCopiarLink}>
                {linkCopiado ? "Copiado!" : "Copiar"}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalLinkProposta(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Propostas;

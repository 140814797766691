export function DataNotFound(props) {
  return (
    <>
      {props.visible && (
        <div className="container-xl d-flex flex-column justify-content-center text-center mt-5 mb-5">
          <div className="empty">
            <div className="empty-img mb-3">
              <i className="far fa-grin-beam-sweat text-secondary" style={{ fontSize : 50 }}></i>
            </div>
            <p className="empty-title font-weight-bold">
              Nenhum resultado encontrado
            </p>
            <p className="empty-subtitle text-muted">
              Tente ajustar sua pesquisa ou filtro para encontrar o que procura.
            </p>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

export function NoData(props) {
  return (
    <>
      {props.visible && (
        <div className="container-xl d-flex flex-column justify-content-center text-center mt-5 mb-5">
          <div className="empty">
            <div className="empty-img mb-3">
              <i className="far fa-grin-beam-sweat text-secondary" style={{ fontSize : 50 }}></i>
            </div>
            <p className="empty-title font-weight-bold">
              Não há nada aqui
            </p>
            <div className="empty-subtitle text-muted">
              {props.children ? props.children : "Que tal começar a inserir conteúdo?"}
            </div>
          </div>
        </div>
      )}
    </>
  );
}


// import FloatingLabel from "react-bootstrap/FloatingLabel";
import React, { useRef, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import { Button, Modal } from "react-bootstrap";
import TextArea from "../../../../components/TextArea";
import { Form } from "@unform/web";
import * as Yup from 'yup';
import { SubmitHandler, FormHandles } from "@unform/core";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import api from '../../../../services/api';
import getValidationErrors from '../../../../helpers/getValidateErrors';
import { IListTipologias, IPropostaVersaoAtual } from '../../../../interfaces/proposta.interface';
import Termo from './../../../Admin/Personalizacao/Termo/index';

interface Props {
  onHideModal: () => void;
  botaoSalvarTermo: boolean;
  onEdit:boolean;
  additional?: IPropostaVersaoAtual; 
  dados:IListTipologias;
  idVersao:number;
}

const ModalEditarTermo: React.FC<Props> = ({ onHideModal, botaoSalvarTermo, onEdit, additional, dados, idVersao}) => {
  //Ref
  const formRef = useRef<FormHandles>(null);
  
  //Parâmetros
  const { pId }: any = useParams();
  const { fabricante } = useAuth();

  //Context
  const toastContext = useContext(ToastContext);

  // Effects
  useEffect(() => {
      if(additional?.termo == null){
        formRef.current?.setFieldValue('textoTermo', dados.termo ?  String(dados.termo) : "");   
      }else{
        formRef.current?.setFieldValue('textoTermo', additional?.termo ?  String(additional?.termo) : "");       
      }    
  }, []);

  //Handles
  const handleSubmitEditarTermo: SubmitHandler = async (value) => {
    try {
        formRef.current?.setErrors({});

        const message = "Esse campo não pode ficar vazio.";
        const schema = Yup.object().shape({
          textoTermo: Yup.string().required(message),
        });
        await schema.validate(value, { abortEarly: false });

        //Passou pela validação
        const dadosTermos = {
          propostaId: pId,
          IdPropostaVersao: additional?.id,
          textoTermo: value.textoTermo,
        };

        await api.post("/Propostas/IncluirTermo", dadosTermos);
        toastContext.notification("Termo atualizado.", "success");
        formRef.current?.reset();
        onHideModal();

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      } else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }
};

  return (
    <>
      {/* Modal Termo de Uso*/}
        <Modal.Header closeButton>
          <Modal.Title>{fabricante ? "Editar Termo de Uso | Garantia" : "Termo de Uso | Garantia"} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
        <Form
            ref={formRef} 
            onSubmit={handleSubmitEditarTermo}
          >
          <div className='card-body'>
            <TextArea 
              name="textoTermo"
              label="Termo de Uso | Garantia" 
              placeholder="Digite um texto..."
              className='form-control'
              maxLength={8000}
              disabled={!fabricante || !additional?.ativo}
            />

             {fabricante  &&
                <Button 
                  variant="primary" 
                  className="mt-4"  
                  onClick={() => formRef.current?.submitForm()}
                  disabled={!botaoSalvarTermo || !additional?.ativo}
                >
                  <i className="fa fa-save mr-2"></i>
                  Salvar
                </Button>
            }            
          </div>  
          </Form>
        </Modal.Body>
     
    </>
  );
};

export default ModalEditarTermo;

import React, { createContext, useContext, useEffect, useState } from 'react';

import { ToastContext } from "../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { ITipologia } from "../../interfaces/proposta.interface";
import api from '../../services/api';

interface IMenuLateralContext {
    tipoSelecionado: string,
    setTipoSelecionado: React.Dispatch<React.SetStateAction<string>>,
    idProposta: number,
    setIdProposta: React.Dispatch<React.SetStateAction<number>>,
    versao: number,
    setVersao: React.Dispatch<React.SetStateAction<number>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    tipologia: ITipologia
    setTipologia: React.Dispatch<React.SetStateAction<ITipologia>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    statusProposta: number,
    setStatusProposta: React.Dispatch<React.SetStateAction<number>>,
    reloadLog: boolean,
    setReloadLog: React.Dispatch<React.SetStateAction<boolean>>,
}

const MenuLateralContext = createContext({} as IMenuLateralContext);

const MenuLateralProvider: React.FC = ({ children }) => {

    //Contexts
    const toastContext = useContext(ToastContext);

    //States
    const [tipoSelecionado, setTipoSelecionado] = useState("")
    const [idProposta, setIdProposta] = useState(0)
    const [versao, setVersao] = useState(0)
    const [loading, setLoading] = useState(false)
    const [tipologia, setTipologia] = useState({} as ITipologia)
    const [statusProposta, setStatusProposta] = useState(0)

    const [reload, setReload] = useState(false)
    const [reloadLog, setReloadLog] = useState(false)


    //Effects
    useEffect(() => {
        if (versao != undefined && tipoSelecionado) {
            (async () => {
                try {
                    setLoading(true)
                    const { data } = await api.get(`/Propostas/${idProposta}/versoes/${versao}/itens/${tipoSelecionado}`)
                    setTipologia(data);
                    setLoading(false);
                } catch (error) {
                    const message = getSingleErrorMessage(error);
                    if (message) {
                        toastContext.notification(message, "warn");
                    } else {
                        toastContext.notification(
                            "Ocorreu um erro ao realizar essa operação.",
                            "error"
                        );
                    }
                }
            })()
        }
    }, [reload, tipoSelecionado])

    return (
        <MenuLateralContext.Provider
            value={{
                tipoSelecionado,
                setTipoSelecionado,
                idProposta,
                setIdProposta,
                versao,
                setVersao,
                loading,
                setLoading,
                tipologia,
                setTipologia,
                setReload,
                statusProposta,
                setStatusProposta,
                reloadLog,
                setReloadLog,
            }}>
            {children}
        </MenuLateralContext.Provider>
    );
}

export { MenuLateralProvider, MenuLateralContext };
import React, { useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoPadrao from '../../assets/img/proposta-online-logo.svg';
import { Button, Collapse, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import api from '../../services/api';
import './styles.css';
import {faChartLine} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onClick: (p: object) => void,
  children: string,
  className: string,
  id: string
}
type RefType = any

const Header: React.FC = () => {

  const style = {
    control: (base:any) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none"
    })
  };

  //Auth
  const { user, signOut, fabricante, nivelAcesso, logo } = useAuth();

  const CustomToggle = React.forwardRef<RefType, Props>(({ children, className, id, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      className={className}
      id={id}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleDelayedCollapse = () => {
    setTimeout(() => { setIsNavOpen(false) }, 1000)
  }

  const handleLogout = () => {
    signOut();
    api.post('/Sessoes/Sair');
  }

  return (
    <>

      <div className="header py-4">
        <div className="container">
          <div className="d-flex">
            <Link className="header-brand" to="/">
              <img src={logo ? logo : logoPadrao} className="header-brand-img" alt="Proposta On-line" />
            </Link>
            <div className="d-flex order-lg-2 ml-auto">

              {fabricante ? (
                <Dropdown>
                  <DropdownToggle as={CustomToggle} className="nav-link pr-0 leading-none" id="profile">
                    <span className="avatar avatar-blue">{user?.nome.split(' ').map((v, i) => i < 3 ? (v.charAt(0)) : "").join("").toUpperCase()}</span>
                    <span className="ml-2 d-none d-lg-block">
                      <span className="text-default">{user?.nome}</span>
                      <small className="text-muted d-block mt-1">Minha conta</small>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu align="right" className="dropdown-menu-arrow">
                    <DropdownItem as={Link} to="/perfil">
                      <i className="dropdown-icon fe fe-user"></i>Perfil
                    </DropdownItem>
                    
                    {nivelAcesso === "Administrador" &&
                      <>
                        <DropdownItem as={Link} to="/usuarios">
                          <i className="dropdown-icon fe fe-user-plus"></i>Usuário
                        </DropdownItem>
                        <DropdownItem as={Link} to="/personalizacao">
                          <i className="dropdown-icon fe fe-grid"></i>Personalização
                        </DropdownItem>
                        <DropdownItem as={Link} to="/opcoes">
                          <i className="dropdown-icon fe fe-package"></i>Opções de Montagem
                        </DropdownItem>
                        <DropdownItem as={Link} to="/imagens">
                          <i className="dropdown-icon fe fe-image"></i>Imagens
                        </DropdownItem>
                        <DropdownItem as={Link} to="/videos">
                          <i className="dropdown-icon fe fe-video"></i>Vídeos
                        </DropdownItem>
                      </>
                    }
                    <DropdownItem as={Link} to="#" onClick={handleLogout}>
                      <i className="dropdown-icon fe fe-log-out"></i>Sair
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Dropdown>
                  <div className="nav pr-0 leading-none">
                    <DropdownToggle as={CustomToggle} className="nav-link pr-0 leading-none" id="profile">
                      <span className="avatar avatar-blue">{user?.nome.split(' ').map((v, i) => i < 3 ? (v.charAt(0)) : "").join("").toUpperCase()}</span>
                      <span className="ml-2 d-none d-lg-block mt-2">
                        <span className="text-default">{user?.nome}</span>
                      </span>
                      <DropdownMenu align="right" className="dropdown-menu-arrow">
                        <DropdownItem as={Link} to="#" onClick={handleLogout}>
                        <i className="dropdown-icon fe fe-log-out"></i>Sair
                      </DropdownItem>

                      </DropdownMenu>

                    </DropdownToggle>
                  </div>

                </Dropdown>
              )}


            </div>
            <Button variant="ghost-dark" className="header-toggler d-lg-none ml-3 ml-lg-0" aria-controls="headerMenuCollapse" aria-expanded={isNavOpen} onClick={() => setIsNavOpen(!isNavOpen)}>
              <span className="header-toggler-icon"></span>
            </Button>
          </div> 
        </div>
      </div>

      <Collapse in={isNavOpen} >
        <div className="navbar-collapse header d-lg-flex p-0" id="headerMenuCollapse">
          <div className="container" >
            <div className="row align-items-center">
              <div className="col-lg order-lg-first">
                <ul className="nav nav-tabs border-0 flex-column flex-lg-row">

                  {fabricante?(
                    <>
                      {(nivelAcesso === "Administrador" || nivelAcesso === "Supervisor") && 
                      <div className='d-flex'>
                        <li className="nav-item">
                          <NavLink activeClassName='active' to="/empresa" className="nav-link" onClick={() => setIsNavOpen(false)}><FontAwesomeIcon icon={faBuilding} /> Empresa</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink activeClassName='active' to="/dashboard" className="nav-link" onClick={() => setIsNavOpen(false)}><FontAwesomeIcon icon={faChartLine} /> Dashboard</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink activeClassName='active' to='/propostas' className="nav-link" onClick={() => setIsNavOpen(false)}><i className="fe fe-file"></i> Propostas</NavLink>
                        </li>
                      </div>}
                    </>
                    ):(
                      <>
                        <div className='d-flex'>
                          <li className="nav-item">
                            <NavLink activeClassName='active' to="/empresa" className="nav-link" onClick={() => setIsNavOpen(true)} style={{margin:'5px'}}><FontAwesomeIcon icon={faBuilding} className='mr-2'/>Empresa</NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink activeClassName='active' to='/propostas' className="nav-link" onClick={() => setIsNavOpen(true)}><i className="fe fe-file mr-2"></i>Propostas</NavLink>
                          </li>
                        </div>

                      </>)}
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </>


  );
}

export default Header;
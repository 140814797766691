import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FileButton from '../../../components/FileButton';
import logoPadrao from '../../../assets/img/proposta-online-logo.svg';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors"

import Termo from './Termo';
import Empresa from './Empresa';
import Projetos from './Projetos';
import Contato from './Contato';
import Fornecedores from './Fornecedores';
import Produtos from './Produtos';
import api from '../../../services/api';
import { getSingleErrorMessage } from '../../../helpers/getSingleErrorMessage';
import { ToastContext } from '../../../contexts/toast/toastContext';
import LoadingBlock from '../../../components/LoadingBlock';
import { useAuth } from '../../../hooks/auth';
import { VincularFornecedoresProvider } from '../../../contexts/vincularFornecedores/vincularFornecedoresContext';

const Personalizacao: React.FC = () => {

  //Auth
  const { alterLogoFabricante } = useAuth();

  //Ref
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);

  //States
  const [novoLogo, setNovoLogo] = useState(false);
  const [logo, setLogo] = useState();
  const [imagesLoading, setImagesLoading] = useState(false);

  //Handles
  const handleUploadLogo = (e: any) => {
    if (e.target.files?.length != 0) {
      formRef.current?.submitForm();
    }
  }

  const handleSubmit: SubmitHandler<any> = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

      const schema = Yup.object().shape({

        logo: Yup.mixed()
          .test('fileSize', "Arquivo deve ser menor que 2MB.", value => value.size <= 2000000)
          .test(
            "fileFormat",
            "O arquivo deve ser .jpg, .jpeg ou .png ",
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
      });
      await schema.validate(dados, { abortEarly: false });

      // Passou pela validação
      if (dados.logo != null) {
        setImagesLoading(true);
        var arquivo = new FormData();
        arquivo.append("logo", dados.logo);

        await api.put("/Sessoes/AlterarLogo", arquivo);
        setNovoLogo(!novoLogo);
      }

    } catch (err: any) {
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDeleteLogo = () => {
    setNovoLogo(false);
  }

  useEffect(() => {
    (async () => {
      try {
        setImagesLoading(true);
        const { data } = await api.put("/Sessoes/ObterLogo");
        if (data.url) {
          setLogo(data.url);
          alterLogoFabricante(data.url);
        }
      } catch (err) {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
      finally {
        setImagesLoading(false);
      }
    })()
  }, [novoLogo])


  return (
    <>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="page-header">
            <h1 className="page-title">Personalização</h1>
          </div>
        </div>
        <Container>
          <Row>
            <Col>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Logo</h3>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={8} lg={6} className="mx-auto">
                      <div className="form-label">Logo atual:</div>
                      <div className="card">
                        <div className="card-body text-center">
                          <LoadingBlock loading={imagesLoading}>
                            <img src={logo ? logo : logoPadrao} alt="Proposta On-line" />
                          </LoadingBlock>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="alert alert-warning text-center" role="alert">
                    Atenção: Utilize imagem no formato horizontal, com fundo branco, sendo .jpg, .jpeg ou .png e preferencialmente nas seguintes dimensões 140x40.
                  </div>
                </div>
                <div className="card-footer text-right">
                  <Form ref={formRef} onSubmit={handleSubmit} >
                    <FileButton name="logo" onChange={handleUploadLogo}><i className="fe fe-upload mr-2"></i>Subir Novo Logo</FileButton>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Termo />
            </Col>
          </Row>
          <Row>
            <Col>
              <Empresa />
            </Col>
          </Row>
          <Row>
            <Col>
              <Projetos />
            </Col>
          </Row>
          <Row>
            <Col>
              <Contato />
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}

export default Personalizacao;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Modal, Pagination } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import api from '../../../services/api';
import Input from '../../../components/Input';
import InputFile from '../../../components/InputFile';
import { NoData } from '../../../components/DataNotFound';

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors"

import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { ToastContext } from '../../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../../helpers/getSingleErrorMessage';

import './styles.css';
import { useAuth } from '../../../hooks/auth';

interface Videos {
    id: number,
    descricao: string,
    url: string
}

const Videos: React.FC = () => {

    //Auth
    const { user } = useAuth();

    //Ref
    const formRefArquivo = useRef<FormHandles>(null);
    const formRefURL = useRef<FormHandles>(null);

    //Context
    const sweetAlert = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);

    //States
    const [show, setShow] = useState(false);
    const [videosLoading, setVideosLoading] = useState(false)
    const [videos, setVideos] = useState<Videos[]>([]);

    const [reload, setReload] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [uploadProgress, setUploadProgress] = useState(0);


    //Constants
    const limit = 6;

    //Effects
    useEffect(() => {
        (async () => {
            try {
                setVideosLoading(true)
                const { data } = await api.get(`/galeriaVideos?pagina=${pageCount}&limite=${limit}`);
                setVideos(data.items)
                setTotalPages(data.totalPages);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            } finally {
                setVideosLoading(false)
            }
        })()
    }, [reload, pageCount])

    //Handles
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmitArquivo: SubmitHandler<any> = async (dados) => {
        try {
            formRefArquivo.current?.setErrors({});

            const SUPPORTED_FORMATS = ['video/mp4', 'video/mpeg'];

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                descricao: Yup.string().required(message),
                arquivo: Yup.mixed()
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .mp4 ou .mpeg",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
                    .test('fileSize', "O arquivo não pode ter mais do que 50MB", value => value.size <= (1024 * 1024) * 50)
            });
            await schema.validate(dados, { abortEarly: false });

            //Passou pela validação
            const upload = new FormData();
            upload.append("descricao", dados.descricao);
            upload.append("arquivo", dados.arquivo);

            const options = {
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const { loaded, total } = progressEvent;
                    setUploadProgress(Math.floor((loaded * 100 / total)))
                }
            }

            await api.post("/GaleriaVideos/AdicionarArquivo", upload, options);
            setUploadProgress(0);
            toastContext.notification("Vídeo adicionado.", "success");
            setReload(prevState => !prevState);
            handleClose();


        } catch (err: any) {
            setUploadProgress(0);
            if (err instanceof Yup.ValidationError) {
                formRefArquivo.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    }
    const handleSubmitURL: SubmitHandler<any> = async (dados) => {
        try {
            formRefURL.current?.setErrors({});

            const message = "Esse campo não pode ficar vazio.";
            const schema = Yup.object().shape({
                descricao: Yup.string().required(message),
                url: Yup.string()
                    .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Você deve usar uma URL válida.')
                    .required(message)
            });

            await schema.validate(dados, { abortEarly: false });

            await api.post("/GaleriaVideos/AdicionarUrl", dados);
            toastContext.notification("Vídeo adicionado.", "success");
            setReload(prevState => !prevState);
            handleClose();

        } catch (err: any) {
            if (err instanceof Yup.ValidationError) {
                formRefURL.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        }
    }

    const handleDelete = ({ id, descricao }: Videos) => {
        const titleAlert = `Excluir "${descricao}"? `;
        const message = `Após a confirmação isso não pode ser desfeito.`;
        sweetAlert.danger(titleAlert, message, async () => {
            try {
                sweetAlert.close();
                setVideosLoading(true);
                await api.delete(`galeriaVideos/${id}`);
                setVideos((prevState) =>
                    prevState.filter(function (item) {
                        return item.id !== id;
                    })
                );
                setReload(!reload);
            } catch (error) {
                const message = getSingleErrorMessage(error);
                if (message) {
                    toastContext.notification(message, "warn");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        });
    };

    return (
        <>
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="page-header">
                        <h1 className="page-title">Vídeos</h1>
                    </div>
                </div>
                <div className="container px-5">
                    <div className="row">
                        <div className="card">
                            <div className="card-header p-5">
                                <Row className="ml-auto">
                                    <Col>
                                        <Button title="Upload de Vídeo" onClick={handleShow}>
                                            <i className="fe fe-film"></i><span className="ml-2">Adicionar Vídeo</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-body">
                                <Row>
                                    {videos.map((video, index) => (
                                        <Col sm={6} lg={4} key={index}>
                                            <div className="card">
                                                <div className="player-wrapper">
                                                    <ReactPlayer
                                                        // light
                                                        controls
                                                        playsinline
                                                        className='react-player'
                                                        width='100%'
                                                        height='100%'
                                                        url={video.url}
                                                    />
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <div>
                                                                <h6 className="mb-0">Título do Vídeo</h6>
                                                            </div>
                                                            <div>{video.descricao}</div>
                                                        </div>
                                                        <div>
                                                            <Button variant="outline-danger" title="Excluir" onClick={() => handleDelete(video)}><i className="fe fe-trash"></i></Button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                    <NoData visible={videosLoading === false ? videos?.length === 0 : false}>
                                        Que tal inserir alguns vídeos?
                                    </NoData>
                                </Row>
                                {videos.length != 0 &&
                                    <Row className="d-flex flex-row align-items-center">
                                        <Col md="auto">
                                            <Pagination className="pt-2">
                                                <Pagination.First
                                                    onClick={() => setPageCount(1)}
                                                    disabled={pageCount <= 1}
                                                />
                                                <Pagination.Prev
                                                    onClick={() => setPageCount(pageCount - 1)}
                                                    disabled={pageCount <= 1}
                                                />
                                                <Pagination.Next
                                                    onClick={() => setPageCount(pageCount + 1)}
                                                    disabled={totalPages == 1 || pageCount == totalPages}
                                                />
                                                <Pagination.Last
                                                    onClick={() => setPageCount(totalPages)}
                                                    disabled={totalPages == 1 || pageCount == totalPages}
                                                />
                                            </Pagination>
                                        </Col>
                                        <Col md="auto">
                                            <span>
                                                Página{" "}
                                                <strong>
                                                    {pageCount} de {totalPages}
                                                </strong>{" "}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Upload Videos */}
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header className="px-5" closeButton>
                    <Modal.Title>Adicionar Vídeo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className={'dimmer' + (uploadProgress != 0 ? ' active' : '')}>
                        <div className="mx-5 progresso">
                            <h1 className="font-weight-light text-center">{uploadProgress}<span className="ml-1 h4">%</span></h1>
                            <div className="progress progress-sm">
                                <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }}></div>
                            </div>
                        </div>
                        <div className={'dimmer-content' + (uploadProgress != 0 ? ' medium-box-loader' : '')}>
                            <h5 className="text-center mb-5">Como você quer adicionar seu vídeo?</h5>
                            <Row>
                                <Col lg={6}>
                                    <Form ref={formRefArquivo} onSubmit={handleSubmitArquivo}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Do meu dispositivo</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="">
                                                    <div className="d-flex align-items-center flex-column">
                                                        <div className="mb-4">
                                                            <span
                                                                className="bg-cyan text-white text-uppercase btn btn-icon"
                                                                style={{ width: "42px", height: "40px", lineHeight: "1.7rem", fontSize: "0.7rem", cursor: "initial" }}
                                                            >
                                                                <strong>.mp4</strong>
                                                            </span>
                                                        </div>
                                                        <div className="w-100 form-group">
                                                            <Input
                                                                type="text"
                                                                name="descricao"
                                                                className="form-control"
                                                                placeholder="Insira um título para o vídeo..."
                                                                label="Título do Vídeo"
                                                            />
                                                        </div>
                                                        <div className="w-100 form-group">
                                                            <InputFile name="arquivo" label="Nome do Arquivo" />
                                                            <small className='pt-1'>Tamanho máximo para o arquivo <strong>{user?.videoTamanhoMaximoEnvioEmMB} MB</strong></small>
                                                            <br/><small className='pt-1'>O nome do arquivo não pode ter <strong>caracteres especiais ou símbolos</strong></small>
                                                        </div>
                                                        <Button variant="primary" className="mt-1" onClick={() => formRefArquivo.current?.submitForm()}>
                                                            <i className="fe fe-upload-cloud"></i><span className="ml-2">Upload</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                                <Col lg={6}>
                                    <Form ref={formRefURL} onSubmit={handleSubmitURL}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">De um site de vídeos</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center flex-column">
                                                    <div className="mb-4">
                                                        <span
                                                            title="URL do YouTube"
                                                            className="bg-red text-white btn btn-icon mx-2"
                                                            style={{ cursor: "initial" }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="3" y="5" width="18" height="14" rx="4"></rect><path d="M10 9l5 3l-5 3z"></path></svg>
                                                        </span>
                                                        <span
                                                            title="URL do Vimeo"
                                                            className="btn-vimeo text-white btn btn-icon mx-2"
                                                            style={{ cursor: "initial" }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 8.5l1 1s1.5 -1.102 2 -.5c.509 .609 1.863 7.65 2.5 9c.556 1.184 1.978 2.89 4 1.5c2 -1.5 7.5 -5.5 8.5 -11.5c.444 -2.661 -1 -4 -2.5 -4c-2 0 -4.047 1.202 -4.5 4c2.05 -1.254 2.551 1.003 1.5 3c-1.052 2.005 -2 3 -2.5 3c-.49 0 -.924 -1.165 -1.5 -3.5c-.59 -2.42 -.5 -6.5 -3 -6.5s-5.5 4.5 -5.5 4.5z"></path></svg>
                                                        </span>
                                                    </div>
                                                    <div className="w-100 form-group">
                                                        <Input
                                                            type="text"
                                                            name="descricao"
                                                            className="form-control"
                                                            placeholder="Insira um título para o vídeo..."
                                                            label="Título do Vídeo"
                                                        />
                                                    </div>
                                                    <div className="w-100 form-group">
                                                        <Input
                                                            type="text"
                                                            name="url"
                                                            className="form-control"
                                                            placeholder="Insira a URL do YouTube, Vimeo, etc..."
                                                            label="URL do Vídeo"
                                                        />
                                                        <br/> <br/><br/>
                                                    </div>
                                                    <Button variant="primary" className="mt-1" onClick={() => formRefURL.current?.submitForm()}>
                                                        <i className="fe fe-link-2"></i><span className="ml-2">Adicionar URL</span>
                                                    </Button>

                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Videos;
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import logo from '../../assets/img/proposta-online-logo.svg';

import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';

interface SignInClientesParams {
    pCodigo: string
}

const SignInClientes: React.FC = () => {
    //Parâmetros
    const { pCodigo } = useParams<SignInClientesParams>();

    //Hook
    const { SignInCliente } = useAuth();

    //State
    const [loading, setLoading] = useState(true)
    const [erro, setErro] = useState("")
    const History = useHistory();

    //Effect
    useEffect(() => {
        setTimeout(async () => {
            try {
                await SignInCliente(pCodigo);
                History.push('/empresa');
            } catch (err) {
                const message = getSingleErrorMessage(err);
                setLoading(false);
                if (message) {
                    setErro(message)
                } else {
                    setErro("Ocorreu um erro ao realizar essa operação.")
                }
            }
        }, 2000);
    }, [])

    return (
        <div className="page img-background-login">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="card">
                                <div className="card-body p-6">
                                    <div className="text-center mb-6">
                                        <img src={logo} className="h-7" alt="" />
                                    </div>
                                    <div className="card-title text-center">{loading ? "Aguarde" : "O link desta proposta expirou!"}</div>
                                    {loading ? (
                                        <p className="text-muted text-center">Estamos realizando a validação de seus dados para acessar o sistema.</p>
                                    ) : (
                                        <p className="text-muted text-center">{erro}</p>
                                    )
                                    }
                                    {loading &&
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-indeterminate bg-primary"></div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInClientes;
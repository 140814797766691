import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ModelViewer } from "../../../../components/ModelViewer";
import { ITipologia } from "../../../../interfaces/proposta.interface";
import { Vidros } from "../Vidros";
import { IOptionsString } from "../../../../interfaces/options.interface";
import './styles.css'

interface IPropsTipologia {
    tipologia: ITipologia,
    vidros: IOptionsString[]
}

    const DadosOriginais: React.FC<IPropsTipologia> = ({tipologia, vidros}) => {
    const [area3D, setArea3D] = useState('');

    return(<>
        <Row className="cod d-md-flex" >
            <div className="col-12 col-md-12">
                <label><b>Cor:</b>{tipologia.cor.codigo}</label>
            </div>
        </Row>
        <Row className="areaEsq">
            <div id='glass' className="col-12 col-md-3">
                <label><b>Área Esquadria:</b>{tipologia.areaEsquadria?.toFixed(2)}</label>
            </div>
        </Row>
        {tipologia.vidros && (
        <Row className="vidro">
            <div id='glass' className="col-12 col-md-12 d-flex">
                <Vidros tipologia={tipologia} vidros={vidros}/>
            </div>
        </Row>)}
      
        <Row className="local">
            <div className="col-12">
                <label><b>Localização:</b>{tipologia.localizacao}</label>
            </div>
        </Row>
        <Row className="linhaValor">
            <div id='value' className="col-12 col-md-12"> 
            
                <div className="linha col-12 col-md-auto">
                    <div className="line col-auto">
                        <label><b>Linha:</b>{tipologia.linhaTipologia}</label>
                    </div>
                    
                    <div className="col-auto">
                        <label><b>Qtd:</b>{tipologia.quantidade}</label>
                        <label className="larg"><b>L:</b>{tipologia.largura}</label>
                        <label><b>H:</b>{tipologia.altura}</label>
                    </div>
                </div>
                <Col className="cardValor col-md-5">
                    <Card className="valorUn">
                        <label className="mt-2"><b>Valor (un):</b></label>
                        <label>{`R$ ${(tipologia.valor/tipologia.quantidade)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}</label>
                    </Card>
                    <Card className="valorTotal text-center">
                        <label className="mt-2"><b>Valor Total:</b></label>
                        <label>{`R$ ${tipologia.valor?.toLocaleString('pt-br',{minimumFractionDigits:2})}`}</label>
                    </Card>
                </Col>

                {tipologia.esquadriaUrl3D && 
                    <Col  className="col-12 col-md-auto m-0">
                        <>
                        {area3D === "" ? (
                            <Button
                            onClick={() => setArea3D("3D")}
                            disabled={!tipologia.esquadriaUrl3D}
                            className="col-12 col-md-auto"
                            variant="outline-secondary" 
                            type="Ver modelo 3D da esquadria">
                            <i className="fe fe-box mr-2"></i>Visualize em 3D</Button>
                            ):(
                            <Button
                            onClick={() => setArea3D("")}
                            disabled={!tipologia.esquadriaUrl3D}
                            className="col-12 col-md-auto"
                            variant="outline-secondary" 
                            type="Ver modelo 3D da esquadria">
                            <i className="fe fe-box mr-2"></i>Fechar 3D</Button>
                        )}
                        </>
                    </Col>
                }
            </div> 
        </Row>
        {area3D === '3D'? (
                <div style={{borderTop: '1px solid #e0e0e0', marginTop:20, borderBottom: '1px solid #e0e0e0', marginBottom:20}}>
                    <Col className="area-3D mt-3">
                        <ModelViewer esquadriaUrl3D={tipologia.esquadriaUrl3D}/>
                    </Col>
                </div>
            ):(<></>)}
    </>)
}

export default DadosOriginais;

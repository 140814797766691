import React, { useContext } from 'react'
import { ModelViewer } from '../../../components/ModelViewer'
import { Row, Col, Button } from 'react-bootstrap'
import { ColunaLateral } from '../ColunaLateral'
import { IOptionsString } from '../../../interfaces/options.interface'
import { MenuLateralContext } from '../../../contexts/menuLateral/menuLateralContext'

interface IPropsVisualizador3D {
  setAreaAtiva: React.Dispatch<React.SetStateAction<string>>,
  editavel : boolean,
  coresPerfis: IOptionsString[],
  vidros:IOptionsString[]
}

const Visualizador3D: React.FC<IPropsVisualizador3D> = ({ setAreaAtiva, editavel, coresPerfis, vidros }) => {

const menuLateralContext = useContext(MenuLateralContext);
  return (
    <>
      <hr />

      <Row>
        <Col md={12} lg>
          <Row className="mb-3">
            <Col>
              <h4>Modelo 3D:</h4>
            </Col>
            <Col xs="auto" className="ml-auto">
              <Button
                onClick={() => setAreaAtiva('plantaBaixa')}
                variant="outline-secondary"
                title='Ativar planta baixa'
                className="toggle-lateral"
              >
                <i className="fe fe-x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="area-3D mx-3 p-0">
              <ModelViewer esquadriaUrl3D={menuLateralContext.tipologia.esquadriaUrl3D} />
            </Col>
          </Row>
        </Col>
        <div className="vr"></div>
        <ColunaLateral setAreaAtiva={setAreaAtiva} editavel={editavel} coresPerfis={coresPerfis} vidros={vidros}/>
      </Row>
    </>
  )
}

export { Visualizador3D }

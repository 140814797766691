import React from "react";

const TableBodyRow = ({ row }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className={`${cell.column.display}` !== 'undefined' ? `${cell.column.display}` : ''} data-label={`${cell.column.label}` !== 'undefined' ? `${cell.column.label}` : ''} >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

const TypeTableBody = ({ getTableBodyProps, page, prepareRow }) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        return <TableBodyRow row={row} key={row.index} />;
      })}
    </tbody>
  );
};

export default TypeTableBody;

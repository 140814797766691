import React, { useEffect, useRef, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    id: string;    
    label?: string;
}

const SwitchCheckbox: React.FC<Props> = ({ name, id, label, defaultChecked, style, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, registerField, defaultValue } = useField(name);
    
    useEffect(() => {

        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref: HTMLInputElement) => {
                return ref.checked;
            },
            clearValue: (ref: HTMLInputElement) => {
                ref.checked = false;
            },
            setValue: (ref: HTMLInputElement, value: boolean) => {               
                ref.checked = value;
            }
        });

    }, [fieldName, registerField]);

    return (

        <label className="custom-switch" style={style} htmlFor={id} key={id}>
            <input
                defaultChecked={defaultChecked}
                ref={inputRef}
                type="checkbox"
                id={id}
                className="custom-switch-input"
                {...rest}
            />
            <span className="custom-switch-indicator"></span>
            <span className="custom-switch-description">{label}</span>
        </label>

    );
};

export default SwitchCheckbox;

import React from 'react';
import {
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps,
    Redirect
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Private from '../layouts/Private';
import Public from '../layouts/Public';

interface RouteProps extends ReactDOMRouteProps {
    isAdm?: boolean;
    isPrivate?: boolean;
    isClienteFinal?: boolean;
    component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, isClienteFinal = false, isAdm = false, component: Component, ...rest }) => {

    const { signed, fabricante, nivelAcesso } = useAuth();

    const Layout = isPrivate ? Private : Public;

    return (
        <ReactDOMRoute
            {...rest}
            render={() => {
                //Está logado e a rota que quer acessar é privada?
                return isPrivate === signed ? (
                    <>
                        {/* É fabricante? */}
                        {fabricante ?

                            // É vendedor?
                            nivelAcesso !== "Vendedor" ? (
                                <Layout>
                                    <Component />
                                </Layout>
                            ) : (

                                // Se é vendedor, está tentando acessar uma rota só para Adm e Supervisor?
                                isAdm ? (
                                    <Redirect to={{ pathname: isPrivate ? '/propostas' : '/' }} />
                                ) : (
                                    <Layout>
                                        <Component />
                                    </Layout>
                                )
                            )
                            :

                            // Se não é fabricante está tentando acessar uma rota permitida para Cliente Final?
                            !fabricante === isClienteFinal ?
                                <Layout>
                                    <Component />
                                </Layout>
                                :
                                <Redirect to={{ pathname: isPrivate ? '/' : '/empresa' }} />
                        }
                    </>
                ) : (
                    <>
                        {fabricante ?
                            <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
                            :
                            <Redirect to={{ pathname: isPrivate ? '/' : '/propostas' }} />
                        }
                    </>
                )
            }} />
    );
}

export default Route;
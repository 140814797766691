import React, {
    ChangeEvent,
    useRef,
    useEffect,
    useCallback,
    useState,
} from 'react';

import { useField } from '@unform/core';
import { Button, ButtonProps } from 'react-bootstrap';

interface Props {
    name: string;
    children?: any;
    variant?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function FileButton({ name, onBlur, children, variant, className, ...rest }: InputProps) {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, defaultValue, error } = useField(name);

    const [file, setFile] = useState<any>('');

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
        } else {
            setFile('');
        }
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
                setFile('');
            },
            setValue(_: HTMLInputElement, value: string) {
                setFile(value);
            },
        });
    }, [fieldName, registerField]);




    return (
        <>
            <Button variant={variant} onClick={handleClick} className={className}>
                {children}
            </Button>
            <input type="file" ref={inputRef} onChange={handleUpload} style={{ display: 'none' }} {...rest} />
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};
import {
    ChangeEvent,
    useRef,
    useEffect,
    useCallback,
    useState,
} from 'react';
import { useField } from '@unform/core';

interface Props {
    name: string;
    label?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function InputFile({ name, label, ...rest }: InputProps) {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, defaultValue, error } = useField(name);
    
    const [file, setFile] = useState(defaultValue);
    
    const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
        } else {
            setFile('');
        }
    }, []);
    
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
                setFile('');
            },
            setValue(_: HTMLInputElement, value: string) {
                setFile(value);
            },
        });
    }, [fieldName, registerField]);
    return (
        <>          
            {label && <label className="form-label">{label}</label>}
            <div className="custom custom-file">
                <input type="file" className="custom-file-input" ref={inputRef} onChange={handleUpload} {...rest}/>
                <label data-browse="Procurar" htmlFor="custom-file" className="custom-file-label" style={file ? { zIndex: 0 } : { zIndex: 0, color: "#adb5bd" }}>
                    {file ? file.name : "Nenhum arquivo selecionado..."}
                </label>
            </div>
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};
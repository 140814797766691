import React, { useState, useEffect, useRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core'

interface Props {
    name: string;
    className?: string;
    placeholder: string;
}

const PhoneInput = ({ name, className, ...rest }: Props) => {
    const [mask, setMask] = useState("(99) 99999-9999");

    const inputRef = useRef(null)

    const { fieldName, defaultValue, registerField, error, clearError } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
            setValue(ref: any, value: string) {
                if(value){
                    if (value.length <= 14) {
                        setMask("(99) 9999-9999");
                    } else {
                        setMask("(99) 99999-9999");
                    }
                    ref.setInputValue(value);
                }
            },
            clearValue(ref: any) {
                ref.setInputValue("");
            },
        });

    }, [fieldName, registerField]);


    return (
        <>
            <ReactInputMask
                id={fieldName}
                ref={inputRef}
                mask={mask}
                type="tel"
                className="form-control"
                onBlur={e => {
                    if (e.target.value.replace("_", "").length === 14) {
                        setMask("(99) 9999-9999");
                    }
                }}
                onFocus={e => {
                    clearError();
                    if (e.target.value.replace("_", "").length === 14) {
                        setMask("(99) 99999-9999");
                    }
                }}
                {...rest}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};

export default PhoneInput;

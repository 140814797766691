import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Input from '../../components/Input';
import logo from '../../assets/img/proposta-online-logo.svg';
import api from '../../services/api';

import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../helpers/getValidateErrors';

import { ToastContext } from '../../contexts/toast/toastContext';
import { getSingleErrorMessage } from '../../helpers/getSingleErrorMessage';

import './styles.css';

interface FormData {
    senha: string,
    confirmarSenha: string,
    codigoRedefinirSenha: string,
}

interface IVerSenha {
    novaSenha: boolean,
    confirmarNovaSenha: boolean
}

interface IParams {
    pCodigo: string,
}

const NovaSenha: React.FC = () => {

    // URL Params
    const { pCodigo } = useParams<IParams>();

    //Ref
    const formRef = useRef<FormHandles>(null)

    //History
    const history = useHistory();

    //Context
    const toastContext = useContext(ToastContext);

    //States
    const [loading, setLoading] = useState(false);
    const [verSenha, setVerSenha] = useState<IVerSenha>({ novaSenha: false, confirmarNovaSenha: false });

    //Handles
    const handleSubmit: SubmitHandler<FormData> = async (data) => {

        //Validação
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                codigoAtivarConta: Yup.string()
                    .required("Código ativação é obrigatório"),
                senha: Yup.string()
                    .matches(
                        /^(?=.*?[a-z])/,
                        "A senha precisa ter pelo menos uma letra minúscula."
                    )
                    .matches(
                        /^(?=.*?[A-Z])/,
                        "A senha precisa ter pelo menos uma letra maiúscula."
                    )
                    .matches(
                        /^(?=.*?[0-9])/,
                        "A senha precisa ter pelo menos um número."
                    )
                    .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
                    .max(12,"Senha deve ter no máximo 12 caracteres")
                    .required('A senha é obrigatória.'),
                confirmarSenha: Yup.string()
                    .oneOf([Yup.ref("senha"), null], "Senha não confere. Digite a mesma senha nos dois campos.")
                    .required('A senha é obrigatória.'),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            //Validação passou
            setLoading(true);
            data.codigoRedefinirSenha = pCodigo;
            await api.put("/autenticacao/AtivarConta", data)
            toastContext.notification("Sua conta foi ativada. Realize o login com a senha informada.", "success");
            history.push("/")

        } catch (err) {

            if (err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                const message = getSingleErrorMessage(err);
                if (message) {
                    toastContext.notification(message, "error");
                } else {
                    toastContext.notification(
                        "Ocorreu um erro ao realizar essa operação.",
                        "error"
                    );
                }
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="page img-background-login">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <Form className="card" ref={formRef} onSubmit={handleSubmit}>
                                <div className="card-body p-6">
                                    <div className="text-center mb-6">
                                        <img src={logo} className="h-7" alt="" />
                                    </div>
                                    <div className="card-title">Código de Ativação</div>
                                    <p className="text-muted">Vamos ativar sua conta para ter acesso ao sistema.</p>
                                    <div className="form-group">
                                        <Input type="text" name="codigoAtivarConta" label="Código de Ativação" placeholder="Digite o código de ativação..." maxLength={6}>
                                            <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                                            </span>
                                        </Input>
                                        <small>Informe o código que você recebeu por e-mail</small>
                                    </div>
                                    <div className="form-group">
                                        <Input type={verSenha.novaSenha ? "text" : "password"} name="senha" label="Nova Senha" placeholder="Digite uma nova senha...">
                                            <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                                                <i className={verSenha.novaSenha ? "fe fe-eye" : "fe fe-eye-off"}
                                                    title={verSenha.novaSenha ? "Esconder Senha" : "Ver Senha"}
                                                    onClick={() => setVerSenha({ ...verSenha, novaSenha: !verSenha.novaSenha })}
                                                ></i>
                                            </span>
                                        </Input>
                                    </div>
                                    <div className="form-group">
                                        <Input type={verSenha.confirmarNovaSenha ? "text" : "password"} name="confirmarSenha" label="Confirmar Nova Senha" placeholder="Digite novamente a nova senha...">
                                            <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                                                <i className={verSenha.confirmarNovaSenha ? "fe fe-eye" : "fe fe-eye-off"}
                                                    title={verSenha.confirmarNovaSenha ? "Esconder Senha" : "Ver Senha"}
                                                    onClick={() => setVerSenha({ ...verSenha, confirmarNovaSenha: !verSenha.confirmarNovaSenha })}
                                                ></i>
                                            </span>
                                        </Input>
                                    </div>
                                    <div className="text-muted password-tips"> <p>Sua senha precisa ter:</p>
                                        <ul className="ml-0">
                                        <li>Mínimo de 6 caracteres</li>
                                            <li>Máximo de 12 caracteres</li>
                                            <li>Uma letra: maiúscula e minúscula</li>
                                            <li>Um número</li>
                                        </ul>
                                    </div>
                                    <div className="form-footer">
                                        <Button type="submit" className="btn-block" disabled={loading}>{loading ? "Ativando Conta..." : "Ativar sua Conta"}</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovaSenha;
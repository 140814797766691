import React from "react";
import { Row } from "react-bootstrap";
import { useAuth } from "../../../../hooks/auth";
import Select from "../../../../components/Select";
import { Scope } from "@unform/core";

import { ITipologia } from "../../../../interfaces/proposta.interface";
import { IOptionsString } from "../../../../interfaces/options.interface";

interface IPropsVidros {   
    tipologia: ITipologia;
    status: number;
    vidros:IOptionsString[];
    editavel: boolean;
    onSelecao: (e: React.ChangeEvent, localizacao:string, index:number) => void;

}
const SelectVidros: React.FC<IPropsVidros> = ({ tipologia, status, vidros, editavel, onSelecao }) => {
    //Auth
    const { fabricante } = useAuth();

    if (tipologia.possuiLogAlteracao) {
        return (
            <>
                <Row className="mt-0 mt-lg-3">
                    
                    {tipologia.propostaItemLog?.vidros.map((vidro, index) => (
                        <Scope key={index} path={`vidros[${index}]`}>
                            <div className={`mt-3 mt-lg-0 ${tipologia.propostaItemLog.vidros.length <= 3 ? "col" : "col-12 col-sm-6 col-md-12 col-lg-4 mb-lg-3 mb-0" }`}>
                                <label className="form-label">{vidro.vidro}</label>
                                {tipologia.vidros && (
                                <Select
                                    name="vidro"
                                    options={vidros}
                                    className={vidro.vidro !== tipologia.vidros[index]?.vidro ? "input-modificado" : ""}
                                    placeholder="Vidro..."
                                    defaultValue={{ label: vidro.descricao, value: vidro.vidro  }}
                                    onChange={(e) => onSelecao(e, vidro.vidro, index)}
                                    isDisabled={fabricante || tipologia.removido || status >= 3 || !editavel}
                                />)}
                            </div>
                        </Scope>
                    ))}
                </Row>
            </>
        );
    } else {
        return (
            <>
               {tipologia.vidros && ( 
               <Row className="mt-0 mt-lg-3">
                    {tipologia.vidros.map((vidro, index) => (
                        <Scope key={index} path={`vidros[${index}]`}>
                            {tipologia.vidros && (
                            <div className={`mt-3 mt-lg-0 ${tipologia.vidros.length <= 3 ? "col" : "col-12 col-sm-6 col-md-12 col-lg-4 mb-lg-3 mb-0" }`}>
                                <label className="form-label">{vidro.localizacao}</label>
                                <Select
                                    name="vidro"
                                    options={vidros}
                                    className={vidro.vidro !== tipologia.vidros[index].vidro ? "input-modificado" : ""}
                                    placeholder="Vidro..."
                                    onChange={(e) => onSelecao(e, vidro.localizacao, index)}
                                    defaultValue={{ label: vidro.descricao, value: vidro.vidro }}
                                    isDisabled={fabricante || tipologia.removido || status >= 3}
                                />
                            </div>
                            )}
                        </Scope>
                    ))}
                </Row>)}
            </>
        );
    }  
}

export { SelectVidros };
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import './styles.css';

const Footer: React.FC = () => {

    const d = new Date();
    const ano = d.getFullYear();

    return (
        <footer className="footer">
            <div className="container">
            <div className="row align-items-center flex-row-reverse d-flex justify-content-between ">
                <div className="col-12 col-lg-auto text-center">                
                    <ul className="list-inline list-inline-dots mb-0">
                        <li className="list-inline-item"><a target="_blank" rel="opener noreferrer" href="http://esquadgroup.com.br">Esquadgroup - Sistemas para Esquadrias</a></li>
                        <li className="list-inline-item"><a target="_blank" rel="opener noreferrer">Termos de Uso</a></li>
                        <li className="list-inline-item">Copyright © {ano}</li>                        
                    </ul>                                      
                </div>                
                <div className="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
                    <ul className="list-inline list-inline-dots mb-0 d-flex align-items-center justify-content-center">
                        <li className="list-inline-item social-icons">
                            <a target="_blank" href="https://www.facebook.com/profile.php?id=100089950552103&locale=pt_BR" rel="opener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                            <a target="_blank" href="https://www.instagram.com/alumisoft/" rel="opener noreferrer"><FontAwesomeIcon icon={faInstagramSquare} /></a>
                            <a target="_blank" href="https://www.youtube.com/watch?v=SVhYFsUakTU" rel="opener noreferrer"><FontAwesomeIcon icon={faYoutubeSquare} /></a>
                        </li>
                    </ul>                
                </div>
            </div>
            </div>
        </footer>    
    );
}

export default Footer
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Input from '../../../components/Input';
import api from '../../../services/api';

import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import getValidationErrors from '../../../helpers/getValidateErrors';

import { ToastContext } from "../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from '../../../helpers/getSingleErrorMessage'

import "./styles.css";

interface IFormDados {
  id: number,
  nome: string,
  email: string,
}

interface IFormSenha {
  senhaAtual: string,
  novaSenha: string,
}

interface IVerSenha {
  senhaAtual: boolean,
  novaSenha: boolean
}

const Perfil: React.FC = () => {

  //Ref
  const formRefDados = useRef<FormHandles>(null)
  const formRefSenha = useRef<FormHandles>(null)

  //Context
  const toastContext = useContext(ToastContext)

  //States
  const [dadosLoading, setDadosLoading] = useState(false);
  const [dadosAtuais, setDadosAtuais] = useState();
  const [reload, setReload] = useState(false);

  const [verSenha, setVerSenha] = useState<IVerSenha>({ senhaAtual: false, novaSenha: false });

  //Effect
  useEffect(() => {
    (async () => {
      try {
        setDadosLoading(true)
        const { data } = await api.get(`/sessoes`) // Alterar ID
        formRefDados.current?.setData(data);
        setDadosAtuais(data)
        setDadosLoading(false)
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })()
  }, [reload])

  const handleSubmitDados: SubmitHandler<IFormDados> = async (dadosNovos) => {
    try {
      formRefDados.current?.setErrors({});

      const message = "Esse campo não pode ficar vazio.";
      const schema = Yup.object().shape({
        nome: Yup.string().required(message),
        email: Yup.string().email("Insira um e-mail válido.").required(message),
      });
      
      await schema.validate(dadosNovos, { abortEarly: false });

      if (JSON.stringify(dadosAtuais) !== JSON.stringify(dadosNovos)) {
        await api.put("/sessoes/alterarPerfil", dadosNovos);
        toastContext.notification("Os dados foram alterados.", "success");
        setReload(!reload);
      }
      else {
        toastContext.notification("Não houveram alteração de dados.", "warn");
      }

    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        formRefDados.current?.setErrors(getValidationErrors(err));
      }
      else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }
  }

  const handleSubmitSenha: SubmitHandler<IFormSenha> = async (dados) => {
    try {
      formRefSenha.current?.setErrors({});

      const message = "Esse campo não pode ficar vazio.";
      const schema = Yup.object().shape({

        senhaAtual: Yup.string().required(message),
        novaSenha: Yup.string()
          .matches(
            /^(?=.*?[a-z])/,
            "A senha precisa ter pelo menos uma letra minúscula."
          )
          .matches(
              /^(?=.*?[A-Z])/,
              "A senha precisa ter pelo menos uma letra maiúscula."
          )
          .matches(
              /^(?=.*?[0-9])/,
              "A senha precisa ter pelo menos um número."
          )
          .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
          .max(12,"Senha deve ter no máximo 12 caracteres")
          .required('A senha é obrigatória.'),
      });
      await schema.validate(dados, { abortEarly: false });

      await api.put("/sessoes/alterarSenha", dados);
      toastContext.notification("A senha foi alterada.", "success");
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        formRefSenha.current?.setErrors(getValidationErrors(err));
      }
      else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    }
  }

  return (
    <>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="page-header">
            <h1 className="page-title">Perfil</h1>
          </div>
        </div>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Editar Dados</h3>
                </div>
                <div className="card-body">
                  <Form ref={formRefDados} onSubmit={handleSubmitDados}>
                    <div className={'dimmer' + (dadosLoading ? ' active' : '')}>
                      <div className="loader"></div>
                      <div className={"dimmer-content"}>
                        <Row>
                          <div className="form-group col-12">
                            <Input name="nome" label="Nome" maxLength={80} />
                          </div>
                          <div className="form-group col-12">
                            <Input name="email" label="E-mail" maxLength={80}/>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <Row>
                      <Col xs="auto" className="ml-auto mt-2">
                        <Button onClick={() => formRefDados.current?.submitForm()}>
                          <i className="fa fa-save mr-2"></i>
                          Salvar Dados
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Alterar Senha</h3>
                </div>
                <div className="card-body">
                  <Form ref={formRefSenha} onSubmit={handleSubmitSenha}>
                    <Row>
                      <div className="form-group col-12">
                        <Input type={verSenha.senhaAtual ? "text" : "password"} name="senhaAtual" label="Senha Atual" placeholder="Digite sua senha atual..." maxLength={25}>
                          <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                            <i className={verSenha.senhaAtual ? "fe fe-eye" : "fe fe-eye-off"}
                              title={verSenha.senhaAtual ? "Esconder Senha" : "Ver Senha"}
                              onClick={() => setVerSenha({ ...verSenha, senhaAtual: !verSenha.senhaAtual })}
                            ></i>
                          </span>
                        </Input>
                      </div>
                      <div className="form-group col-12">
                        <Input type={verSenha.novaSenha ? "text" : "password"} name="novaSenha" label="Nova Senha" placeholder="Digite sua nova senha..." maxLength={25}>
                          <span className="input-icon-addon" style={{ pointerEvents: "initial", cursor: "pointer" }}>
                            <i className={verSenha.novaSenha ? "fe fe-eye" : "fe fe-eye-off"}
                              title={verSenha.novaSenha ? "Esconder Senha" : "Ver Senha"}
                              onClick={() => setVerSenha({ ...verSenha, novaSenha: !verSenha.novaSenha })}
                            ></i>
                          </span>
                        </Input>
                      </div>
                    </Row>
                    <Row>
                      <Col className="text-muted password-tips"> <p>Sua senha precisa ter:</p>
                        <ul className="ml-0">
                          <li>Mínimo de 6 e máximo 12 caracteres</li>
                          <li>Uma letra: maiúscula e minúscula</li>
                          <li>Um número</li>
                        </ul>
                      </Col>
                      <Col xs="auto" className="ml-auto mt-2">
                        <Button onClick={() => formRefSenha.current?.submitForm()}>
                        <i className="fa fa-key mr-2"></i>
                          Alterar Senha
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Perfil;
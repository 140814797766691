import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ColunaLateral } from '../ColunaLateral'

import { PlantaBaixaViewer } from '../../../components/PlantaBaixaViewer';
import { IOptionsString } from '../../../interfaces/options.interface';

interface IProps {
    setAreaAtiva: (value: string) => void,
    versao: number,
    editavel: boolean,
    plantaBaixaEditavel: boolean,
    active:boolean,
    coresPerfis: IOptionsString[],
    vidros:IOptionsString[]
}

const VisualizadorPlantaBaixa: React.FC<IProps> = ({ setAreaAtiva, versao , plantaBaixaEditavel, editavel, active, coresPerfis,vidros}) => {
    return (
        <>
            <hr />

            <Row>
                <Col md={12} lg style={{ overflow: "hidden" }}>
                    <Row className="mb-3">
                        <Col>
                            <h4>Planta Baixa:</h4>
                        </Col> 
                    </Row>
                    <Row>
                        <PlantaBaixaViewer versao={versao} editavel={plantaBaixaEditavel} ativo={active}/>
                    </Row>
                </Col>
                <div className="vr"></div>
                <ColunaLateral setAreaAtiva={setAreaAtiva} editavel={editavel} coresPerfis={coresPerfis} vidros={vidros}/>
            </Row>

        </>
    );
}

export { VisualizadorPlantaBaixa };
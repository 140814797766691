interface IProps {
    value: number;
    title: string;
    color?: string | undefined;
    ico:string;
}

const CardStatus: React.FC<IProps> = ({ value, title, color, ico }) => {
    return (
        <div className="card" style={{borderBottom:`5px solid ${color}`}}>
            <div className="card-body p-0 text-center" >

                {value === undefined ? (
                    <div className="mt-4 mb-4 w-75 mx-auto" style={{color:'red'}}>
                        <div className="skeleton-heading"></div>
                        <div className="skeleton-line"></div>
                    </div>
                    ) : (
                    <div className='pt-0'>
                        <div style={{borderBottom:`2px solid ${color}`}} 
                        className="h2 py-5 m-2 d-flex justify-content-around">
                            <span className="badge badge-light rounded-circle">
                            <i className={`fe fe-${ico}`} style={{color:`${color}`}}></i>
                            </span>
                            <div>{value}</div>
                        </div>
                        <div className="text-muted py-3 text-center">{title}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardStatus;
export function useResponsive() {
    var largura = window.screen.width;
  
    const isMobile = largura <= 430;
    const isTablet = largura >= 768 && largura <= 1023;
    const isDesktop = largura >= 1024 && largura <= 1445;
    const isScreenBig = largura > 1446;
    
    return {
		isMobile,
		isTablet,
		isDesktop,
        isScreenBig,
	}
};
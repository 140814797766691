import React, { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { ColunaLateral } from "../ColunaLateral";
import { NoData } from '../../../components/DataNotFound';

import { MenuLateralContext } from '../../../contexts/menuLateral/menuLateralContext';
import { IOptionsString } from '../../../interfaces/options.interface';

interface IPropsVisualizadorVideo {
    setAreaAtiva: React.Dispatch<React.SetStateAction<string>>,
    editavel: boolean,
    coresPerfis: IOptionsString[],
    vidros:IOptionsString[]
}

const VisualizadorVideo: React.FC<IPropsVisualizadorVideo> = ({ setAreaAtiva, editavel, coresPerfis,vidros }) => {

    //Contexts
    const menuLateralContext = useContext(MenuLateralContext);

    return (
        <>
            <hr />

            <Row>
                <Col md={12} lg>
                    <Row className="mb-3">
                        <Col>
                            <h4>Vídeos do produto:</h4>
                        </Col>
                        <Col xs="auto" className="ml-auto">
                            <Button onClick={() => setAreaAtiva("plantaBaixa")} variant="outline-secondary" className="toggle-lateral"><i className="fe fe-x"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        {menuLateralContext.tipologia.videos.map((video) => (
                            <Col md={6} key={video.id}>
                                <div className="player-wrapper mb-5">
                                    <ReactPlayer
                                        // light
                                        controls
                                        playsinline
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                        url={video.url}
                                    />
                                </div>

                            </Col>
                        ))}
                        <NoData
                            visible={menuLateralContext.loading === false ? menuLateralContext.tipologia.videos?.length === 0 : false}
                        >
                            Não existe um vídeo para esse produto.
                        </NoData>
                    </Row>
                </Col>
                <div className="vr"></div>
                <ColunaLateral setAreaAtiva={setAreaAtiva} editavel={editavel} coresPerfis={coresPerfis} vidros={vidros}/>

            </Row>
        </>
    );
}

export { VisualizadorVideo };